import PropTypes from "prop-types";
import styles from "./SideNavContainer.module.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAllProduct } from "../api-context/commonApiContext";

const SideNavContainer = ({ className = "" }) => {
  const { refreshData, setRefreshData } = useAllProduct();
  const navigate = useNavigate();
  const location = useLocation();

  // Function to check if the current path matches exactly
  const isActivePage = (path) => {
    return location.pathname === path;
  };

  // Function to check if the current path matches a pattern
  const isActivePattern = (pattern) => {
    const regex = new RegExp(pattern);
    return regex.test(location.pathname);
  };
  const { id } = useParams();

  const handleLogout = () => {
    localStorage.removeItem("authToken"); // Remove the token from local storage
    console.log("Logged out");
    navigate("/"); // Redirect to login or another page
  };

  return (
    <div className={styles.sidenavecontainerParent}>
      <div className={styles.sidenavecontainer}>
        <div className={styles.escrowstackLogos28}>
          <img className={styles.es16} alt="" src="/logo_escrow_sidenav.svg" />
        </div>
      </div>
      <div className={styles.companyListParent}>
        <button
          className={`${styles.companyList} ${isActivePage("/content") ? styles.Active : ""}`}
          onClick={() => {
            console.log("hello world side");

            setRefreshData(!refreshData);
            navigate("/content");
          }}
        >
          <img
            className={styles.building07Icon}
            alt=""
            src={`${isActivePage("/content") ? "/building071.svg" : "/building07NA.svg"}`}
          />
          <div className={`${styles.companyList1} ${isActivePage("/content") ? styles.Active : ""}`}>
            Escrow A/C List
          </div>
        </button>

        {location.pathname !== "/user-management" && (
          <div className={`${styles.kycListParent} ${isActivePage("/content") ? styles.hiddendiv : ""}`}>
            {/*================ KYC List ================== */}
            <button
              className={`${styles.kycList} ${isActivePattern("^/content/company/.*") ? styles.Active : ""}`}
              autoFocus={true}
              onClick={() => {
                // if(id.split("_").length<=1){
                //   console.log("only compid")
                //   console.log(id);
                //   navigate(`/content/company/${id}`)

                // }if(id.split("_").length>1){
                //   console.log("only directorid and compid");

                // navigate(`/content/company/${id.split("_")[1]}`)}
                navigate("/content/company/kyclist/Company");
                setRefreshData(!refreshData);
              }}
            >
              <img
                className={styles.building07Icon}
                alt=""
                src={`${isActivePattern("^/content/company/.*") ? "/scanActive.svg" : "/scan31.svg"}`}
              />
              <div className={`${styles.kycList1} ${isActivePattern("^/content/company/.*") ? styles.Active : ""}`}>
                KYC List
              </div>
            </button>

            {/*================= Contracts=============== */}

            <button
              className={`${styles.contractsList} 
          ${isActivePattern("^/content/contract/.*") ? styles.Active : ""}`}
              onClick={() => {
                navigate("/content/contract/main");
                setRefreshData(!refreshData);
              }}
            >
              <img
                className={styles.agreementIcon}
                alt=""
                src={`${isActivePattern("^/content/contract/.*") ? "/AgreementActive.svg" : "/agreement21.svg"}`}
              />
              <div
                className={`${styles.contractsList1}
            ${isActivePattern("^/content/contract/.*") ? styles.Active1 : ""}
            `}
              >
                Contracts List
              </div>
            </button>

            <button
              className={`${styles.contractsList} ${isActivePattern("^/content/esign/.*") ? styles.Active1 : ""}`}
              onClick={() => {
                // if(id.split("_").length==1){
                //   console.log("only compid");
                //   navigate(`/content/esign/${id}`)

                // }if(id.split("_").length>1){
                //   console.log("only directorid and compid");
                //   navigate(`/content/esign/${id.split("_")[1]}`)

                // }
                navigate("/content/esign/esign/main");
                setRefreshData(!refreshData);
              }}
            >
              <img
                className={styles.building07Icon}
                alt=""
                src={`${isActivePattern("^/content/esign/.*") ? "/ContractActive.svg" : "/contract31.svg"}`}
              />
              <div
                className={`${styles.kycList1}
            ${isActivePattern("^/content/esign/.*") ? styles.Active : ""}
            `}
              >
                E-sign & E-stamp
              </div>
            </button>
            {/* <a href="https://localhost:5001/">navigate to contract</a> */}
          </div>
        )}
        <button
          className={`${styles.companyList} ${isActivePage("/user-management") ? styles.Active : ""}`}
          onClick={() => {
            setRefreshData(!refreshData);
            navigate("/user-management");
          }}
        >
          <img
            className={styles.building07Icon}
            alt=""
            src={`${isActivePage("/user-management") ? "/userMgmt.svg" : "/person-vcard.svg"}`}
          />
          <div className={`${styles.companyList1} ${isActivePage("/user-management") ? styles.Active : ""}`}>
            User Management
          </div>
        </button>
        {/* <div className={styles.logout}>
            <button
              className={styles.contractsList}
              onClick={() => {
                handleLogout();
              }}
            >
              <img className={styles.building07Icon} alt="" src="/Logoutbtn.svg" />
              <div className={styles.kycList1}>Logout</div>
            </button>
          </div> */}
      </div>
    </div>
  );
};

SideNavContainer.propTypes = {
  className: PropTypes.string,
};

export default SideNavContainer;

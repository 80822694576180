export default process.env.NODE_ENV !== "production"
  ? // export default false
    {
      // node_api_endpoint: "https://kycapi.escrowstack.io",
      node_api_endpoint: "http://192.168.0.95:5050",
      mock_api_endpoint: "http://192.168.0.95:8001",
      // mock_api_endpoint: "https://contractnegotiation.escrowstack.io",
      email_api_endpoint: "https://emailservice.eazypayouts.com",
      email_key: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9",
      doc_link_api_endpoint: "http://192.168.0.95:3000",
      webhook_api_endpoint: "http://192.168.0.95:5051",
      cognito_client_id: "6oign0d7urm10jstpl9r9crt25",
      // cognito_client_id: "3g6r2ulh1m5jqisc8eajo47vv7",
    }
  : {
      node_api_endpoint: "https://kycapi.escrowstack.io",
      // mock_api_endpoint: "https://5ee6135a-a5fb-47e3-94b5-0dc75a2f732b.mock.pstmn.io",
      mock_api_endpoint: "https://contractnegotiation.escrowstack.io",
      email_api_endpoint: "https://emailservice.eazypayouts.com",
      email_key: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9",
      // doc_link_api_endpoint: "http://192.168.0.95:3000",
      doc_link_api_endpoint: "https://kyc.escrowstack.io",
      webhook_api_endpoint: "https://digiowebhooks.escrowstack.io",
      cognito_client_id: "3g6r2ulh1m5jqisc8eajo47vv7",
    };

// Home 192.168.29.160
// Office 192.168.0.95

import PropTypes from "prop-types";
import styles from "./EscrowContainer.module.css";
import { useEffect, useRef, useState } from "react";
import NewCompanyModel from "../KYCList/AddEscrow/NewCompanyModel";
import SelectActivity1 from "../SelectActivity/SelectActivity1";
import config from "../../../service/config";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Spin } from "antd";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import axios from "axios";
import { useAllProduct } from "../../api-context/commonApiContext";

const PageContainer = () => {
  const [data, setData] = useState([]);
  const { authTokens, role, userData, refreshData, loader, setLoader, clientID } = useAllProduct();
  const [showModel, setShowModel] = useState(false);
  const [compName, setCompName] = useState(""); // Move state to parent
  const [errorMsg, setErrorMsg] = useState(""); // Move state to parent
  const [showActivity, setShowActivity] = useState(false);
  const [currentcompid, setCurrentCompId] = useState("");
  const [createdDateFilter, setCreatedDateFilter] = useState(null);
  const [textFilter, setTextFilter] = useState("");
  const [currentCompName, setCurrentCompName] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [searchedByDate, setSearchedByDate] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const modalRef = useRef(null);

  const fetchData = async () => {
    if (authTokens && Object?.keys(authTokens)?.length > 0 && userData?.length > 0) {
      console.log("userData-->", userData);

      try {
        setLoader(true);

        // Fetch escrow data
        const escrowRes = await axios.get(`${config.node_api_endpoint}/v1/escrow`, {
          headers: {
            aws_cognito_id_token: authTokens.idToken,
            "X-Client-Id": clientID,
            "Content-Type": "application/json",
          },
        });
        const escrowData = escrowRes.data;

        const updatedEscrowData = escrowData.map((escrow) => {
          const user = userData.find((u) => u.email === escrow.created_by);

          return {
            ...escrow,
            role: user ? user.auth[0].role : "N/A", // Default to 'N/A' if no match is found
          };
        });

        // Sort and set filtered data
        const sortedData = updatedEscrowData.sort((a, b) => {
          const dateA = dayjs(a.created_at, "DD MMM YYYY / hh:mm a");
          const dateB = dayjs(b.created_at, "DD MMM YYYY / hh:mm a");
          return dateB - dateA;
        });

        setData(updatedEscrowData);
        setFilteredData(sortedData);
        setLoader(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoader(false);
      }
    }
  };
  useEffect(() => {
    if (role?.toString()?.length > 0) {
      fetchData();
    }
  }, [showModel, role, userData, authTokens]);

  useEffect(() => {
    setFilteredData([]); // Reset the filtered data to an empty array

    setCurrentPage(0);
    fetchData(); // Re-fetch data instead of reloading the page
  }, [refreshData]);

  useEffect(() => {
    if (showModel || showActivity) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showModel, showActivity]);

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleTextFilterChange = (e) => {
    const value = e.target.value;
    setTextFilter(value);

    let filtered = searchedByDate?.length > 0 ? searchedByDate : data;

    if (value) {
      filtered = filtered.filter(
        (item) =>
          item.created_at.toLowerCase().includes(value.toLowerCase()) ||
          item?.role[0]?.toLowerCase().includes(value.toLowerCase()) ||
          item?.created_by?.toLowerCase().includes(value.toLowerCase()) ||
          item.name.toLowerCase().includes(value.toLowerCase()),
      );
    }

    setFilteredData(filtered);
    if (!value) setFilteredData(filtered);
    setCurrentPage(0);
  };
  const applyFilters = () => {
    setTextFilter("");
    let filtered = [...data];

    if (createdDateFilter) {
      filtered = filtered.filter((item) => {
        const [datePart] = item.created_at.split(" / "); // Extract the date part before '/'
        const itemDate = new Date(datePart).toDateString(); // Convert to a comparable date format
        const filterDate = new Date(createdDateFilter).toDateString();

        return itemDate === filterDate;
      });
    }
    setSearchedByDate(filtered);
    setFilteredData(filtered);
    setCurrentPage(0);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };
  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setShowModel(false);
      setShowActivity(false);
      setCompName(""); // Reset compName
      setErrorMsg(""); // Reset errorMsg
    }
  };

  // Calculate current page data
  const currentPageData = filteredData.slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage);
  console.log("role-->", role);

  return (
    <div className={`${styles.pageContainer} ${styles.family}  h-100 w-100`}>
      <Spin
        size="large"
        tip="Loading..."
        spinning={loader}
        className="d-flex justify-content-center align-items-center w-100 h-100"
        wrapperClassName="spin-nested-loading"
        style={{ maxHeight: "none" }}
      >
        <div
          className={`${styles.newCompany_div} ${role?.toString() === "Owner" || role?.toString() === "Super Admin" || role?.toString() === "Admin" ? "" : styles.disabledDiv}`}
        >
          <button
            onClick={() => {
              setShowModel(true);
            }}
            className={styles.newCompany}
            disabled={
              role?.toString() === "Owner" || role?.toString() === "Super Admin" || role?.toString() === "Admin"
                ? false
                : true
            }
          >
            <img className={styles.plusCircleIcon} alt="" src="/pluscircle.svg" />
            <b className={styles.addNewCompany}>Add Escrow account</b>
          </button>
        </div>

        <div className={`${styles.searchFiltersParent} mt-3 p-2 mb-3`}>
          <div className={styles.frameParent}>
            {/* MUI search for table */}
            <div className={styles.leadingIconParent}>
              <img className={styles.leadingIcon} alt="" src="/leadingicon.svg" />
              <TextField label="Search" variant="standard" value={textFilter} onChange={handleTextFilterChange} />
            </div>

            <div className={styles.mobileMenuParent}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Created Date"
                  value={createdDateFilter}
                  maxDate={dayjs()}
                  onChange={(newValue) => setCreatedDateFilter(newValue)}
                  renderInput={(params) => <TextField {...params} />}
                  sx={{
                    "& .MuiInputBase-root": {
                      borderRadius: "12px",
                    },
                  }}
                />
              </LocalizationProvider>

              <div className={styles.loadsButtons1} onClick={applyFilters}>
                <img className={styles.boxArrowInDownIcon} alt="" src="/boxarrowindown1.svg" />
                <b className={styles.addNewCompany}>Apply</b>
              </div>
            </div>

            {/* Frame Container ends */}
          </div>
        </div>

        <Paper>
          <TableContainer>
            <Table>
              <TableHead
                className={styles.tableBg}
                sx={{
                  "& .MuiTableCell-root": {
                    padding: "16px",
                  },
                }}
              >
                <TableRow>
                  <TableCell align="center">Created Date</TableCell>
                  <TableCell align="center">Escrow Name</TableCell>
                  <TableCell align="center">Created By</TableCell>
                  <TableCell align="center">Role</TableCell>
                  <TableCell align="center">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {currentPageData.length && role?.toString()?.length > 0 ? (
                  currentPageData.map((detail) => (
                    <TableRow key={detail._id} sx={{ "& .MuiTableCell-root": { padding: "8px" } }}>
                      <TableCell align="center">{detail.created_at}</TableCell>
                      <TableCell align="center" title={detail.name.length > 30 ? detail.name : ""}>
                        {detail.name.length > 30 ? detail.name.slice(0, 30) + "..." : detail.name}
                      </TableCell>
                      <TableCell align="center">{detail.created_by}</TableCell>
                      <TableCell align="center">{detail?.role?.toString()}</TableCell>
                      <TableCell align="center">
                        <Button
                          className={styles.change}
                          style={{ color: "#2348AB", textDecoration: "underline", textTransform: "lowercase" }}
                          onClick={() => {
                            handleScrollToTop();
                            setShowActivity(true);
                            setCurrentCompId(detail.id);
                            setCurrentCompName(detail.name);
                          }}
                        >
                          {"View More"}
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={5} align="center">
                      No Records Found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            className={`${styles.tableBg}`}
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredData.length}
            rowsPerPage={rowsPerPage}
            page={currentPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{
              "& .MuiTablePagination-selectLabel": {
                marginTop: "14px",
              },
              "& .MuiTablePagination-displayedRows": {
                marginTop: "14px",
              },
            }}
          />
        </Paper>

        <SelectActivity1
          isVisible={showActivity}
          onclose={() => {
            setShowActivity(false);
          }}
          escrowId={currentcompid}
          escrowName={currentCompName}
          modalRef={modalRef}
        />
        <NewCompanyModel
          isVisible={showModel}
          onclose={() => setShowModel(false)}
          setLoader={setLoader}
          clientID={clientID}
          modalRef={modalRef}
          compName={compName} // Pass state
          setCompName={setCompName} // Pass setter
          errorMsg={errorMsg} // Pass state
          setErrorMsg={setErrorMsg} // Pass setter
        />
      </Spin>
    </div>
  );
};

PageContainer.propTypes = {
  className: PropTypes.string,
};

export default PageContainer;

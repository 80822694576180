import React, { useState, useEffect, useRef } from "react";
import Draggable from "react-draggable";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import config from "../../../../service/config";
import styles from "./SignatureBox.module.css";
import companyStore from "../../CompanyStore";
import { useAllProduct } from "../../../api-context/commonApiContext";

const Sign = ({ button, reason, onPlaceholderRemoved, removedPlaceholders, replaceTrigger, signatories, setError }) => {
  const { setESignCoordinates, setLoader, authTokens } = useAllProduct();
  const [items, setItems] = useState([]);
  const [imageData, setImageData] = useState([]);
  const [img, setImg] = useState([]);
  const [draggables, setDraggables] = useState({});
  const [data, setData] = useState([]);
  const [id, setId] = useState("");
  const [pdfHeight, setPdfHeight] = useState("");
  const [pdfWidth, setPdfWidth] = useState("");
  const containerRefs = useRef({});
  const [imageSizes, setImageSizes] = useState({});
  const navigate = useNavigate();

  const coordinates = [
    { x: 170, y: 300 },
    { x: 180, y: 540 },
    { x: 60, y: 540 },
    { x: 300, y: 513 },
    { x: 180, y: 513 },
    { x: 60, y: 513 },
  ];

  useEffect(() => {
    if (Object.keys(authTokens)?.length > 0) {
      setId(companyStore.companyID);
      const doc_id = companyStore.doc_id;
      console.log("Previewing documents for doc_id:", doc_id);
      axios
        .post(
          `${config.node_api_endpoint}/v1/esign/getdocument`,
          { doc_id },
          {
            headers: {
              aws_cognito_id_token: authTokens.idToken,
            },
          },
        )
        .then((res) => {
          console.log("Full API Response:", res.data);
          console.log("Signing Parties:", res.data.signing_parties);
          console.log("PNG URLs:", res.data.png_url);

          const pngUrls = Array.isArray(res.data.png_url) ? res.data.png_url : [];
          setData(res.data.signing_parties || []);
          setImageData(pngUrls);
          setImg(pngUrls);

          const draggableObj = {};
          const esignData = Array.isArray(res.data.signing_parties) ? res.data.signing_parties : [];
          setItems(esignData);

          if (esignData.length === 0) {
            console.warn("No signing parties found. Using dummy data for testing.");
            pngUrls.forEach((_, i) => {
              const imageId = String(i);
              draggableObj[imageId] = [
                {
                  name: `Test User ${i}`,
                  email: `test${i}@example.com`,
                  x: coordinates[0].x,
                  y: coordinates[0].y,
                  imageId: imageId,
                  originalIndex: 0,
                },
              ];
            });
          } else {
            pngUrls.forEach((_, i) => {
              const imageId = String(i);
              draggableObj[imageId] = esignData.map((esignDataItem, j) => {
                console.log(`Assigning ${esignDataItem.name} to page ${imageId}`);
                const line_no = Math.floor(j / 3);
                return {
                  name: esignDataItem.name || "Unnamed",
                  email: esignDataItem.email || `user${j}@example.com`,
                  x: coordinates[0].x - (j % 3) * 80,
                  y: coordinates[0].y - line_no * 37,
                  imageId: imageId,
                  originalIndex: j,
                };
              });
            });
          }
          console.log("Initialized draggables:", JSON.stringify(draggableObj, null, 2));
          setDraggables(draggableObj);
        })
        .catch((err) => {
          console.error("Error fetching document:", err.response?.data || err.message);
        });
    }
  }, [authTokens]);

  // Effect to re-add removed placeholders when replaceTrigger is true
  useEffect(() => {
    if (replaceTrigger && removedPlaceholders.length > 0) {
      setDraggables((prev) => {
        const newDraggables = { ...prev };
        removedPlaceholders.forEach((item) => {
          const imageId = item.imageId;
          if (!newDraggables[imageId]) {
            newDraggables[imageId] = [];
          }
          // Avoid duplicates by checking if the item already exists
          if (
            !newDraggables[imageId].some(
              (drag) => drag.email === item.email && drag.originalIndex === item.originalIndex,
            )
          ) {
            newDraggables[imageId].push(item);
          }
        });
        console.log("Re-added draggables:", JSON.stringify(newDraggables, null, 2));
        return newDraggables;
      });
    }
  }, [replaceTrigger, removedPlaceholders]);

  const handleImageLoad = (e, imageId) => {
    const imgElement = e.target;
    setPdfHeight(Math.floor(imgElement.naturalHeight));
    setPdfWidth(Math.floor(imgElement.naturalWidth));
    setImageSizes((prev) => ({
      ...prev,
      [imageId]: {
        width: imgElement.naturalWidth / 4,
        height: imgElement.naturalHeight / 4,
      },
    }));
    console.log(
      `Image loaded - Page ${imageId}, Height: ${imgElement.naturalHeight}, Width: ${imgElement.naturalWidth}`,
    );
  };

  const conversion = (x, y, pg, userIndex) => {
    const coord = {
      PageNo: pg,
      ControlID: 4,
      AssignedTo: userIndex + 1,
      Top: pdfHeight - Math.floor(y * 2) > pdfHeight ? pdfHeight : pdfHeight - Math.floor(y * 2),
      Left: Math.floor(x * 2) > pdfWidth ? pdfWidth : Math.floor(x * 2),
      Width: Math.floor(x * 2) + 150 > pdfWidth ? pdfWidth : Math.floor(x * 2) + 150,
      Height: pdfHeight - (Math.floor(y * 2) + 60) > pdfHeight ? pdfHeight : pdfHeight - (Math.floor(y * 2) + 60),
    };
    console.log(`Converted coordinates for Page ${pg}:`, coord);
    return coord;
  };

  const handleSendClick = () => {
    const signCoordinates = {};
    const eCoordinates = [];
    const eCoordinatesForEstamp = [];

    Object.keys(draggables).forEach((imageId) => {
      draggables[imageId].forEach((item, ind) => {
        console.log("test-->", item, "index-->", ind);
        if (!signCoordinates[item.email]) {
          signCoordinates[item.email] = {};
        }
        const pageNum = parseInt(imageId) + 1;
        const coord = conversion(item.x, item.y, pageNum, item.originalIndex);
        signCoordinates[item.email][pageNum] = [coord];
        eCoordinates.push(coord);
        eCoordinatesForEstamp.push(coord);
      });
    });
    console.log("Sign Coordinates:", JSON.stringify(signCoordinates, null, 2));
    console.log("eCoordinates:", JSON.stringify(eCoordinates, null, 2));

    if (reason === "E-SignWithE-Stamp") {
      console.log("tets-->s", signatories.length);
      if (signatories.length > 1) {
        setESignCoordinates(eCoordinatesForEstamp);
        navigate(`/content/esign/esignwithestamp/${companyStore.doc_id}`);
      } else {
        alert("Two signatories is required to proceed with Estamping");
        setError("Two signatories is required to proceed with Estamping");
      }
    } else {
      setLoader(true);
      let obj = {
        doc_id: companyStore.doc_id,
        escrow_id: companyStore.escrowId,
        sign_coordinates: eCoordinates,
        SignatureSettings: {
          ModeOfAuthentication: 0,
          ModeOfSignature: "12",
        },
        reason: reason,
        created_by: companyStore?.AdminEmail,
      };
      console.log("Sending to API:", JSON.stringify(obj, null, 2));
      axios
        .post(`${config.node_api_endpoint}/v1/esign/sign_document`, obj, {
          headers: {
            aws_cognito_id_token: authTokens.idToken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          setLoader(false);
          navigate(`/content/esign/esign-requested`);
        })
        .catch((err) => {
          setLoader(false);
          console.error("Error sending coordinates:", err.response?.data || err.message);
          alert(err.response?.data?.message || "An error occurred");
          navigate("/content/esign/esign/main");
        });
    }
  };

  useEffect(() => {
    if (button) {
      handleSendClick();
    }
  }, [button]);

  const handleDrag = (imageId, index, e, data) => {
    console.log(`Dragging on Page ${imageId}, Index ${index}: x=${data.x}, y=${data.y}`);
    setDraggables((prev) => ({
      ...prev,
      [imageId]: prev[imageId].map((item, i) => (i === index ? { ...item, x: data.x, y: data.y } : item)),
    }));
  };

  const handleDragStop = (imageId, index, e, data) => {
    console.log(`Drag stopped on Page ${imageId}, Index ${index}: x=${data.x}, y=${data.y}`);
  };

  const handleRemove = (imageId, index) => {
    console.log(`Removing placeholder from Page ${imageId}, Index ${index}`);
    setDraggables((prev) => {
      const removedItem = prev[imageId][index];
      const newDraggables = {
        ...prev,
        [imageId]: prev[imageId].filter((_, i) => i !== index),
      };
      console.log(`Updated draggables for Page ${imageId}:`, newDraggables[imageId]);
      console.log(`Removed item:`, removedItem);

      if (onPlaceholderRemoved) {
        onPlaceholderRemoved(removedItem);
      }

      return newDraggables;
    });
  };

  return (
    <div className={styles.containSign}>
      {imageData.map((image, index) => {
        const pageId = String(index);
        console.log(`Rendering Page ${index} (ID: ${pageId}) with draggables:`, draggables[pageId] || "None");
        return (
          <div
            key={index}
            ref={(el) => (containerRefs.current[pageId] = el)}
            className="outer-div"
            style={{
              position: "relative",
              width: "auto",
              height: "auto",
            }}
          >
            <img
              src={image}
              alt="Background"
              onLoad={(e) => handleImageLoad(e, pageId)}
              style={{
                width: "100%",
                height: "100%",
                display: "block",
                border: "1px solid black",
                objectFit: "contain",
              }}
            />

            {draggables[pageId]?.length > 0 ? (
              draggables[pageId].map((dragItem, idx) => {
                console.log(`Mapping item on Page ${pageId}, Index ${idx}:`, dragItem);
                return (
                  <Draggable
                    key={`${dragItem.email}-${idx}`}
                    position={{ x: dragItem.x, y: dragItem.y }}
                    onDrag={(e, data) => handleDrag(pageId, idx, e, data)}
                    onStop={(e, data) => handleDragStop(pageId, idx, e, data)}
                    bounds="parent"
                  >
                    <div
                      className="inner-div"
                      style={{
                        width: "150px",
                        height: "60px",
                        backgroundColor: "#F1F4FF",
                        cursor: "grab",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        color: "#333333",
                        borderRadius: "10px",
                        fontWeight: "bold",
                        fontFamily: "sarabun",
                        position: "absolute",
                        top: dragItem.y,
                        left: dragItem.x,
                        zIndex: 1,
                        padding: "0 5px",
                      }}
                    >
                      <img src="/editorIcon.svg" alt="" />
                      <span>{dragItem.name.toUpperCase()}</span>

                      <img onClick={() => handleRemove(pageId, idx)} src="/xcircle.svg" alt="" />
                    </div>
                  </Draggable>
                );
              })
            ) : (
              <div>No placeholders available for page {index}</div>
            )}
          </div>
        );
      })}
      {button && <div></div>}
    </div>
  );
};

export default Sign;

import PropTypes from "prop-types";
import styles from "./CompanyApproved.module.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import config from "../../../../../service/config";
import companyStore from "../../../CompanyStore";
import { useAllProduct } from "../../../../api-context/commonApiContext";

const CompanyApproved = ({ isVisible, onclose, className = "" }) => {
  const { authTokens } = useAllProduct();
  const navigate = useNavigate();

  const onApproval = () => {
    const obj = {
      kyb_id: companyStore.KYBId,
      status: "approved",
    };

    axios
      .post(`${config.node_api_endpoint}/v1/company/kyb/update_status`, obj, {
        headers: {
          aws_cognito_id_token: authTokens.idToken,
          "Content-Type": "application/json",
        },
      })
      .then(() => {
        console.log("Request has been approved");
        navigate(`/content/company/route`);
      })
      .catch((err) => {
        console.error(err);
      });

    onclose();
  };

  if (!isVisible) return null;

  return (
    <div className={`modal show d-block ${styles.approvalModal}`} tabIndex="-1" role="dialog">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className={`modal-content ${styles.customModalContent}`}>
          <div className={`modal-body ${styles.customModalBody}`}>
            <p>Are you sure you want to approve?</p>
            <img src="/4019677-2131040-1.svg" alt="Approval Icon" className={styles.icon} />
          </div>
          <div className={`modal-footer d-flex justify-content-center`}>
            <button
              type="button"
              style={{ color: "#2348AB", backgroundColor: "#ffffff", border: "1px solid #2348AB" }}
              className={`btn btn-secondary ${styles.customCancelBtn}`}
              onClick={onclose}
            >
              No
            </button>
            <button
              type="button"
              style={{ backgroundColor: "#2348AB", border: "1px solid #2348AB" }}
              className={`btn btn-primary ${styles.customApproveBtn}`}
              onClick={onApproval}
            >
              Yes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

CompanyApproved.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onclose: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default CompanyApproved;

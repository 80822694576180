import React, { useEffect, useState } from "react";

import styles from "./userManagement.module.css";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";
import UserModal from "./userModal/UserModal";
import config from "../../service/config";
import axios from "axios";
import { Spin } from "antd";
import { useAllProduct } from "../api-context/commonApiContext";
import companyStore from "../Content/CompanyStore";

const UserManagement = () => {
  const [data, setData] = useState([]);
  const [showModel, setShowModel] = useState(false);
  const [removeUserModel, setRemoveUserModel] = useState(false);
  const [reasonModel, setReasonModel] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [textFilter, setTextFilter] = useState("");
  const [reason, setReason] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedUser, setSelectedUser] = useState([]);
  const [userByRole, setUserByRole] = useState([]);
  const [userToDelete, setUserToDelete] = useState(null); // Track the user to delete
  const [clientId, setClientId] = useState("");
  const [errors, setErrors] = useState({});
  const [assignTo, setAssignTo] = useState("");
  const [hideButton, setHideButton] = useState(false);
  const { role, authTokens, loader, setLoader, userData, setUserData, setRefreshData, refreshData, clientID } =
    useAllProduct();

  const fetchUsers = async () => {
    try {
      setLoader(true);
      setTextFilter("");
      const response = await axios.get(`${config.node_api_endpoint}/v1/user/getUserList`, {
        headers: {
          aws_cognito_id_token: authTokens.idToken,
          "Content-Type": "application/json",
          "X-Client-Id": clientID,
        },
      });
      setLoader(false);
      const resData = await response.data.userData;
      setUserData(resData);
      const filterClientId = resData?.filter((data) => data.auth[0].role[0].toString() === "Owner");
      setClientId(resData[0]?.auth[0]?.clientId);
      setFilteredData(resData);
      setData(resData);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };
  useEffect(() => {
    if (role && Object.keys(authTokens)?.length > 0 && clientID) fetchUsers();
  }, [role, authTokens, showModel, hideButton]);

  useEffect(() => {
    setFilteredData([]); // Reset the filtered data to an empty array

    console.log("helllo");

    setCurrentPage(0);
    fetchUsers(); // Re-fetch data instead of reloading the page
  }, [refreshData]);

  useEffect(() => {
    const filterUsers = userData?.filter(
      (data) =>
        data?.auth[0]?.role.toString() === userToDelete?.auth[0]?.role.toString() && userToDelete.email !== data.email,
    );
    setUserByRole(filterUsers.filter((data) => data.status === "active"));
  }, [userToDelete, userData]);

  const deleteUser = async (userId, name, email, reason) => {
    const validationErrors = {};
    console.log("error-->", assignTo, reason);

    if (assignTo?.length === 0 || !assignTo) {
      validationErrors.assignTo = "Please select the user.";
    }
    if (reason.trim()?.length === 0) {
      validationErrors.reason = "Please provide a reason for removing the user.";
    }
    console.log("errors-->", validationErrors);

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    let payload = { userId: userId, status: "removed", reason: reason, email: email, assignTo: assignTo };

    try {
      setLoader(true);
      await axios
        .put(`${config.node_api_endpoint}/v1/user/deleteUserData`, payload, {
          headers: {
            aws_cognito_id_token: authTokens.idToken,
            "Content-Type": "application/json",
          },
        })
        .then(async () => {
          const getUserResponse = await axios.get(`${config.node_api_endpoint}/v1/user/getAllUserData`, {
            headers: {
              aws_cognito_id_token: authTokens.idToken,
              "Content-Type": "application/json",
            },
          });
          setTextFilter("");
          setFilteredData(getUserResponse.data.users);
          setData(getUserResponse.data.users);
          setReason("");
          setRemoveUserModel(false);
          let bodyHtml = `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
    <html lang="und" dir="auto" xmlns="http://www.w3.org/1999/xhtml">
      <head>
        <style type="text/css">
          @import url("https://fonts.googleapis.com/css2?family=Sarabun:wght@400;500;600");
        </style>

        <title></title>
        <!--[if (!mso)&(!ie)]>These<!-- -->
        <!--<![endif]-->
        <!--[if (!mso)&(!ie)]>are<!-- -->
        <!--<![endif]-->
        <!--[if (!mso)&(!ie)]>for<!-- -->
        <!--<![endif]-->
        <!--[if (!mso)&(!ie)]>outlook<!-- -->
        <!--<![endif]-->
        <!--[if (!mso)&(!ie)]>live<!-- -->
        <!--<![endif]-->
        <!--[if (!mso)&(!ie)]>that<!-- -->
        <!--<![endif]-->
        <!--[if (!mso)&(!ie)]>removes<!-- -->
        <!--<![endif]-->
        <!--[if (!mso)&(!ie)]>the first<!-- -->
        <!--<![endif]-->
        <!--[if (!mso)&(!ie)]>10 well-formed<!-- -->
        <!--<![endif]-->
        <!--[if (!mso)&(!ie)]>conditional comments<!-- -->
        <!--<![endif]-->
        <!--[if gte mso 9]>
          <xml>
            <o:OfficeDocumentSettings xmlns:o="urn:schemas-microsoft-com:office:office">
              <o:AllowPNG />
              <o:PixelsPerInch>96</o:PixelsPerInch>
            </o:OfficeDocumentSettings>
          </xml>
        <![endif]-->

        <style type="text/css">
          @media (prefers-color-scheme: dark) {
          }
        </style>

        <meta name="color-scheme" content="light dark" />

        <meta name="supported-color-schemes" content="light dark" />

        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />

        <!--[if !mso]><!-->
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <!--<![endif]-->

        <meta name="x-apple-disable-message-reformatting" />

        <style></style>

        <meta name="viewport" content="width=device-width, initial-scale=1.0" />

        <style type="text/css">
          u + div .kombai-email-compat__list-with-padding-left {
            padding-left: 0.5em !important;
          }
        </style>

        <!--[if mso]>
          <style type="text/css">
            v\:* {
              behavior: url(#default#VML);
              display: inline-block;
            }
            o\:* {
              behavior: url(#default#VML);
              display: inline-block;
            }
            w\:* {
              behavior: url(#default#VML);
              display: inline-block;
            }
            .ExternalClass {
              width: 100%;
            }
            table {
              mso-table-lspace: 0pt;
              mso-table-rspace: 0pt;
            }
            img {
              -ms-interpolation-mode: bicubic;
            }
            .ReadMsgBody {
              width: 100%;
            }
            a {
              background: transparent !important;
              background-color: transparent !important;
            }

            li {
              text-align: -webkit-match-parent;
              display: list-item;
              text-indent: -1em;
            }

            ul,
            ol {
              margin-left: 1em !important;
            }

            p {
              text-indent: 0;
            }
          </style>
        <![endif]-->
      </head>
      <body style="margin: 0; padding: 0">
        <div style="font-size: 0px; line-height: 1px; mso-line-height-rule: exactly; display: none; max-width: 0px; max-height: 0px; opacity: 0; overflow: hidden; mso-hide: all"></div>
        <center lang="und" dir="auto" style="width: 100%; table-layout: fixed; -webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%">
          <table cellpadding="0" cellspacing="0" border="0" role="presentation" bgcolor="white" width="657.00" style="border-radius: 12px; background-color: white; padding-top: 32px; padding-bottom: 28.02px; width: 657px; border-spacing: 0; font-family: Sarabun, sans-serif; min-width: 657px; border-collapse: separate">
            <tr>
              <td valign="middle" width="100.00%" style="width: 100%; vertical-align: middle">
                <table cellpadding="0" cellspacing="0" border="0" role="presentation" width="100.00%" style="width: 100%; border-spacing: 0">
                   <tr>
              <td align="center" style="padding-bottom: 13.76px">
                <img src="cid:logo" width="101" height="50" alt="Logo" />
            </td>
            </tr>
                  <tr>
                    <td align="left" style="padding-top: 13.75px; padding-bottom: 12.15px; padding-left: 32px">
                      <p width="100.00%" style="font-size: 20px; font-weight: 400; letter-spacing: 0.4px; color: #4c4e64; margin: 0; padding: 0; width: 100%; line-height: 21.44px; mso-line-height-rule: exactly"><span>Dear </span><span style="font-size: 20px; font-weight: 600; letter-spacing: 0.4px; text-align: left">${name},</span></p>
                    </td>
                  </tr>
                  <tr>
                    <td align="left" style="padding-top: 12.15px; padding-left: 32px; padding-right: 32px">
                      <p width="100.00%" style="text-align: left; color: #777777; margin: 0; padding: 0; width: 100%">
                        <span style="font-size: 20px; font-weight: 500; letter-spacing: 0.4px; text-align: left">This is to inform you that your access to </span><span style="font-size: 20px; font-weight: 600; color: #333333; letter-spacing: 0.4px; text-align: left">Digital Onboarding Portal</span><span style="font-size: 20px; font-weight: 500; letter-spacing: 0.4px; text-align: left"> has been revoked.<br /> You will no longer have the permissions or privileges associated with the platform. If you believe this change was made in error or require clarification, please contact the account owner for further updates.<br />Remark: </span><span style="font-size: 20px; font-weight: 600; color: #333333; letter-spacing: 0.4px; text-align: left">${reason}</span>

                      </p>
                    </td>
                  </tr>

                <tr>
                  <td align="left" style="padding-top: 13.75px; padding-bottom: 12.15px; padding-left: 32px">
                   <span style="font-size: 20px; font-weight: 500; letter-spacing: 0.4px; text-align: left; color: #777777",>Best regards,</span>
                    <br />
                    <span style="font-size: 20px; font-weight: 500; letter-spacing: 0.4px; text-align: left; color: #777777;">Digital Onboarding Team</span>
                </td>
                </tr>
                </table>
              </td>
            </tr>
          </table>
        </center>
      </body>
    </html>`;
          const emailPayload = {
            to: email,
            body: bodyHtml,
            subject: "Access Revoked from Digital Onboarding",
          };

          axios
            .post(`${config.email_api_endpoint}/send-email`, emailPayload, {
              headers: { "Content-Type": "multipart/form-data", "x-api-key": config.email_key },
            })
            .then((res) => {
              setHideButton(true);
              setLoader(false);
            })
            .catch((err) => {
              console.log("", err);
            });
        });
    } catch (error) {
      setLoader(false);
      console.error("Error updating user:", error.response?.data || error.message);
      alert(error.response?.data?.error || "An unexpected error occurred. Please try again later.");
    }
  };

  const handleTextFilterChange = (e) => {
    const value = e.target.value.toLowerCase();
    setTextFilter(value);
    const filtered = data.filter(
      (user) =>
        user.name.toLowerCase().includes(value) ||
        user.email.toLowerCase().includes(value) ||
        user.status.toLowerCase().includes(value) ||
        user?.auth[0].role[0]?.toLowerCase().includes(value),
    );
    setFilteredData(filtered);
    setCurrentPage(0); // Reset to first page after filtering
  };

  const editUser = (user) => {
    setSelectedUser(user);
    setShowModel(true);
  };

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };
  const currentPageData = filteredData?.slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage);
  console.log("filter--->", userToDelete);

  return (
    <div className={`h-100 w-100 mb-3 ${styles.family}`}>
      {reasonModel && (
        <div className={`modal show d-block ${styles.rejectionModal}`} tabIndex="-1" role="dialog">
          <div className={`modal-dialog modal-dialog-centered ${styles.modalDialog}`} role="document">
            <div className={`modal-content ${styles.modalContent}`}>
              <div className={`modal-header ${styles.modalHeader} d-flex justify-content-center`}>
                <h5 className={`modal-title ${styles.modalTitle}`}>User Removed</h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => setReasonModel(false)}
                />
              </div>
              <div className={`modal-body ${styles.modalBody} d-flex justify-content-center`}>
                <span style={{ fontWeight: "600", fontSize: "16px" }}>{"Reason for removal: "}</span>
                <span className="px-2">{userToDelete?.reason}</span>
              </div>
              <div className={`modal-body ${styles.modalBody} d-flex justify-content-center`}>
                <span style={{ fontWeight: "600", fontSize: "16px" }}>{"Assigned To: "}</span>
                <span className="px-2">{userToDelete?.assigned_to.email}</span>
              </div>
            </div>
          </div>
        </div>
      )}

      {removeUserModel && (
        <div className={`modal show d-block ${styles.rejectionModal}`} tabIndex="-1" role="dialog">
          <div className={`modal-dialog modal-dialog-centered ${styles.modalDialog}`} role="document">
            {userByRole?.length > 0 ? (
              <div className={`modal-content ${styles.modalContent}`}>
                <div className={`modal-header ${styles.modalHeader}`}>
                  <h5 className={`modal-title ${styles.modalTitle}`}>Are you sure you want to remove the user?</h5>
                </div>
                <div className={`modal-body ${styles.modalBody}`}>
                  <FormControl className="w-100 mb-2">
                    <InputLabel id="escrow-select-label">Assign To</InputLabel>
                    <Select
                      labelId="escrow-select-label"
                      id="escrow-select"
                      value={JSON.stringify(assignTo)} // Convert object to string
                      label="Assign To"
                      onChange={(e) => setAssignTo(JSON.parse(e.target.value))} // Parse string back to object
                      sx={{ borderRadius: "12px" }}
                    >
                      {userByRole.map((p, idx) => (
                        <MenuItem key={idx} value={JSON.stringify({ email: p.email, name: p.name })}>
                          {p.name} ({p.email}) {/* Show both name and email */}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {errors.assignTo && <div className={styles.errorMessage}>{errors.assignTo}</div>}

                  <textarea
                    className={`${styles.textarea}`}
                    placeholder="Write reason for rejection..."
                    value={reason}
                    onChange={(e) => setReason(e.target.value)}
                  />
                  {errors.reason && <div className={styles.errorMessage}>{errors.reason}</div>}
                </div>
                <div className={`modal-footer ${styles.modalFooter}`}>
                  <div
                    className={`${styles.cancelButton}  d-flex justify-content-center align-items-center`}
                    onClick={() => {
                      setErrors("");
                      setAssignTo("");
                      setReason("");
                      setRemoveUserModel(false);
                    }}
                  >
                    <button type="button" className={`btn`} style={{ color: "#2348AB" }}>
                      No
                    </button>
                  </div>
                  <div
                    className={`${styles.rejectButton}  d-flex justify-content-center align-items-center`}
                    onClick={() =>
                      deleteUser(userToDelete.user_id, userToDelete.name, userToDelete.email, reason, assignTo)
                    }
                  >
                    <button type="button" className={`btn`} style={{ color: "#ffffff" }}>
                      Yes
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div className={`modal-content ${styles.modalContent}`}>
                <div className={`modal-header ${styles.modalHeader}`}></div>
                <div className={`modal-body ${styles.modalBody}`}>
                  <h5
                    className={`modal-title ${styles.modalTitle} d-flex justify-content-center`}
                    style={{ color: "#DC3545" }}
                  >
                    Unable to delete this user.
                  </h5>
                  <h5
                    className={`modal-title ${styles.modalTitle} d-flex justify-content-center`}
                    style={{ color: "#DC3545" }}
                  >
                    No other {userToDelete.auth[0].role.toString()} available to reassign.
                  </h5>
                </div>
                <div className={`modal-footer ${styles.modalFooter}`}>
                  <div
                    className={`${styles.cancelButton} d-flex justify-content-center align-items-center`}
                    onClick={() => {
                      setErrors("");
                      setAssignTo("");
                      setReason("");
                      setRemoveUserModel(false);
                    }}
                  >
                    <button type="button" className={`btn`} style={{ color: "#2348AB" }}>
                      Close
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      <div className={`d-flex align-items-center justify-content-end mb-3`}>
        <button
          onClick={() => {
            setShowModel(true);
          }}
          className={`${styles.newCompany} ${role.toString() === "Owner" || role.toString() === "Super Admin" || role.toString() === "Admin" ? "" : styles.disabledDiv}`}
        >
          <img className={`${styles.plusCircleIcon} pe-1`} alt="" src="/pluscircle.svg" />
          <b className={styles.addNewCompany}>New User</b>
        </button>
      </div>
      {showModel && (
        <UserModal
          showModal={showModel}
          setShowModal={setShowModel}
          selectedUser={selectedUser}
          setSelectedUser={setSelectedUser}
          setFilteredData={setFilteredData}
          clientId={clientId}
        />
      )}
      <Spin
        size="large"
        tip="Loading..."
        spinning={loader}
        className="d-flex justify-content-center align-items-center w-100 h-100"
        style={{ maxHeight: "none" }}
      >
        <div className={`${`${styles.tableBg} d-flex justify-content-between p-3 w-100`}`}>
          <h2 className={styles.title}>User List</h2>
          <div>
            <img className={styles.leadingIcon} alt="" src="/leadingicon.svg" />
            <TextField
              label="Search"
              variant="standard"
              // variant="outlined"
              size="small"
              className={styles.searchInput}
              value={textFilter}
              onChange={handleTextFilterChange}
            />
          </div>
          {/* <TextField
            placeholder="Search..."
            value={textFilter}
            onChange={handleTextFilterChange}
            variant="outlined"
            size="small"
            className={styles.searchInput}
          /> */}
        </div>
        <Paper>
          <TableContainer>
            <Table>
              <TableHead
                className={styles.tableBg}
                sx={{
                  "& .MuiTableCell-root": {
                    padding: "16px",
                  },
                }}
              >
                <TableRow>
                  <TableCell align="center">Name</TableCell>
                  <TableCell align="center">Email</TableCell>
                  <TableCell align="center">Role</TableCell>
                  <TableCell align="center">Status</TableCell>
                  <TableCell align="center">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {currentPageData?.length > 0 ? (
                  currentPageData.map((user) => (
                    <TableRow key={user._id} sx={{ "& .MuiTableCell-root": { padding: "8px" } }}>
                      <TableCell align="center" title={user.name.length > 30 ? user.name : ""}>
                        {" "}
                        {user.name.length > 30 ? user.name.slice(0, 30) + "..." : user.name}
                      </TableCell>
                      <TableCell
                        align="center"
                        title={user.email.length > 30 ? user.email : ""}
                        style={{ color: `${user.email === companyStore.AdminEmail ? "rgb(35,72,171)" : ""}` }}
                      >
                        {user.email.length > 30 ? user.email.slice(0, 30) + "..." : user.email}
                      </TableCell>
                      <TableCell align="center">{user.auth[0].role.map((data) => data)}</TableCell>
                      <TableCell align="center">{user.status}</TableCell>
                      <TableCell align="center">
                        {user.status === "removed" ? (
                          <div>
                            <Button
                              style={{ color: "#2348AB" }}
                              className={` ${role.toString() === "Owner" && user.auth[0].role.map((data) => data).toString() === "Owner" ? styles.disabledDiv : role.toString() === "Admin" && (user.auth[0].role.map((data) => data).toString() === "Admin" || user.auth[0].role.map((data) => data).toString() === "Super Admin" || user.auth[0].role.map((data) => data).toString() === "Owner") ? styles.disabledDiv : role.toString() === "Super Admin" && (user.auth[0].role.map((data) => data).toString() === "Super Admin" || user.auth[0].role.map((data) => data).toString() === "Owner") ? styles.disabledDiv : role.toString() === "Approver" ? styles.disabledDiv : role.toString() === "Approver" ? styles.disabledDiv : role.toString() === "Approver" ? styles.disabledDiv : role.toString() === "Maker" ? styles.disabledDiv : role.toString() === "Viewer" ? styles.disabledDiv : ""}`}
                              onClick={() => editUser(user)}
                            >
                              Edit
                            </Button>

                            <span className="px-2">{"/"}</span>
                            <Button
                              style={{ color: "#2348AB" }}
                              onClick={() => {
                                setReasonModel(true);
                                setUserToDelete(user);
                              }}
                            >
                              {"View Reason"} &nbsp; <img className={`${styles.plusCircleIcon} pb-1`} src="/eye.svg" />
                            </Button>
                          </div>
                        ) : (
                          <>
                            <Button
                              style={{ color: "#2348AB" }}
                              className={` ${role.toString() === "Owner" && user.auth[0].role.map((data) => data).toString() === "Owner" ? styles.disabledDiv : role.toString() === "Admin" && (user.auth[0].role.map((data) => data).toString() === "Admin" || user.auth[0].role.map((data) => data).toString() === "Super Admin" || user.auth[0].role.map((data) => data).toString() === "Owner") ? styles.disabledDiv : role.toString() === "Super Admin" && (user.auth[0].role.map((data) => data).toString() === "Super Admin" || user.auth[0].role.map((data) => data).toString() === "Owner") ? styles.disabledDiv : role.toString() === "Approver" ? styles.disabledDiv : role.toString() === "Approver" ? (styles.disabledDiv ? role.toString() === "Approver" : styles.disabledDiv) : role.toString() === "Maker" ? styles.disabledDiv : role.toString() === "Viewer" ? styles.disabledDiv : ""}`}
                              onClick={() => editUser(user)}
                            >
                              Edit
                            </Button>

                            <span className="px-2">{"/"}</span>

                            <img
                              className={`${styles.plusCircleIcon} pe-1 ${role.toString() === "Owner" && user.auth[0].role.map((data) => data).toString() === "Owner" ? styles.disabledDiv : role.toString() === "Admin" && (user.auth[0].role.map((data) => data).toString() === "Admin" || user.auth[0].role.map((data) => data).toString() === "Super Admin" || user.auth[0].role.map((data) => data).toString() === "Owner") ? styles.disabledDiv : role.toString() === "Super Admin" && (user.auth[0].role.map((data) => data).toString() === "Super Admin" || user.auth[0].role.map((data) => data).toString() === "Owner") ? styles.disabledDiv : role.toString() === "Approver" ? styles.disabledDiv : role.toString() === "Approver" ? styles.disabledDiv : role.toString() === "Approver" ? styles.disabledDiv : role.toString() === "Maker" ? styles.disabledDiv : role.toString() === "Viewer" ? styles.disabledDiv : ""}`}
                              onClick={() => {
                                setUserToDelete(user); // Set user to delete
                                setRemoveUserModel(true); // Open the delete confirmation modal
                              }}
                              style={{ cursor: "pointer" }}
                              src="/DeleteIcon.svg"
                            />
                          </>
                        )}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={5} align="center">
                      No Records Found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            className={`${styles.tableBg}`}
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredData?.length}
            rowsPerPage={rowsPerPage}
            page={currentPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{
              "& .MuiTablePagination-selectLabel": {
                marginTop: "14px",
              },
              "& .MuiTablePagination-displayedRows": {
                marginTop: "14px",
              },
            }}
          />
        </Paper>
      </Spin>
    </div>
  );
};

export default UserManagement;

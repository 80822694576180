import { useCallback } from "react";
import { TextField, InputAdornment, Icon, IconButton, FormControl } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import styles from "./CompanyKYC.module.css";
import { useEffect, useState } from "react";
import axios from "axios";
import Lottie from "lottie-react";
import load from "./LoadGIF.json";
import config from "../../../../service/config";
import companyStore from "../../CompanyStore";
import OfflineKyc from "./OfflineCompanyKyc/OfflineKyc";
import { Spin } from "antd";
import { useAllProduct } from "../../../api-context/commonApiContext";

const CompanyKYC = ({ className = "" }) => {
  const { loader, setLoader, authTokens } = useAllProduct();
  const [compName, setcompName] = useState("");
  const [POCname, setPOCName] = useState("");
  const [email, setEmail] = useState("");
  const [data, setData] = useState("");
  const [remark, setRemark] = useState("");
  const [id, setID] = useState("");
  const [Name, setName] = useState("");
  const [escrowName, setEscrowName] = useState("");
  const [len, setLen] = useState();
  const [clicked, setClicked] = useState(false);
  const [mode, setMode] = useState("external");
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    setID(companyStore.companyID);
    setName(companyStore.companyName);
    setEscrowName(companyStore.escrowName);
  }, []);

  useEffect(() => {
    setErrors({});
    setEmail("");
    setPOCName("");
    setRemark("");
  }, [mode]);

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const handleClick = () => {
    const validationErrors = {};

    if (!POCname.trim()) {
      validationErrors.POCname = "Authorized Signatory is required.";
    } else if (POCname.trim().length < 3) {
      validationErrors.POCname = "Authorized Signatory must be at least 3 characters long.";
    } else if (POCname.trim().length > 100) {
      validationErrors.POCname = "Authorized Signatory cannot exceed 100 characters.";
    }
    if (!email.trim()) validationErrors.email = "Email ID is required.";
    else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) validationErrors.email = "Invalid email format.";

    if (remark.trim().length > 250) {
      validationErrors.remark = "Remark cannot exceed 250 characters.";
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    setErrors({});
    setClicked(true);
    const obj = {
      company_id: id,
      company_name: Name,
      authorized_signatory_email: email,
      authorized_signatory: POCname,
      remark: remark,
      mode: "online",
      created_by: companyStore?.AdminEmail,
      // request: request,
    };
    setLoader(true);
    axios
      .post(`${config.node_api_endpoint}/v2/company/generate_kyb_request/form`, obj, {
        headers: {
          aws_cognito_id_token: authTokens.idToken,
          "Content-Type": "application/json",
        },
      })
      .then(() => {
        setLoader(false);
        console.log("Data has been Send");
        handleScrollToTop();
        navigate(`/content/company/kyc_request`);
        setcompName("");
        setEmail("");
        setPOCName("");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const clearAll = () => {
    setcompName("");
    setEmail("");
    setPOCName("");
    setRemark("");
  };
  const handlenavigate = () => {
    navigate(`/content/company/kyclist/main`);
  };

  const handleMode = () => {
    setMode(!mode);
  };

  return (
    <Spin
      size="large"
      tip="Loading..."
      spinning={loader}
      className="d-flex justify-content-center align-items-center w-100 h-100"
      wrapperClassName="spin-nested-loading"
      style={{ maxHeight: "none" }}
    >
      <div className={[styles.pageContainer, className].join(" ")}>
        <div className={styles.frameParent}>
          <button
            className={styles.arrowNarrowLeftParent}
            // onClick={}
            onClick={handlenavigate}
          >
            <img className={styles.arrowNarrowLeftIcon} alt="" src="/arrownarrowleft1.svg" />
            <div className={styles.back}>Back</div>
          </button>
          <b className={`${styles.escrowName} mt-4`} title={escrowName.length > 50 ? escrowName : ""}>
            {escrowName.length > 50 ? escrowName.slice(0, 50) + "..." : escrowName}
          </b>
          <b className={`${styles.compName} mt-2 mb-4`}>{Name}</b>
          <div className="d-flex align-items-center w-100 mb-4">
            <div>
              <label className="d-flex align-items-center pe-4">
                Send To <span style={{ color: "red" }}>*</span>
              </label>
            </div>
            <div className="pe-3">
              <input
                type="radio"
                id="external"
                name="mode"
                value="external"
                checked={mode === "external"}
                onChange={(e) => setMode(e.target.value)}
              />
              <label className="p-2" htmlFor="external">
                Customer
              </label>
            </div>
            <div>
              <input
                type="radio"
                id="internal"
                name="mode"
                value="internal"
                checked={mode === "internal"}
                onChange={(e) => setMode(e.target.value)}
              />
              <label className="p-2" htmlFor="internal">
                Internal Team
              </label>
            </div>
          </div>

          {mode === "external" ? (
            <>
              <div className={`${styles.frameGroup}`}>
                <div className={`${styles.companyKycParent} mb-5`}>
                  <div className={styles.companyKyc}>Company KYC</div>
                  <button className={styles.input} onClick={clearAll}>
                    Clear
                  </button>
                </div>
                <div className={styles.mobileMenuParent}>
                  <TextField
                    className={styles.mobileMenu}
                    color="primary"
                    label="Company Name"
                    variant="outlined"
                    value={Name}
                    disabled={true}
                    sx={{ "& .MuiInputBase-root": { height: "56px", color: "black !important" } }}
                  />

                  <div className={styles.mobileMenuGroup}>
                    <TextField
                      className={styles.mobileMenu1}
                      color="primary"
                      label="Authorized Signatory"
                      variant="outlined"
                      value={POCname}
                      onChange={(e) => {
                        setPOCName(e.target.value);
                      }}
                      sx={{ "& .MuiInputBase-root": { height: "56px" } }}
                      error={!!errors.POCname}
                      helperText={errors.POCname ? errors.POCname : <div>&nbsp;</div>}
                    />
                    <div className={styles.mobileMenu1}>
                      <TextField
                        // className={styles.field}
                        className="w-100"
                        color="primary"
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                        label="Email ID"
                        variant="outlined"
                        type="email"
                        sx={{
                          "& .MuiInputBase-root": { height: "56px" },
                          width: "344px",
                        }}
                        error={!!errors.email}
                        // helperText={errors.email}
                        helperText={errors.email ? errors.email : <div>&nbsp;</div>}
                      />
                    </div>
                  </div>

                  {/* <div className={styles.mobileMenu3}>
                    <textarea
                      className={`${styles.field1} w-100`}
                      placeholder="Remark"
                      type="text"
                      value={remark}
                      onChange={(e) => {
                        setRemark(e.target.value);
                      }}
                      style={{ resize: "none", height: "100px" }}
                    />
                  </div> */}
                  <div className={`w-100 d-flex justify-content-around`}>
                    <FormControl className="w-100">
                      <TextField
                        label="Remark"
                        value={remark}
                        onChange={(e) => setRemark(e.target.value)}
                        variant="outlined"
                        // onBlur={() => setFieldErrors((prev) => ({ ...prev, remark: validateField("remark", remark) }))}
                        // error={!!fieldErrors.remark}
                        // helperText={fieldErrors.remark}
                        InputProps={{ style: { height: 100 } }} // Adjust height here
                      />
                    </FormControl>
                  </div>
                </div>
                {errors.remark && <div className={`${styles.errorMsg}`}>{errors.remark}</div>}
                <div className="w-100 d-flex align-items-center justify-content-start mt-4">
                  <button
                    className={`${styles.loadsButtons} w-50 d-flex align-items-center justify-content-center`}
                    onClick={() => {
                      handleClick();
                    }}
                  >
                    {clicked && (
                      <Lottie className={styles.httpslottiefilescomanimatIcon} animationData={load} loop={true} />
                    )}

                    <b className={`${styles.generateRequest} w-50 d-flex align-items-center justify-content-center `}>
                      Generate Request
                    </b>
                  </button>
                </div>
              </div>
            </>
          ) : (
            <div className={`${styles.frameGroupOffline} mb-5`}>
              <OfflineKyc loader={loader} setLoader={setLoader} />
            </div>
          )}
        </div>
      </div>
    </Spin>
  );
};

CompanyKYC.propTypes = {
  className: PropTypes.string,
};

export default CompanyKYC;

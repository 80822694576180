import {
  Select,
  InputLabel,
  MenuItem,
  FormHelperText,
  FormControl,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
} from "@mui/material";
import PropTypes from "prop-types";
import styles from "./MakeEditor.module.css";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import config from "../../../../../service/config";
import companyStore from "../../../CompanyStore";
import { useAllProduct } from "../../../../api-context/commonApiContext";

const MakeEditor = ({
  isVisible,
  onclose,
  setClicked,
  makeEditor,
  contract_id,
  escrow_id,
  email,
  count,
  setIsSuccess,
}) => {
  const { authTokens, authFromUrl, setLoader } = useAllProduct();
  const [users, setUsers] = useState([]);
  const [selectedActions, setSelectedActions] = useState({});
  const [loading, setLoading] = useState(false);
  const [sendMail, isSendmail] = useState(false);
  const [error, setError] = useState(null);
  const [currenteditor, setcurrenteditor] = useState("");
  const [data, setData] = useState({});
  const [doc_link, setDocLink] = useState("");
  const formData = new FormData();
  const modalRef = useRef(null);
  const [currentEditAccess, setCurrentEditAccess] = useState("");
  const [previousCount, setPreviousCount] = useState(0);

  useEffect(() => {
    setLoading(true);
    const obj = {
      escrow_id: companyStore?.escrowId,
      contract_id: companyStore?.Contract_id,
    };

    axios
      .post(`${config.mock_api_endpoint}/api/getContractData`, obj, {
        headers: {
          "x-encrypted-token": "a25b2963bab38ab7577575df9e80a27a",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log("res-->", res);

        setData(res.data);
        let accessNotRemoved = res.data.counter_parties.filter((data) => data.status !== "Removed");
        setUsers(accessNotRemoved);
        const initialActions = {};
        res.data.counter_parties.forEach((user) => {
          // initialActions[user.email] = /* user.access || */ "view";
          initialActions[user.email] = /*  user.access || */ "view";
        });
        setSelectedActions(initialActions);
        setCurrentEditAccess(res.data.current_edit_access);
        setPreviousCount(res.data.changes_count);
      })
      .catch((err) => {
        console.error("%o", err);
        setError("Failed to load data");
      })
      .finally(() => {
        setLoading(false);
      });
  }, [isVisible, companyStore, authTokens, authFromUrl]);

  useEffect(() => {
    if (sendMail) {
      mailToAdmin();
    }
  }, [sendMail]);

  const handleActionChange = (userId, access) => {
    setSelectedActions((prev) => {
      const newActions = { ...prev }; // Clone the previous state

      if (access === "edit") {
        // Set all other users to "view"
        Object.keys(newActions).forEach((id) => {
          if (id !== userId) {
            newActions[id] = "view";
          } else {
            setcurrenteditor(userId);
            newActions[id] = "edit"; // Set selected user to "edit"
          }
        });
      } else {
        newActions[userId] = access; // If access is not "edit", just set the user access
      }

      return newActions;
    });
  };

  const handleSubmit = () => {
    var currentuser = "";

    // // Map through users and select actions
    let updatedActions = users.map((user) => {
      return {
        email: user.email,
        access: selectedActions[user.email] || "view",
      };
    });
    console.log("updatedAction", updatedActions);

    // Process the actions if "edit" access exists
    updatedActions.map((user) => {
      if (user.access === "edit") {
        setcurrenteditor(user.email); // Set the current editor
        currentuser = user.email;
        setDocLink(user.doc_link); // Set the document link
      }
    });

    // Prepare the payload to be sent to the backend
    const payload = {
      escrow_id: companyStore.escrowId,
      contract_id: companyStore.Contract_id,
      current_edit_access: currentuser,
      changes_count: count,
      previous_count: previousCount,
    };

    // Submit the data to modify access

    axios
      .post(`${config.mock_api_endpoint}/api/modifyAccess`, payload, {
        headers: {
          "x-encrypted-token": "a25b2963bab38ab7577575df9e80a27a",
          // "Content-Type": "application/json",
        },
      })
      .then((res) => {
        onclose();
        // setClicked(true);
        // For each counter party, prepare and send an email
        res.data.updatedContract.counter_parties.forEach((rres) => {
          let body = `
            <html>
              <head>
                <style>
                  body { font-family: Arial, sans-serif; line-height: 1.6; color: #333; }
                  .container { max-width: 600px; margin: auto; padding: 20px; }
                  h1 { color: #007BFF; }
                  a { color: #007BFF; text-decoration: none; }
                  .button { display: inline-block; padding: 10px 20px; font-size: 16px; color: white; background-color: #007BFF; border: none; border-radius: 5px; text-decoration: none; text-align: center; }
                  .footer { margin-top: 20px; font-size: 0.9em; color: #777; }
                </style>
              </head>
              <body>
                <div style="font-size: 0px; line-height: 1px; mso-line-height-rule: exactly; display: none; max-width: 0px; max-height: 0px; opacity: 0; overflow: hidden; mso-hide: all"></div>
                <center lang="und" dir="auto" style="width: 100%; table-layout: fixed; -webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%">
                  <table class="bg-fffffe" cellpadding="0" cellspacing="0" border="0" role="presentation" bgcolor="white" width="657.00" style="border-radius: 12px; background-color: white; padding-top: 32px; padding-bottom: 32px; width: 657px; border-spacing: 0; font-family: Sarabun, Tahoma, sans-serif; min-width: 657px; border-collapse: separate">
                    <tr>
                      <td valign="middle" width="100.00%" style="width: 100%; vertical-align: middle">
                        <table cellpadding="0" cellspacing="0" border="0" role="presentation" width="100.00%" style="width: 100%; border-spacing: 0">
                          <tr>
                            <td align="center" style="padding-bottom: 13.76px">
                              <img src="cid:logo" width="101" height="50" alt="Logo" />
                            </td>
                          </tr>
                          <tr>
                            <td align="left" style="padding-top: 13.76px; padding-bottom: 13.72px; padding-left: 32px">
                              <p class="color-4c4e64" width="100.00%" style="font-size: 20px; font-weight: 400; letter-spacing: 0.4px; color: #4C4E64; margin: 0; padding: 0; width: 100%; line-height: 21.44px; mso-line-height-rule: exactly"><span>Dear </span><span style="font-size: 20px; font-weight: 600; letter-spacing: 0.4px; text-align: left">${rres.name},</span></p>
                            </td>
                          </tr>
                          <tr>
                            <td align="left" style="padding-top: 13.72px; padding-bottom: 15.16px; padding-left: 32px; padding-right: 32px">
                              <p width="100.00%" style="text-align: left; color: #777777; margin: 0; padding: 0; width: 100%"><span class="color-777777" style="font-size: 20px; font-weight: 400; color: #777777; letter-spacing: 0.4px; text-align: left">You have been assigned as an </span><span style="font-size: 20px; font-weight: 600; color: #333333; letter-spacing: 0.4px; text-align: left">Editor</span><span class="color-777777" style="font-size: 20px; font-weight: 400; color: #777777; letter-spacing: 0.4px; text-align: left"> for the contract titled</span><span class="color-777777" style="font-size: 20px; font-weight: 600; color: #777777; letter-spacing: 0.4px; text-align: left"> </span><span style="font-size: 20px; font-weight: 600; color: #333333; letter-spacing: 0.4px; text-align: left">&quot;${data.contract_name} &quot;.</span><span class="color-777777" style="font-size: 20px; font-weight: 600; color: #777777; letter-spacing: 0.4px; text-align: left"> </span><span class="color-777777" style="font-size: 20px; font-weight: 500; color: #777777; letter-spacing: 0.4px; text-align: left"> As an editor, you can review and make changes to the document directly through our document editor. </span></p>
                            </td>
                          </tr>
                          <tr>
                            <td align="left" style="padding-top: 15.16px; padding-bottom: 7.58px; padding-left: 32px">
                              <p width="100.00%" style="font-size: 20px; font-weight: 700; letter-spacing: 0.4px; color: #333333; margin: 0; padding: 0; width: 100%; line-height: 15.68px; mso-line-height-rule: exactly">Please follow the link below to edit the contract:</p>
                            </td>
                          </tr>
                          <tr>
                            <td style="padding-top: 7.58px; padding-left: 33px; padding-right: 33px">
                              <table class="bg-2348ab" cellpadding="0" cellspacing="0" border="0" role="presentation" bgcolor="#2348AB" width="591.00" height="40.00" style="border-radius: 30px; background-color: #2348AB; width: 591px; height: 40px; border-spacing: 0; border-collapse: separate">
                                <tr>
                                  <td valign="middle" width="100.00%" height="40.00" style="width: 100%; vertical-align: middle; height: 40px">
                                    <a href="${config.doc_link_api_endpoint}${rres.doc_link}" style="text-decoration: none;">
                                      <p class="color-fffffe" width="100.00%" style="font-size: 16px; font-weight: 700; letter-spacing: 0.15px; color: white; margin: 0; padding: 0; width: 100%; line-height: 24px; text-align: center; mso-line-height-alt: 24px">
                                        Edit Contract
                                      </p>
                                    </a>
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                          <tr>
              <td align="left" style="padding-top: 13.75px; padding-bottom: 12.15px; padding-left: 32px">
               <span style="font-size: 20px; font-weight: 500; letter-spacing: 0.4px; text-align: left; color: #777777",>Best regards,</span>
                <br />
                <span style="font-size: 20px; font-weight: 500; letter-spacing: 0.4px; text-align: left; color: #777777;">Digital Onboarding Team</span>
            </td>
            </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                </center>
              </body>
            </html>
          `;

          // Send email if the user is the editor

          let payload2 = {
            contract_id: contract_id,
            escrow_id: escrow_id,
            changes_count: count,
            previous_count: previousCount,
            email: email,
            status: "Finalized",
          };

          if (!makeEditor) {
            axios
              .post(`${config.mock_api_endpoint}/api/saveAndSend`, payload2, {
                headers: {
                  "x-encrypted-token": "a25b2963bab38ab7577575df9e80a27a",
                  // "Content-Type": "application/json",
                },
              })
              .then((res) => {
                console.log("testRes", res.data);
                const allFinalized = res.data.updatedCounterParties.counter_parties.every(
                  (counterparty) => counterparty.status === "Finalized",
                );

                isSendmail(allFinalized ? true : false);
                setClicked(true);
                setIsSuccess(allFinalized ? false : true);
              })
              .catch((err) => {
                console.log(err);
              });
          }

          if (rres.email === currentuser) {
            const emailPayload = {
              to: currentuser,
              body: body,
              subject: `Access and Edit Contract - ${data.contract_name}`,
            };
            axios
              .post(`${config.email_api_endpoint}/send-email`, emailPayload, {
                headers: {
                  "Content-Type": "multipart/form-data",
                  "x-api-key": config.email_key,
                },
              })
              .then((response) => {
                setClicked(true);
                console.log("Email sent successfully:", response);
                setIsSuccess(true);
              })
              .catch((error) => {
                console.error("Error sending email:", error);
              });
          }
        });
      })

      .catch((error) => {
        console.error("Error modifying access:", error);
      });
  };

  const mailToAdmin = () => {
    setLoader(true);
    const obj = {
      escrow_id: companyStore?.escrowId,
      contract_id: companyStore?.Contract_id,
    };

    axios
      .post(`${config.mock_api_endpoint}/api/getContractData`, obj, {
        headers: {
          "x-encrypted-token": "a25b2963bab38ab7577575df9e80a27a",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        const contractName = res.data.contract_name;
        const adminMail = res.data.admin_email;
        const allFinalized = res.data.counter_parties.every((counterparty) => counterparty.status === "Finalized");
        const counterpartyNames = res.data.counter_parties.map((cp) => cp.name).join(", ");
        console.log("resContract-->", counterpartyNames, allFinalized);

        if (allFinalized) {
          let bodyHtmlAdmin = `
          <!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
      <html lang="und" dir="auto" xmlns="http://www.w3.org/1999/xhtml">
        <head>
          <style type="text/css">
            @import url("https://fonts.googleapis.com/css2?family=Sarabun:wght@400;500;600;700");
          </style>
          <title></title>
          <!--[if (!mso)&(!ie)]>These<!-- -->
          <!--<![endif]-->
          <!--[if (!mso)&(!ie)]>are<!-- -->
          <!--<![endif]-->
          <!--[if (!mso)&(!ie)]>for<!-- -->
          <!--<![endif]-->
          <!--[if (!mso)&(!ie)]>outlook<!-- -->
          <!--<![endif]-->
          <!--[if (!mso)&(!ie)]>live<!-- -->
          <!--<![endif]-->
          <!--[if (!mso)&(!ie)]>that<!-- -->
          <!--<![endif]-->
          <!--[if (!mso)&(!ie)]>removes<!-- -->
          <!--<![endif]-->
          <!--[if (!mso)&(!ie)]>the first<!-- -->
          <!--<![endif]-->
          <!--[if (!mso)&(!ie)]>10 well-formed<!-- -->
          <!--<![endif]-->
          <!--[if (!mso)&(!ie)]>conditional comments<!-- -->
          <!--<![endif]-->
          <!--[if gte mso 9]>
            <xml>
              <o:OfficeDocumentSettings xmlns:o="urn:schemas-microsoft-com:office:office">
                <o:AllowPNG />
                <o:PixelsPerInch>96</o:PixelsPerInch>
              </o:OfficeDocumentSettings>
            </xml>
          <![endif]-->
          <style type="text/css">
            .dark-mode .bg-fffffe {
              background-color: #FFFFFE !important;
            }
            .dark-mode .color-4c4e64 {
              color: #4C4E64 !important;
            }
            .dark-mode .color-777777 {
              color: #777777 !important;
            }
            @media (prefers-color-scheme: dark) {
              html:not(.light-mode) .bg-fffffe {
                background-color: #FFFFFE !important;
              }
              html:not(.light-mode) .color-4c4e64 {
                color: #4C4E64 !important;
              }
              html:not(.light-mode) .color-777777 {
                color: #777777 !important;
              }
            }
            [data-ogsc] .bg-fffffe {
              background-color: #FFFFFE !important;
            }
            [data-ogsc] .color-4c4e64 {
              color: #4C4E64 !important;
            }
            [data-ogsc] .color-777777 {
              color: #777777 !important;
            }
          </style>
          <meta name="color-scheme" content="light dark" />
          <meta name="supported-color-schemes" content="light dark" />
          <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
          <!--[if !mso]><!-->
          <meta http-equiv="X-UA-Compatible" content="IE=edge" />
          <!--<![endif]-->
          <meta name="x-apple-disable-message-reformatting" />
          <style></style>
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          <style type="text/css">
            u + div .kombai-email-compat__list-with-padding-left {
              padding-left: 0.5em !important;
            }
          </style>
          <!--[if mso]>
            <style type="text/css">
              v\:* {
                behavior: url(#default#VML);
                display: inline-block;
              }
              o\:* {
                behavior: url(#default#VML);
                display: inline-block;
              }
              w\:* {
                behavior: url(#default#VML);
                display: inline-block;
              }
              .ExternalClass {
                width: 100%;
              }
              table {
                mso-table-lspace: 0pt;
                mso-table-rspace: 0pt;
              }
              img {
                -ms-interpolation-mode: bicubic;
              }
              .ReadMsgBody {
                width: 100%;
              }
              a {
                background: transparent !important;
                background-color: transparent !important;
              }
              li {
                text-align: -webkit-match-parent;
                display: list-item;
                text-indent: -1em;
              }
              ul,
              ol {
                margin-left: 1em !important;
              }
              p {
                text-indent: 0;
              }
            </style>
          <![endif]-->
        </head>
        <body style="margin: 0; padding: 0">
          <div style="font-size: 0px; line-height: 1px; mso-line-height-rule: exactly; display: none; max-width: 0px; max-height: 0px; opacity: 0; overflow: hidden; mso-hide: all"></div>
          <center lang="und" dir="auto" style="width: 100%; table-layout: fixed; -webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%">
            <table class="bg-fffffe" cellpadding="0" cellspacing="0" border="0" role="presentation" bgcolor="white" width="657.00" style="border-radius: 12px; background-color: white; padding-top: 32px; padding-bottom: 31.16px; width: 657px; border-spacing: 0; font-family: Sarabun, Tahoma, sans-serif; min-width: 657px; border-collapse: separate">
              <tr>
                <td valign="middle" width="100.00%" style="width: 100%; vertical-align: middle">
                  <table cellpadding="0" cellspacing="0" border="0" role="presentation" width="100.00%" style="width: 100%; border-spacing: 0">
                    <tr>
                      <td align="center" style="padding-bottom: 13.76px">
                      <img src="cid:logo" width="101" height="50" alt="Logo" />
                      </td>
                    </tr>
                    <tr>
                      <td align="left" style="padding-top: 13.76px; padding-bottom: 13.72px; padding-left: 32px">
                        <p class="color-4c4e64" width="100.00%" style="font-size: 20px; font-weight: 400; letter-spacing: 0.4px; color: #4C4E64; margin: 0; padding: 0; width: 100%; line-height: 21.44px; mso-line-height-rule: exactly"><span>Dear </span><span style="font-size: 20px; font-weight: 600; letter-spacing: 0.4px; text-align: left">Admin,</span></p>
                      </td>
                    </tr>
                    <tr>
                      <td align="left" style="padding-top: 13.72px; padding-left: 32px; padding-right: 32px">
                        <div class="color-777777" width="100.00%" style="font-size: 20px; font-weight: 500; letter-spacing: 0.4px; text-align: left; color: #777777; margin: 0; padding: 0; width: 100%">
                          <span style="font-size: 20px; font-weight: 700; color: #333333; letter-spacing: 0.4px; text-align: left">&quot;${counterpartyNames} &quot;</span><span> has finalised their changes to the contract titled. Please review the proposed changes at your earliest convenience..<br /><br /></span>
                          <span><br /></span><span style="font-size: 20px; font-weight: 500; color: #333333; letter-spacing: 0.4px; text-align: left">Contract Details</span>
                          <ul class="kombai-email-compat__list-with-padding-left" style="list-style-type: disc; margin: 0; padding-left: 1.5em">
                            <li><span>Contract Name: </span><span style="font-size: 20px; font-weight: 700; color: #333333; letter-spacing: 0.4px; text-align: left">${contractName}</span></li>
                            <li><span>CounterParty Name: </span><span style="font-size: 20px; font-weight: 700; color: #333333; letter-spacing: 0.4px; text-align: left">${counterpartyNames}</span></li>
                            <li><span>Status: </span><span style="font-size: 20px; font-weight: 700; color: #333333; letter-spacing: 0.4px; text-align: left">Changes Finalised</span></li>
                          </ul>
                          <span style="font-size: 20px; font-weight: 700; color: #333333; letter-spacing: 0.4px; text-align: left"><br /></span><span>Once reviewed, you may accept or reject the changes to move forward with the negotiation process.<br /><br />Thank you for your participation, and we look forward to completing the agreement soon.<br /></span>
                        </div>
                      </td>
                    </tr>
                      <tr>
                  <td align="left" style="padding-top: 13.75px; padding-bottom: 12.15px; padding-left: 32px">
                   <span style="font-size: 20px; font-weight: 500; letter-spacing: 0.4px; text-align: left; color: #777777",>Best regards,</span>
                    <br />
                    <span style="font-size: 20px; font-weight: 500; letter-spacing: 0.4px; text-align: left; color: #777777;">Digital Onboarding Team</span>
                </td>
                </tr>
                  </table>
                </td>
              </tr>
            </table>
          </center>
        </body>
      </html>
          `;
          const AdminEmailPayload = {
            to: adminMail,
            body: bodyHtmlAdmin,
            subject: `Contract Changes Finalised by ${counterpartyNames} for ${contractName}`,
          };
          axios
            .post(`${config.email_api_endpoint}/send-email`, AdminEmailPayload, {
              headers: {
                "Content-Type": "multipart/form-data",
                "x-api-key": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9",
              },
            })
            .then((res) => {
              setLoader(false);
              setIsSuccess(true);
              console.log("email sent");
              isSendmail(false);
            })
            .catch((err) => {
              console.log("", err);
            });
        }
      })
      .catch((err) => {
        console.error("%o", err);
        setError("Failed to load data");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (!isVisible) return null;

  return (
    <div className={styles.makeEditor}>
      <div className={styles.makeEditorInner} ref={modalRef}>
        <div className={styles.frameParent}>
          <div className={styles.makeEditorParent}>
            <div className={styles.makeEditor1}>Make Edit</div>
            <img className={styles.xCircleIcon} alt="" src="/xcircle.svg" onClick={onclose} />
          </div>

          <TableContainer component={Paper} className={styles.tableContainer}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <b>Name</b>
                  </TableCell>
                  <TableCell>
                    <b>Email</b>
                  </TableCell>
                  <TableCell>
                    <b>Access</b>
                  </TableCell>
                  {/* <TableCell><b>Action</b></TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {!makeEditor
                  ? users
                      .filter((user) => !(user.email === currentEditAccess))
                      .map((user) => (
                        <TableRow key={user.email}>
                          <TableCell>{user.name}</TableCell>
                          <TableCell>{user.email}</TableCell>
                          <TableCell>
                            <FormControl fullWidth>
                              {/* <InputLabel>Select Access</InputLabel> */}
                              <Select
                                value={selectedActions[user.email]}
                                onChange={(event) => handleActionChange(user.email, event.target.value)}
                                disabled={
                                  !makeEditor && user.email === currentEditAccess // Disable if not current editor
                                }
                              >
                                <MenuItem value="view">View</MenuItem>
                                <MenuItem value="edit">Edit</MenuItem>
                              </Select>
                            </FormControl>
                          </TableCell>
                        </TableRow>
                      ))
                  : users.map((user) => (
                      <TableRow key={user.email}>
                        <TableCell>{user.name}</TableCell>
                        <TableCell>{user.email}</TableCell>
                        <TableCell>
                          <FormControl fullWidth>
                            {/* <InputLabel>Select Access</InputLabel> */}
                            <Select
                              value={selectedActions[user.email]}
                              onChange={(event) => handleActionChange(user.email, event.target.value)}
                              // disabled={
                              //   !makeEditor && user.email === currentEditAccess // Disable if not current editor
                              // }
                            >
                              <MenuItem value="view">View</MenuItem>
                              <MenuItem value="edit">Edit</MenuItem>
                            </Select>
                          </FormControl>
                        </TableCell>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
          </TableContainer>

          <div className={styles.actionsContainer}>
            <button onClick={handleSubmit}>Submit</button>
          </div>
        </div>
      </div>
    </div>
  );
};

MakeEditor.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onclose: PropTypes.func.isRequired,
};

export default MakeEditor;

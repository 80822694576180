import PropTypes from "prop-types";
import styles from "./ContractMain.module.css";
import { Fragment, useEffect, useState } from "react";
import axios from "axios";
import config from "../../../../service/config";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";
import moment from "moment";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useNavigate } from "react-router-dom";
import companyStore from "../../CompanyStore";
import { Pagination, Spin } from "antd";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { useAllProduct } from "../../../api-context/commonApiContext";
const ContractMain = () => {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [showModel, setShowModel] = useState(false);
  const [createdDateFilter, setCreatedDateFilter] = useState(null);
  const [textFilter, setTextFilter] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [searchedByDate, setSearchedByDate] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [statusFilter, setStatusFilter] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { role, authTokens, refreshData, loader, setLoader } = useAllProduct();

  const fetchData = async () => {
    try {
      setLoader(true);
      // Fetch users' data
      const userRes = await axios.get(`${config.node_api_endpoint}/v1/user/getAllUserData`, {
        headers: {
          aws_cognito_id_token: authTokens.idToken,
          "Content-Type": "application/json",
        },
      });
      // setLoader(false);
      const users = userRes.data.userData;
      // console.log("user", users);

      // Map users by their email for quick lookup
      const userRoleMap = users.reduce((acc, user) => {
        acc[user.email] = user.auth[0].role;
        return acc;
      }, {});

      // Fetch contracts data
      let obj = {
        escrow_id: companyStore.escrowId,
        escrow_name: companyStore.escrowName,
        admin: {
          email: companyStore.AdminEmail,
        },
      };

      const contractRes = await axios.post(`${config.mock_api_endpoint}/api/contractList`, obj, {
        headers: {
          // aws_cognito_id_token: authTokens.idToken,
          aws_cognito_id_token: authTokens.idToken,
          "Content-Type": "application/json",
        },
      });
      console.log("date---?", contractRes?.data?.contracts);

      // Add roles to the contracts data
      const contractsWithRoles = contractRes?.data?.contracts?.map((contract) => {
        return {
          ...contract,
          role: userRoleMap[contract.created_by] || "Unknown", // Add role or set as "Unknown" if not found
        };
      });

      const sortedData = contractsWithRoles.sort((a, b) => {
        const dateA = dayjs(a.createdAt);
        const dateB = dayjs(b.createdAt);
        return dateB - dateA;
      });
      setLoader(false);
      setData(sortedData);
      setFilteredData(sortedData);
    } catch (error) {
      setLoader(false);
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, [showModel, authTokens, refreshData]);

  useEffect(() => {
    setFilteredData([]); // Reset the filtered data to an empty array

    console.log("helllo");

    setCurrentPage(0);
    fetchData(); // Re-fetch data instead of reloading the page
  }, [refreshData]);

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const applyFilters = () => {
    setTextFilter("");
    let filtered = [...data];

    // Check for status filter, if it's not "All" (empty string), apply the status filter
    if (statusFilter && statusFilter !== "") {
      filtered = filtered.filter((item) => item.status === statusFilter);
    }

    if (createdDateFilter) {
      filtered = filtered.filter((item) => {
        const [datePart] = item.createdAt?.split(" / "); // Extract the date part before '/'
        const itemDate = new Date(datePart).toDateString(); // Convert to a comparable date format
        const filterDate = new Date(createdDateFilter).toDateString();

        return itemDate === filterDate;
      });
    }
    setSearchedByDate(filtered);
    setFilteredData(filtered);
    setCurrentPage(0);
  };

  const handleTextFilterChange = (e) => {
    const value = e.target.value;
    setTextFilter(value);

    let filtered = searchedByDate?.length > 0 ? searchedByDate : data;
    console.log("search", data);

    if (value) {
      filtered = filtered.filter((item) => {
        const formattedDate = moment(item.createdAt).format("DD MMM YYYY / hh:mm A");

        return (
          formattedDate.toLowerCase().includes(value.toLowerCase()) ||
          item?.contract_name?.toLowerCase().includes(value.toLowerCase()) ||
          item?.created_by?.toLowerCase().includes(value.toLowerCase()) ||
          item?.role[0]?.toLowerCase().includes(value.toLowerCase()) ||
          item?.status?.toLowerCase().includes(value.toLowerCase())
        );
      });
    }

    setFilteredData(filtered);
    if (!value) setFilteredData(filtered);
    setCurrentPage(0); // Reset to the first page
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };
  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const currentPageData = filteredData.slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage);

  return (
    <div className={`${styles.pageContainer} ${styles.family}`}>
      <Spin
        size="large"
        tip="Loading..."
        spinning={loader}
        className="d-flex justify-content-center align-items-center w-100 h-100"
        wrapperClassName="spin-nested-loading"
        style={{ maxHeight: "none" }}
      >
        <div className="d-flex justify-content-between">
          <b
            className={`${styles.escrowName} mt-2 mb-2`}
            title={companyStore.escrowName.length > 50 ? companyStore.escrowName : ""}
          >
            {companyStore.escrowName.length > 50
              ? companyStore.escrowName.slice(0, 50) + "..."
              : companyStore.escrowName}
          </b>
          <button
            onClick={() => {
              navigate("/content/contract/newcontract");
            }}
            // className=
            className={`${styles.newCompany} ${role.toString() === "Owner" || role.toString() === "Super Admin" || role.toString() === "Admin" || role.toString() === "Maker" ? "" : styles.disabledDiv}`}
          >
            <img className={styles.plusCircleIcon} alt="" src="/pluscircle.svg" />
            <b className={styles.addNewCompany}>Add Contract</b>
          </button>
        </div>

        <div className={`${styles.searchFiltersParent} p-2 mb-2 mt-2`}>
          <div className={"w-100 d-flex justify-content-center align-items-center"}>
            {/* <div className={styles.leadingIconParent}> */}
            <div className={"w-25"}>
              <div className="d-flex">
                <img className={`${styles.leadingIcon}`} alt="" src="/leadingicon.svg" />
                <TextField label="Search" variant="standard" value={textFilter} onChange={handleTextFilterChange} />
              </div>
            </div>

            <div className={`${styles.mobileMenuParent} w-75 d-flex justify-content-end `}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Created Date"
                  value={createdDateFilter}
                  maxDate={dayjs()}
                  onChange={(newValue) => setCreatedDateFilter(newValue)}
                  renderInput={(params) => <TextField {...params} />}
                  sx={{
                    "& .MuiInputBase-root": {
                      // width: "250px",
                      borderRadius: "12px",
                    },
                  }}
                />
              </LocalizationProvider>

              <FormControl sx={{ width: "250px" }}>
                <InputLabel id="escrow-select-label">Status</InputLabel>
                <Select
                  labelId="escrow-select-label"
                  id="escrow-select"
                  value={statusFilter}
                  label="Status"
                  onChange={(e) => setStatusFilter(e.target.value)}
                  sx={{ borderRadius: "12px" }}
                >
                  <MenuItem value="">All</MenuItem> {/* Option for "All" */}
                  <MenuItem value={"sent"}>Sent</MenuItem>
                  <MenuItem value={"Changed"}>Changed</MenuItem>
                  {/* <MenuItem value={"Completed"}>Completed</MenuItem> */}
                  <MenuItem value={"Accepted"}>Accepted</MenuItem>
                </Select>
              </FormControl>
              <div className={styles.loadsButtons1} onClick={applyFilters}>
                <b className={styles.addNewCompany}>Apply</b>
              </div>
            </div>

            {/* Frame Container ends */}
          </div>
        </div>
        <Paper>
          <TableContainer>
            <Table>
              <TableHead
                className={styles.tableBg}
                sx={{
                  "& .MuiTableCell-root": {
                    padding: "16px",
                  },
                }}
              >
                <TableRow>
                  <TableCell align="center">Created Date</TableCell>
                  <TableCell align="center">Contract Name</TableCell>
                  <TableCell align="center">Created By</TableCell>
                  <TableCell align="center">Role</TableCell>
                  <TableCell align="center">Status</TableCell>
                  <TableCell align="center">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {currentPageData.length > 0 ? (
                  currentPageData.map((detail) => (
                    <TableRow key={detail.contract_id} sx={{ "& .MuiTableCell-root": { padding: "8px" } }}>
                      <TableCell align="center">{dayjs(detail.createdAt).format("DD MMM YYYY / hh:mm a")}</TableCell>
                      <TableCell align="center">{detail.contract_name}</TableCell>
                      <TableCell align="center">{detail.created_by}</TableCell>
                      <TableCell align="center">{detail.role}</TableCell>
                      <TableCell align="center">{detail.status.toUpperCase()}</TableCell>
                      <TableCell align="center">
                        <Button
                          // className={styles.change}
                          style={{ color: "#2348AB", textDecoration: "underline", textTransform: "lowercase" }}
                          onClick={() => {
                            handleScrollToTop();
                            companyStore.setContractID(detail.contract_id);
                            navigate("/content/contract/contractdetail");
                          }}
                        >
                          {detail.status === "sent" || detail.status === "Changed" ? "summary" : "view"}
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={6} align="center">
                      No Records Found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            className={`${styles.tableBg}`}
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredData.length}
            rowsPerPage={rowsPerPage}
            page={currentPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{
              "& .MuiTablePagination-selectLabel": {
                marginTop: "14px",
              },
              "& .MuiTablePagination-displayedRows": {
                marginTop: "14px",
              },
            }}
          />
        </Paper>
      </Spin>
    </div>
  );
};

ContractMain.propTypes = {
  className: PropTypes.string,
};

export default ContractMain;

import { TextField, InputAdornment, Icon, IconButton } from "@mui/material";
import PropTypes from "prop-types";
import styles from "./LoginPage1.module.css";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import config, { node_api_endpoint } from "../../service/config";
import companyStore from "../Content/CompanyStore";
import { jwtDecode } from "jwt-decode";
import { useAllProduct } from "../api-context/commonApiContext";
import {
  CognitoIdentityProviderClient,
  InitiateAuthCommand,
  RespondToAuthChallengeCommand,
} from "@aws-sdk/client-cognito-identity-provider";

const Login = ({ className = "" }) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [errorVerification, setErrorVerification] = useState("");
  const [session, setSession] = useState(null);
  const [otp, setOTP] = useState("");
  const [otpSend, setOtpSend] = useState(false);
  const { authTokens, setAuthTokens } = useAllProduct();
  const client = new CognitoIdentityProviderClient({ region: "ap-south-1" });

  useEffect(() => {
    // console.log(parsedAuthTokens);

    if (authTokens?.idToken && isTokenValid(authTokens?.idToken)) {
      navigate("/content");
    }
  }, [authTokens]);

  const isTokenValid = (token) => {
    try {
      const decoded = jwtDecode(token);
      console.log("Decoded--->", decoded);

      if (decoded.exp * 1000 > Date.now()) {
        return true;
      } else {
        console.log("Token expired. Fetching new tokens...");
        getNewTokens();
        return true;
      }
    } catch (e) {
      console.error("Invalid token:", e.message);
      return false;
    }
  };

  const getNewTokens = async () => {
    console.log("hello World");

    // if (!authTokens.refreshToken) {
    //   console.log("No refresh token available.");
    //   return;
    // }

    try {
      const client = new CognitoIdentityProviderClient({ region: "ap-south-1" }); // Replace 'your-region' with your AWS region
      const params = {
        ClientId: config.cognito_client_id, // Your Cognito App Client ID
        AuthFlow: "REFRESH_TOKEN_AUTH",
        AuthParameters: {
          REFRESH_TOKEN: authTokens.refreshToken,
        },
      };
      const command = new InitiateAuthCommand(params);
      const refreshResponse = await client.send(command);
      console.log("refereshRes--->", refreshResponse);

      if (refreshResponse.AuthenticationResult) {
        console.log("AccessToken", refreshResponse.AuthenticationResult.AccessToken);
        console.log("IdToken", refreshResponse.AuthenticationResult.IdToken);
        console.log("Tokens refreshed successfully!");
        setAuthTokens({
          refreshToken: refreshResponse.AuthenticationResult.RefreshToken,
          idToken: refreshResponse.AuthenticationResult.IdToken,
        });
        localStorage.setItem(
          "authTokens",
          JSON.stringify({
            refreshToken: refreshResponse.AuthenticationResult.RefreshToken,
            idToken: refreshResponse.AuthenticationResult.IdToken,
          }),
        );
      } else {
        console.log("Unable to refresh tokens.");
      }
    } catch (error) {
      navigate("/");
      console.error("Error refreshing tokens:", error);
    }
  };

  const awsCognitoSignIn = async () => {
    try {
      const params = {
        AuthFlow: "CUSTOM_AUTH",
        ClientId: config.cognito_client_id, // App client ID
        AuthParameters: {
          USERNAME: email,
          PASSWORD: "YourHardcodedPassword", // Hardcoded password for step 1
        },
      };

      const command = new InitiateAuthCommand(params);
      const authResponse = await client.send(command);
      console.log("Auth Response:", authResponse);

      if (authResponse.ChallengeName === "CUSTOM_CHALLENGE") {
        // setOtpSent(true);
        console.log("Auth Response:", authResponse);
        setSession(authResponse.Session);
        // setGeneratedUserName(authResponse.ChallengeParameters.USERNAME);
        console.log("OTP has been sent to the user!");
        return authResponse;
      }
    } catch (error) {
      setErrorMessage(error.message);

      console.error("Error during sending OTP:", error);
    }
  };

  const handleLogin = () => {
    axios
      .post(`${config.node_api_endpoint}/v1/service/auth/generate_otp`, { email })
      .then((res) => {
        console.log("loginData------->", res);
        let loginData = JSON.parse(res.config.data);

        companyStore.setemail_id(loginData.email);
        console.log(email);
      })
      .then(() => {
        awsCognitoSignIn().then((res) => {
          console.log("respData-->", res);
          if (res) {
            setOtpSend(true);
          } else {
            alert("User was not added to the cognito pool.");
          }
        });
      })
      .catch((err) => {
        console.log("loginDatas------->", err);
        alert(err?.response?.data?.error ? err?.response?.data?.error : err?.response?.data?.message);
      });
  };

  const HandleOtp = async () => {
    try {
      const params = {
        ChallengeName: "CUSTOM_CHALLENGE",
        ClientId: config.cognito_client_id,
        ChallengeResponses: {
          USERNAME: email,
          ANSWER: otp,
        },
        Session: session,
      };

      const command = new RespondToAuthChallengeCommand(params);
      const verifyResponse = await client.send(command);
      console.log("Verify Response:", verifyResponse);

      if (verifyResponse.AuthenticationResult) {
        // setOtpVerified(true);
        setAuthTokens({
          refreshToken: verifyResponse.AuthenticationResult.RefreshToken,
          idToken: verifyResponse.AuthenticationResult.IdToken,
        });
        localStorage.setItem(
          "authTokens",
          JSON.stringify({
            refreshToken: verifyResponse.AuthenticationResult.RefreshToken,
            idToken: verifyResponse.AuthenticationResult.IdToken,
          }),
        );
        navigate("content");
        console.log("OTP verified successfully!");
      } else {
        // setOtpVerified(false);
        console.log("Invalid OTP. OTP has been resended to your email.");

        setErrorVerification("Invalid OTP. OTP has been resended to your email.");
        awsCognitoSignIn();
      }
    } catch (error) {
      setErrorVerification("Invalid OTP. OTP has been resended to your email.");
      // setErrorVerification(error.message);
      console.error("Error during OTP verification:", error);
      awsCognitoSignIn();
    }
  };

  const resendOtp = () => {
    setOTP("");
    setErrorVerification("");
    awsCognitoSignIn();
  };

  return (
    <div className={[styles.pageContainer, className].join(" ")}>
      <div className={styles.card}>
        <div className={styles.logo}>
          <img className={styles.escrowstackLogos27Icon} alt="" src="/Layer1.svg" />
        </div>
        <div className={styles.welcomeToYourEscrowStackParent}>
          <div className={styles.welcomeToYour}>Welcome to your Escrow Stack</div>
          <div className={styles.pleaseSignInTo}>Please sign-in to continue</div>
        </div>
        {!otpSend ? (
          <div className={styles.inputoutlinedParent}>
            <TextField
              className="input-box"
              autoFocus
              fullWidth
              onChange={(e) => setEmail(e.target.value?.trim())}
              value={email}
              label="Enter your Email"
              sx={{
                mb: 5,
                borderRadius: "12px",
              }}
            />

            <div
              className={styles.loadsButtons}
              onClick={() => {
                handleLogin();
              }}
            >
              <img className={styles.boxArrowInDownIcon} alt="" src="/boxarrowindown3.svg" />
              <b className={styles.loads}>Get OTP</b>
            </div>
          </div>
        ) : (
          <div className={styles.inputoutlinedParent}>
            <TextField
              className="input-box"
              autoFocus
              fullWidth
              inputProps={{ maxLength: 6 }}
              onChange={(e) => setOTP(e.target.value?.trim())}
              value={otp}
              label="Enter OTP"
              sx={{
                // mb: 5,
                borderRadius: "12px",
              }}
            />
            {errorVerification?.length > 0 && <div className={styles.error}>{errorVerification}</div>}
            <div
              className={styles.loadsButtons}
              onClick={() => {
                HandleOtp();
              }}
            >
              <img className={styles.boxArrowInDownIcon} alt="" src="/boxarrowindown3.svg" />
              <b className={styles.loads}>VERIFY OTP</b>
            </div>
            <div
              className={styles.loadsButtons}
              onClick={() => {
                resendOtp();
              }}
            >
              <img className={styles.boxArrowInDownIcon} alt="" src="/boxarrowindown3.svg" />
              <b className={styles.loads}>RESEND OTP</b>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

Login.propTypes = {
  className: PropTypes.string,
};

export default Login;

import { useEffect, useState } from "react";
import InspectPdf from "../InspectPdf/Inspectpdf";
import styles from "./DocumentPreview.module.css";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import config from "../../../../service/config";
import companyStore from "../../CompanyStore";
import { useAllProduct } from "../../../api-context/commonApiContext";
import { Spin } from "antd";

const DocumentPreview = () => {
  const navigate = useNavigate();
  const [comp, setComp] = useState("");
  const [info, setInfo] = useState({});
  const [data, setData] = useState([]);
  const [id, setId] = useState("");

  const [doc_id, setDoc_id] = useState("");
  const [clicked, setclicked] = useState(null);
  const [pdfUrl, setPdfUrl] = useState(null);
  const { role, authTokens, loader } = useAllProduct();
  useEffect(() => {
    if (Object.keys(authTokens)?.length > 0) {
      setId(companyStore.companyID);
      setDoc_id(companyStore.doc_id);
      const iddd = companyStore.doc_id;
      let obj = { docid: companyStore.doc_id };
      axios
        .post(
          `${config.node_api_endpoint}/v1/esign/getdocument`,
          { doc_id: companyStore.doc_id },
          {
            headers: {
              aws_cognito_id_token: authTokens.idToken,
              // "Content-Type": "application/json",
            },
          },
        )
        .then((res) => {
          setData(res.data.signing_parties);
          setInfo(res.data);
        })
        .catch((err) => {
          console.log("%o", err);
        });
    }
  }, [authTokens]);

  const handleDocumentDownload = (newlink) => {
    const link = document.createElement("a");
    link.href = newlink;
    link.download = newlink.split("/").pop(); // This will extract the filename from the URL
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link); // Clean up by removing the link
  };
  const handlenavi = () => {
    navigate(`/content/esign/esign/main`);
  };

  return (
    <Spin
      size="large"
      tip="Loading..."
      spinning={loader}
      className="d-flex justify-content-center align-items-center w-100 h-100"
      wrapperClassName="spin-nested-loading"
      style={{ maxHeight: "none" }}
    >
      <div className={`${styles.maindiv} w-100`}>
        <div className={` d-flex mb-2`}>
          <img
            className={`${styles.arrowNarrowLeftIcon} ${styles.cursorIcon} pe-2`}
            onClick={() => {
              handlenavi();
            }}
            alt=""
            src="/arrownarrowleft.svg"
          />
          <div
            className={`${styles.back} ${styles.cursorIcon}`}
            onClick={() => {
              handlenavi();
            }}
          >
            Back
          </div>
        </div>
        <div className={styles.containAll}>
          <div className={`${styles.scrollableContainer} w-75`}>
            <InspectPdf pdfUrl={pdfUrl} setPdfUrl={setPdfUrl} />
          </div>
          <div className="p-2 w-25">
            <div className={`${styles.detailsContentWrapper} w-100`}>
              <div className={styles.companyAddress}>
                <h1
                  className={styles.escrowStackPrivate}
                  title={companyStore.escrowName.length > 50 ? companyStore.escrowName : ""}
                >
                  {companyStore.escrowName.length > 50
                    ? companyStore.escrowName.slice(0, 50) + "..."
                    : companyStore.escrowName}
                </h1>
              </div>
              <div className={styles.detailsSection}>
                <div className={styles.detailRow}>
                  <div className={styles.label}>Requested by</div>
                  <div className={styles.colon}>:</div>
                  <div className={styles.value} title={info?.created_by?.length > 15 ? info?.created_by : ""}>
                    {info?.created_by
                      ? info?.created_by?.length > 15
                        ? info?.created_by?.slice(0, 15) + "..."
                        : info?.created_by
                      : "N/A"}
                  </div>
                </div>
                <div className={styles.detailRow}>
                  <div className={styles.label}>Requested</div>
                  <div className={styles.colon}>:</div>
                  <div className={styles.value}>
                    {info?.created_at ? info?.created_at?.split("T")[0] || "N/A" : "N/A"}
                  </div>
                </div>
              </div>
              <div className={`${styles.signDetailsWrapper} mb-4`}>
                <div className={styles.signDetails}>Sign Details</div>
              </div>
              {/* Scrollable Signed Details Section */}
              <div className={styles.signedDetailsContainer}>
                {data.map((detail, index) => (
                  <div key={index} className={styles.detailRow}>
                    <div className={styles.label}>{detail.name}</div>
                    <div className={styles.colon}>:</div>
                    <div
                      className={
                        detail?.status?.toUpperCase() === "SIGNED"
                          ? styles.coloredvalue
                          : detail?.status?.toUpperCase() === "FAILED"
                            ? styles.coloredvaluefailed
                            : detail?.status?.toUpperCase() === "PENDING"
                              ? styles.coloredvaluepending
                              : styles.value
                      }
                    >
                      {detail?.status ? detail?.status?.toUpperCase() || "" : "N/A"}
                    </div>
                  </div>
                ))}
              </div>
              <div className="mt-4">
                <button
                  src={info.pdf_url}
                  onClick={() => {
                    handleDocumentDownload(pdfUrl || "");
                  }}
                  className={`${styles.downloadbtn} ${role.toString() === "Owner" || role.toString() === "Super Admin" || role.toString() === "Admin" ? "" : styles.disabledDiv}`}
                >
                  <b>Download PDF</b>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default DocumentPreview;

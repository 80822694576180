import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { Spin } from "antd";
import axios from "axios";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import config from "../../../../service/config";
import { useAllProduct } from "../../../api-context/commonApiContext";
import companyStore from "../../CompanyStore";
import styles from "./NewEsign.module.css";
const CryptoJS = require("crypto-js");

const NewEsign = () => {
  const { loader, setLoader, authTokens } = useAllProduct();
  const [docUrl, setDocUrl] = useState("");
  const [partiesState, setPartiesState] = useState([{ name: "", email: "" }]);
  const navigate = useNavigate();
  const [pdf, setPdf] = useState("application/pdf");
  const [pdfUrl, setPdfUrl] = useState("application/pdf");
  const [parties, setParties] = useState([]);
  const formdata = new FormData();
  const [agreementName, setAgreementName] = useState("");
  const [clicked, setClicked] = useState(false);
  const [errors, setErrors] = useState({});
  const [file, setFile] = useState([]);
  const [from, setFrom] = useState("");
  const [pdfbase64, setpdfbase64] = useState("");

  const onFrameContainerClick = useCallback(() => {
    navigate("/content/esign/esign/main");
  }, [navigate]);

  useEffect(() => {
    const fetchData = async () => {
      setLoader(true);
      const payload = {
        escrow_id: companyStore.escrowId,
      };

      try {
        const response = await axios.post(`${config.node_api_endpoint}/v2/company/list`, payload, {
          headers: {
            aws_cognito_id_token: authTokens.idToken,
            // "Content-Type": "application/json",
          },
        });
        const data = response.data;

        const allDirectorIds = data.reduce((ids, item) => {
          if (item.director_ids && Array.isArray(item.director_ids)) {
            return [...ids, ...item.director_ids];
          }
          return ids;
        }, []);

        let kycResponse = [];
        if (allDirectorIds.length > 0) {
          setLoader(true);
          kycResponse = await axios.post(
            `${config.node_api_endpoint}/v2/company/kycparties`,
            {
              director_ids: allDirectorIds,
            },
            {
              headers: {
                aws_cognito_id_token: authTokens.idToken,
                "Content-Type": "application/json",
              },
            },
          );
        }

        const filteredData = kycResponse?.data?.filter(
          (party) => party.status === "approved" || party.status === "SUBMITTED",
        );

        const uniqueEmailsMap = new Map();

        filteredData.forEach((detail) => {
          const lowerCaseEmail = detail.director_email.toLowerCase();
          if (!uniqueEmailsMap.has(lowerCaseEmail)) {
            uniqueEmailsMap.set(lowerCaseEmail, detail); // Store the original detail
          }
        });

        const uniqueParties = Array.from(uniqueEmailsMap.values()).map((detail) => ({
          name: detail.director_name,
          email: detail.director_email,
        }));
        setLoader(false);
        setParties(uniqueParties);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoader(false);
      }
    };
    if (Object.keys(authTokens).length > 0) fetchData();
  }, [companyStore, authTokens]);

  function decryptBuffer(encryptedBuffer) {
    const key = CryptoJS.enc.Utf8.parse("1234567890123456"); // Same 128-bit key used in encryption
    const iv = CryptoJS.enc.Utf8.parse("1234567890123456"); // Same IV used in encryption

    // Decrypt the encrypted buffer (base64 string)
    const decrypted = CryptoJS.AES.decrypt(encryptedBuffer, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC, // Cipher Block Chaining mode
      padding: CryptoJS.pad.Pkcs7, // Padding scheme
    });

    // Convert decrypted data to a UTF-8 string
    const decryptedData = decrypted.toString(CryptoJS.enc.Utf8);

    return decryptedData;
  }

  useEffect(() => {
    if (companyStore.Contract_id !== "") {
      let file_Path = "";
      let obj = {
        escrow_id: companyStore.escrowId,
        contract_id: companyStore.Contract_id,
      };
      if (authTokens && Object?.keys(authTokens)?.length > 0) {
        axios
          .post(`${config.mock_api_endpoint}/api/getContractData`, obj, {
            headers: {
              "x-encrypted-token": "a25b2963bab38ab7577575df9e80a27a",
              "Content-Type": "application/json",
            },
          })
          .then(async (res) => {
            setAgreementName(res.data.contract_name);
            companyStore.setContractName(res.data.contract_name);
            setDocUrl(res.data.filePath);
            setFrom(res.data.from);
            try {
              var res = await axios.post(
                `${config.mock_api_endpoint}/api/getEsignDocument`,
                {
                  file_Path: res.data.filePath,
                  escrow_id: companyStore.escrowId,
                  contract_id: companyStore.Contract_id,
                },
                {
                  headers: {
                    "x-encrypted-token": "a25b2963bab38ab7577575df9e80a27a",
                    // "Content-Type": "application/json",
                  },
                },
              );
              const decryptedData = decryptBuffer(res.data);
              setLoader(false);
              setpdfbase64(decryptedData);
            } catch (error) {
              console.log(error);
            }
          })
          .catch((err) => {
            console.log("%o", err);
          });
      }
    }
  }, [authTokens]);

  useEffect(() => {
    if (pdfbase64) {
      // Convert Base64 to binary data
      const byteCharacters = atob(pdfbase64);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);

      // Create a Blob and Object URL
      const blob = new Blob([byteArray], { type: "application/pdf" });
      const blobUrl = URL.createObjectURL(blob);
      setPdfUrl(blobUrl);

      // Clean up URL after component unmounts
      return () => URL.revokeObjectURL(blobUrl);
    }
  }, [pdfbase64]);

  const handleAddParty = () => {
    setPartiesState((prevParties) => [...prevParties, { name: "", email: "" }]);
  };

  const handleRemoveParty = (index) => {
    const updatedParties = partiesState.filter((_, i) => i !== index);
    setPartiesState(updatedParties);
  };

  console.log("base64", pdfbase64);

  const handleFileChange = (e) => {
    let selectedFile = e.target.files[0];
    setPdf(selectedFile);
    if (selectedFile) {
      const maxSize = 10 * 1024 * 1024;
      if (selectedFile.size > maxSize) {
        alert("File size exceeds 10MB. Please upload a smaller file.");
        return; // Exit the function early
      }

      // Check file type (only allow Word documents)
      const allowedTypes = ["application/pdf"];

      if (!allowedTypes.includes(selectedFile.type)) {
        alert("Invalid file type. Please upload a PDF document.");
        return; // Exit the function early
      }

      const fileReader = new FileReader();
      fileReader.onload = (e) => {
        var contract = e.target.result;
        setFile({
          name: selectedFile.name,
          size: (selectedFile.size / (1024 * 1024)).toFixed(2) + " MB", // Convert bytes to MB
          preview: "/pdf-logo.svg", // Fixed preview icon for Word files
        });
      };

      fileReader.readAsDataURL(selectedFile);
    }
  };
  function handleRemoveFile() {
    setFile({});
  }

  const validateForm = () => {
    const newErrors = {};

    if (!agreementName) {
      newErrors.agreementName = "Agreement name is required.";
    } else if (agreementName.length < 3) {
      newErrors.agreementName = "Agreement name must be at least 3 characters long.";
    } else if (agreementName.length > 50) {
      newErrors.agreementName = "Agreement name cannot exceed 50 characters.";
    }

    // Validate parties
    if (partiesState.length === 0) {
      newErrors.parties = "At least one party is required.";
    } else {
      partiesState.forEach((party, index) => {
        if (!party.name.trim()) {
          newErrors[`partyName${index}`] = `Name is required for party ${index + 1}.`;
        }
        if (!party.email.trim()) {
          newErrors[`partyEmail${index}`] = `Email is required for party ${index + 1}.`;
        } else if (!validateEmail(party.email)) {
          newErrors[`partyEmail${index}`] = `Invalid email format for party ${index + 1}.`;
        }
      });
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleContinue = () => {
    setLoader(true);
    const filteredParties = partiesState.filter((party) => party.name.trim() !== "" && party.email.trim() !== "");
    const emailCounts = filteredParties.reduce((counts, party) => {
      const email = party.email.toLowerCase(); // Convert email to lowercase
      counts[email] = (counts[email] || 0) + 1;
      return counts;
    }, {});
    const duplicateEmails = Object.keys(emailCounts).filter((email) => emailCounts[email] > 1);

    if (duplicateEmails.length > 0) {
      alert(`Duplicate emails found: ${duplicateEmails.join(", ")}. Please ensure each email is unique.`);
      return;
    }
    if (docUrl?.length > 0) {
      if (!validateForm()) {
        setLoader(false);
        alert("Please fix the highlighted errors.");
        return;
      }

      setClicked(true);
      formdata.append("agreement_name", agreementName);
      formdata.append("escrow_id", companyStore.escrowId);
      formdata.append("from", from);
      formdata.append("contract_id", companyStore.Contract_id);
      formdata.append("signing_parties", JSON.stringify(filteredParties));
      formdata.append("pdf_url", docUrl);
      axios
        .post(`${config.node_api_endpoint}/v2/esign/upload`, formdata, {
          headers: {
            aws_cognito_id_token: authTokens.idToken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          console.log("getDOcID", res);

          setLoader(false);
          companyStore.setDoc_id(res.data.doc_id);
          navigate(`/content/esign/signaggr`);
        })
        .catch((err) => {
          setLoader(false);
          console.log(err);
          alert(err.response.data.error);
        });
    } else {
      if (!pdf.name) {
        setLoader(false);
        alert("Please upload the document.");
        return;
      }
      if (!validateForm()) {
        setLoader(false);
        alert("Please fix the highlighted errors.");
        return;
      }

      setLoader(true);
      setClicked(true);
      formdata.append("agreement_name", agreementName);
      formdata.append("escrow_id", companyStore.escrowId);
      formdata.append("signing_parties", JSON.stringify(filteredParties));
      formdata.append("pdf_file", pdf, "[PROXY]");

      axios
        .post(`${config.node_api_endpoint}/v2/esign/manualupload`, formdata)
        .then((res) => {
          setLoader(false);
          companyStore.setDoc_id(res.data._id);
          navigate(`/content/esign/signaggr`);
        })
        .catch((err) => {
          setLoader(false);
          console.log(err);
          alert(err.response.data.error);
        });
    }
  };

  const validateEmail = (email) => {
    const regex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
    return regex.test(email);
  };

  const handleNameChange = (e, index) => {
    const selectedEmail = e.target.value; // Use email instead of name

    const selectedParty = parties.find((p) => p.email === selectedEmail);

    const newPartiesState = [...partiesState];
    newPartiesState[index].name = selectedParty ? selectedParty.name : ""; // Update name
    newPartiesState[index].email = selectedParty ? selectedParty.email : ""; // Update email
    setPartiesState(newPartiesState);
  };
  console.log("filePath-->", docUrl);

  return (
    <Spin
      size="large"
      tip="Loading..."
      spinning={loader}
      className="d-flex justify-content-center align-items-center w-100 h-100"
      wrapperClassName="spin-center-wrapper"
      style={{ maxHeight: "none", zIndex: "1", opacity: "10" }}
    >
      <div className={`${styles.frameParent} ${styles.family} w-100 `}>
        <div className={styles.frameWrapper} onClick={onFrameContainerClick}>
          <div className={styles.arrowNarrowLeftParent}>
            <img className={styles.arrowNarrowLeftIcon} alt="" src="/arrownarrowleft.svg" />
            <div className={styles.back}>Back</div>
          </div>
        </div>
        <div className={"mb-3 mt-2"}>
          <div
            className={`${styles.escrowName}`}
            title={companyStore.escrowName.length > 50 ? companyStore.escrowName : ""}
          >
            {companyStore.escrowName.length > 50
              ? companyStore.escrowName.slice(0, 50) + "..."
              : companyStore.escrowName}
          </div>
        </div>
        <div className={`${styles.frameGroup}`}>
          {parties?.length > 0 ? (
            <>
              {" "}
              {docUrl?.length > 0 ? (
                <div className={`${styles.frameContainer} d-flex justify-content-center`}>
                  <div className={styles.uploadTheFinalDraftOfAgreParent}>
                    {/* <div className={styles.uploadTheFinal}>+ Upload the final draft of agreement</div> */}
                    <div className={styles.uploadTheFinal}>Add parties (by default show 2 Inputs)</div>
                  </div>

                  <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                    <div className="w-100 h-50">
                      <Viewer fileUrl={pdfUrl} />
                    </div>
                  </Worker>
                </div>
              ) : (
                <div className={`${styles.frameContainer} d-flex justify-content-center`}>
                  <div className={styles.uploadTheFinalDraftOfAgreParent}>
                    <div className={styles.uploadTheFinal}>+ Upload the final draft of agreement</div>
                    <div className={styles.uploadTheFinal}>Add parties (by default show 2 Inputs)</div>
                  </div>

                  {Object.keys(file).length > 0 && (
                    <Paper
                      variant="outlined"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        padding: 1,
                        marginBottom: 1,
                        borderRadius: 2,
                        border: "1px solid #e0e0e0",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          marginRight: 2,
                        }}
                      >
                        <img
                          src="/pdf-logo.svg"
                          alt={file.name}
                          style={{
                            width: 40,
                            height: 40,
                            objectFit: "cover",
                            borderRadius: 4,
                          }}
                        />
                      </Box>

                      <Box
                        sx={{
                          flex: 1,
                          display: "flex",
                          flexDirection: "column",
                          marginRight: 1,
                        }}
                      >
                        <Typography variant="body2" sx={{ fontWeight: 500 }}>
                          {file.name}
                        </Typography>
                        <Typography variant="caption" color="textSecondary" className="w-100">
                          {file.size}
                        </Typography>
                      </Box>
                      <IconButton onClick={handleRemoveFile} size="small">
                        <Close fontSize="small" />
                      </IconButton>
                    </Paper>
                  )}
                  <Button
                    variant="contained"
                    component="label"
                    startIcon={
                      <img
                        src="/fileUpload.svg"
                        alt="Upload Icon"
                        style={{
                          width: 24,
                          height: 24,
                        }}
                      />
                    }
                    sx={{
                      // marginTop: 2,
                      padding: 1.5,
                      border: "2px dotted #AAAAAA",
                      borderRadius: 2,
                      fontSize: "14px",
                      width: "100%",
                      backgroundColor: "#ffffff",
                      color: "#777777",
                      boxShadow: "none",
                      textTransform: "none",
                      "&:hover": {
                        backgroundColor: "#f5f5f5",
                      },
                    }}
                  >
                    <span
                      style={{
                        textDecoration: "underline",
                        color: "#2348AB",
                        fontWeight: 700,
                      }}
                    >
                      Click to upload
                    </span>
                    <input type="file" hidden accept=".pdf" onChange={handleFileChange} />
                  </Button>

                  <Typography variant="caption" display="block" sx={{ textAlign: "center", color: "#757575" }}>
                    (File type DOCX / Max Total File Size 10MB)
                  </Typography>
                </div>
              )}
              <div className={`${styles.frameDiv} d-flex justify-content-center gap-2`}>
                <div className={styles.parties}>Note:</div>
                <ol>
                  <li className={`text-warning ${styles.warningText}`}>
                    For e-signing, at least one signatory is required.
                  </li>
                  <li className={`text-warning ${styles.warningText}`}>
                    For e-signing with e-stamping, a minimum of two signatories is required.
                  </li>
                </ol>
                <div className={styles.partiesParent}>
                  <div className={styles.parties}>Parties</div>
                </div>
                {/* <ol> */}
                <div className="d-flex justify-content-start align-items-center w-100">
                  <div className="pe-4 w-100">
                    <TextField
                      className="w-100"
                      color="primary"
                      label="Name of Agreement"
                      variant="outlined"
                      value={agreementName}
                      onChange={(e) => setAgreementName(e.target.value)}
                      sx={{
                        "& .MuiInputBase-root": { height: "56px" },
                      }}
                      error={!!errors.agreementName}
                      helperText={errors.agreementName || ""}
                    />
                  </div>
                </div>
                <div className={`${styles.partiesOverflow} w-100`}>
                  {partiesState?.map((party, index) => {
                    const selectedNames = partiesState.map((p) => p.name).filter((_, i) => i !== index);

                    return (
                      <div key={index} className={styles.frameWrapper1}>
                        <div className={styles.partieslistitem}>{index + 1}. Parties</div>
                        <div className={styles.mobileMenuParent}>
                          <div className={styles.mobileMenu2}>
                            <FormControl sx={{ m: 1, width: 300 }} error={!!errors[`partyName${index}`]}>
                              <InputLabel id="demo-multiple-name-label">Name</InputLabel>
                              <Select
                                labelId="demo-multiple-name-label"
                                id="demo-multiple-name"
                                value={party.email || ""} // Use email as the value
                                onChange={(e) => handleNameChange(e, index)} // Pass email on change
                                sx={{
                                  "& .MuiInputBase-root": { height: "56px" },
                                  width: "309px",
                                }}
                                input={<OutlinedInput label="Name" />}
                              >
                                {parties.map((p, idx) => (
                                  <MenuItem key={idx} value={p.email}>
                                    {" "}
                                    {/* Use email as value */}
                                    {p.name}
                                  </MenuItem>
                                ))}
                              </Select>
                              {errors[`partyName${index}`] && (
                                <FormHelperText error>{errors[`partyName${index}`]}</FormHelperText>
                              )}
                            </FormControl>
                            <img className={styles.trailingIcon2} alt="" src="/trailingicon-2.svg" />
                          </div>
                          <FormControl sx={{ m: 1, width: 300 }}>
                            <TextField
                              className={styles.mobileMenu3}
                              color="primary"
                              placeholder="Email ID"
                              variant="outlined"
                              type="email"
                              value={party.email || ""}
                              disabled={true}
                              sx={{
                                "& .MuiInputBase-root": { height: "56px" },
                                width: "309px",
                                "& .MuiInputBase-input.Mui-disabled": {
                                  opacity: 1,
                                  "-webkit-text-fill-color": "rgba(0, 0, 0, 0.38)",
                                  cursor: "not-allowed",
                                },
                              }}
                            />
                          </FormControl>
                        </div>
                        {partiesState.length > 1 && (
                          <Button className={styles.inputParent} onClick={() => handleRemoveParty(index)}>
                            <div className={styles.input1}>Remove</div>
                            <img className={styles.arrowNarrowLeftIcon} alt="" src="/minuscircle.svg" />
                          </Button>
                        )}
                      </div>
                    );
                  })}
                </div>
                {/* </ol> */}

                <div className={styles.containbuttons}>
                  {partiesState?.length < parties?.length && (
                    <Button className={`${styles.addDirector} w-50 mx-2`} onClick={handleAddParty}>
                      <img className={styles.boxArrowInDownIcon} alt="" src="/boxarrowindown1.svg" />
                      <img className={styles.arrowNarrowLeftIcon} alt="" src="/pluscircle1.svg" />
                      <b className={styles.addDirector1}>Add Parties</b>
                    </Button>
                  )}

                  {
                    <div
                      className={styles.generateRequest}
                      onClick={() => {
                        handleContinue();
                      }}
                    >
                      <b className={styles.generateRequest1}>Continue</b>
                    </div>
                  }
                </div>
              </div>{" "}
            </>
          ) : (
            <div className="d-flex justify-content-center align-items-center  h-100">
              <div
                className="p-2"
                style={{
                  border: "2px dotted #DC3545",
                  padding: "20px",
                  borderRadius: "8px",
                  color: "#DC3545",
                  fontWeight: 500,
                }}
              >
                {"Please add atleast one Siganatory to proceed with E-Stamping"}
              </div>
            </div>
          )}
        </div>
      </div>
    </Spin>
  );
};

NewEsign.propTypes = {
  className: PropTypes.string,
};

export default NewEsign;

import PropTypes from "prop-types";
import styles from "./PageContainerComp.module.css";
import { Fragment, useEffect, useRef, useState } from "react";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import companyStore from "../../CompanyStore";
import AddNewKYC from "../AddKYC/AddNewKYC";
import {
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Paper,
  TablePagination,
} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import config from "../../../../service/config";
import { DatePicker } from "@mui/x-date-pickers";
import { Button, Pagination, Spin } from "antd";
import dayjs from "dayjs";
import { useAllProduct } from "../../../api-context/commonApiContext";

const PageContainerComp = () => {
  const { role, authTokens, setLoader, loader } = useAllProduct();
  const [showModel, setShowModel] = useState(false);
  const [data, setData] = useState([]);
  const [compName, setCompName] = useState("");
  const [check, setCheck] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [searchedByDate, setSearchedByDate] = useState([]);
  const [statusFilter, setStatusFilter] = useState("");
  const [textFilter, setTextFilter] = useState("");
  const [createdDateFilter, setCreatedDateFilter] = useState(null);
  let [id, setId] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const navigate = useNavigate();
  const modalRef = useRef(null);

  useEffect(() => {
    setId(companyStore.companyID);
    setCompName(companyStore.companyName);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoader(true);
      if (authTokens && Object?.keys(authTokens)?.length > 0) {
        try {
          // Fetch KYC list data
          const kycRes = await axios.get(
            `${config.node_api_endpoint}/v1/company/kyc_list?company_id=${companyStore.companyID}`,
            {
              headers: {
                aws_cognito_id_token: authTokens.idToken,
                "Content-Type": "application/json",
              },
            },
          );
          const kycData = kycRes.data;

          // Fetch user data
          const userRes = await axios.get(`${config.node_api_endpoint}/v1/user/getAllUserData`, {
            headers: {
              aws_cognito_id_token: authTokens.idToken,
              "Content-Type": "application/json",
            },
          });
          const users = userRes.data.userData;
          setLoader(false);
          // Map roles to KYC data
          const dataWithRoles = kycData.map((kycItem) => {
            console.log("testtt-->", kycItem);

            const user = users.find((u) => u.email === kycItem.created_by);
            return {
              ...kycItem,
              role: user ? user.auth[0].role : "Unknown", // Add role or default to 'Unknown'
            };
          });

          // Sort KYC data by date and update state
          const sortedData = dataWithRoles.sort((a, b) => {
            const dateA = dayjs(a.created_at, "DD MMM YYYY / hh:mm a");
            const dateB = dayjs(b.created_at, "DD MMM YYYY / hh:mm a");
            return dateB - dateA;
          });

          setData(sortedData); // Original data without roles
          setFilteredData(sortedData); // Data enriched with roles and sorted

          // Check specific KYC type and status
          kycData.forEach((detail) => {
            if (
              detail.kyc_type === "Company KYC" &&
              (detail.status === "pending_approval" || detail.status === "approved")
            ) {
              setCheck(true);
            }
          });
        } catch (error) {
          setLoader(false);
          console.error("Error fetching data:", error);
        }
      }
    };

    fetchData();

    // Set company-related state
    setId(companyStore.companyID);
    setCompName(companyStore.companyName);
  }, [authTokens]);

  useEffect(() => {
    if (showModel) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showModel]);

  const handleClick = (para) => {
    if (para[0] === "Company KYC") {
      companyStore.setKYBId(para[1]);
      navigate(`/content/company/companykyc/detail`);
    } else if (para[0] === "Signatory KYC") {
      console.log("testID-->", para[1]);
      companyStore.setDirect_KYC_ID(para[1]);
      navigate(`/content/company/directkyc/detail`);
    }
  };

  const resetFilters = () => {
    setFilteredData(data);
  };

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setShowModel(false);
    }
  };

  const applyFilters = () => {
    setTextFilter("");
    let filtered = data;

    if (statusFilter?.trim()) {
      filtered = filtered.filter((item) => item.status?.toLowerCase().trim() === statusFilter.toLowerCase().trim());
    }

    if (createdDateFilter) {
      filtered = filtered.filter((item) => {
        const [datePart] = item.created_at.split(" / "); // Extract the date part before '/'
        const itemDate = new Date(datePart).toDateString(); // Convert to a comparable date format
        const filterDate = new Date(createdDateFilter).toDateString();

        console.log("dateFilter--->", itemDate, filterDate);

        return itemDate === filterDate;
      });
    }

    if (textFilter) {
      filtered = filtered.filter((item) => item.status === textFilter);
    }
    setSearchedByDate(filtered);
    setFilteredData(filtered);
    setCurrentPage(0);
  };

  const handleTextFilterChange = (e) => {
    const value = e.target.value;
    setTextFilter(value);
    const lowerText = value.toLowerCase();
    let filtered = searchedByDate?.length > 0 ? searchedByDate : data;

    if (lowerText) {
      filtered = filtered.filter(
        (item) =>
          item?.created_by?.toLowerCase()?.includes(lowerText) ||
          item?.role[0]?.toLowerCase()?.includes(lowerText) ||
          item?.status?.toLowerCase()?.includes(lowerText) ||
          item?.kyc_type?.toLowerCase()?.includes(lowerText) ||
          item?.name?.toLowerCase()?.includes(lowerText),
      );
    }

    setFilteredData(filtered);
    if (!value) setFilteredData(filtered);
    setCurrentPage(0);
  };

  const handlenavi = () => {
    navigate("/content/company/kyclist/Company");
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };
  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const currentPageData = filteredData.slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage);

  return (
    <Fragment>
      <div className={`${styles.pageContainer} ${styles.family} `}>
        <Spin
          size="large"
          tip="Loading..."
          spinning={loader}
          className="d-flex justify-content-center align-items-center w-100 h-100"
          wrapperClassName="spin-nested-loading"
          style={{ maxHeight: "none" }}
        >
          <div className={` d-flex`}>
            <img
              className={`${styles.arrowNarrowLeftIcon} ${styles.cursorIcon} pe-2`}
              onClick={() => {
                handlenavi();
              }}
              alt=""
              src="/arrownarrowleft.svg"
            />
            <div
              className={`${styles.back} ${styles.cursorIcon}`}
              onClick={() => {
                handlenavi();
              }}
            >
              Back
            </div>
          </div>
          <div className={`${styles.frameParent} mt-1`}>
            <b
              className={`d-flex justify-content-start ${styles.escrowstackPrivateLimited}`}
              title={companyStore.escrowName.length > 50 ? companyStore.escrowName : ""}
            >
              {companyStore.escrowName.length > 50
                ? companyStore.escrowName.slice(0, 50) + "..."
                : companyStore.escrowName}
            </b>
            <div className={`${styles.escrowstackPrivateLimitedParent} mb-2`}>
              <b className={styles.companyNameTitle}>{compName}</b>
              <button
                className={`${styles.loadsButtons} ${role?.toString() === "Owner" || role?.toString() === "Super Admin" || role?.toString() === "Admin" || role?.toString() === "Maker" ? "" : styles.disabledDiv}`}
                onClick={() => setShowModel(true)}
              >
                <img className={styles.plusCircleIcon} alt="" src="/pluscircle2.svg" />
                <b className={styles.newKycRequest}>New KYC Request</b>
              </button>
            </div>

            <div className={`${styles.frameWrapper} mb-2 p-2`}>
              <div className={styles.frameGroup}>
                {/* Filter inputs */}
                <div className={styles.frameContainer}>
                  <div className={styles.frameDiv}>
                    <img className={styles.leadingIcon} alt="" src="/leadingicon3.svg" />
                    <TextField label="Search" variant="standard" value={textFilter} onChange={handleTextFilterChange} />
                  </div>
                </div>

                <div className={styles.mobileMenuParent}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Created Date"
                      value={createdDateFilter}
                      onChange={(newValue) => setCreatedDateFilter(newValue)}
                      renderInput={(params) => <TextField {...params} />}
                      maxDate={dayjs()}
                      sx={{
                        "& .MuiInputBase-root": {
                          width: "250px",
                          borderRadius: "12px",
                        },
                      }}
                    />
                  </LocalizationProvider>

                  <FormControl sx={{ width: "250px" }}>
                    <InputLabel id="escrow-select-label">Status</InputLabel>
                    <Select
                      labelId="escrow-select-label"
                      id="escrow-select"
                      value={statusFilter}
                      label="Status"
                      onChange={(e) => setStatusFilter(e.target.value)}
                      sx={{ borderRadius: "12px" }}
                    >
                      <MenuItem value={"requested"}>Requested</MenuItem>
                      <MenuItem value={"pending_approval"}>Pending Approval</MenuItem>
                      <MenuItem value={"approved"}>Approved</MenuItem>
                      <MenuItem value={"rejected"}>Rejected</MenuItem>
                      <MenuItem value={"SUBMITTED"}>Submitted</MenuItem>
                    </Select>
                  </FormControl>

                  {/* Button to apply filter */}
                  <div className={`${styles.loadsButtons1}`} onClick={applyFilters}>
                    <b className={styles.escrowstackPrivateLimited}>Apply</b>
                  </div>
                </div>
              </div>
            </div>
            <div className={`${`${styles.tableBg} d-flex justify-content-between px-2`}`}>
              <h2 className={styles.title}>KYC List</h2>
            </div>
            <Paper>
              <TableContainer>
                <Table>
                  <TableHead className={styles.tableBg}>
                    <TableRow>
                      <TableCell align="center">Created Date</TableCell>
                      <TableCell align="center">Name of Company / Authorized Signatory</TableCell>
                      <TableCell align="center">KYC Type</TableCell>
                      <TableCell align="center">Created By</TableCell>
                      <TableCell align="center">Role</TableCell>
                      <TableCell align="center">Status</TableCell>
                      <TableCell align="center">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {currentPageData.length > 0 ? (
                      currentPageData.map((detail) => {
                        console.log("id data", detail);

                        return (
                          <TableRow key={detail._id}>
                            <TableCell align="center">{detail.created_at}</TableCell>
                            <TableCell align="center" title={detail.name.length > 50 ? detail.name : ""}>
                              {detail.name.length > 50 ? detail.name.slice(0, 50) + "..." : detail.name}
                            </TableCell>
                            <TableCell align="center">
                              {detail.kyc_type === "Director KYC" ? "Signatory KYC" : detail.kyc_type}
                            </TableCell>
                            <TableCell align="center">{detail.created_by}</TableCell>
                            <TableCell align="center">{detail.role}</TableCell>
                            <TableCell align="center">{detail?.status?.toUpperCase()}</TableCell>
                            <TableCell align="center">
                              {detail.kyc_type === "Company KYC" && (
                                <Button
                                  className={styles.btn}
                                  onClick={() => handleClick([detail.kyc_type, detail.kyb_id])}
                                >
                                  <img className={styles.eyeIcon} alt="" src="/eye.svg" />
                                </Button>
                              )}
                              {detail.kyc_type === "Signatory KYC" && (
                                <Button
                                  className={styles.btn}
                                  onClick={() => handleClick([detail.kyc_type, detail.kyc_id])}
                                >
                                  <img className={styles.eyeIcon} alt="" src="/eye.svg" />
                                </Button>
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      })
                    ) : (
                      <TableRow>
                        <TableCell colSpan={7} align="center">
                          No Records Found
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                className={`${styles.tableBg}`}
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={filteredData.length}
                rowsPerPage={rowsPerPage}
                page={currentPage}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                sx={{
                  "& .MuiTablePagination-selectLabel": {
                    marginTop: "14px",
                  },
                  "& .MuiTablePagination-displayedRows": {
                    marginTop: "14px",
                  },
                }}
              />
            </Paper>
          </div>
        </Spin>
      </div>
      <AddNewKYC
        isVisible={showModel}
        onClose={() => {
          setShowModel(false);
        }}
        check={check}
        modalRef={modalRef}
      />
    </Fragment>
  );
};

PageContainerComp.propTypes = {
  className: PropTypes.string,
};

export default PageContainerComp;

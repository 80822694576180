import { useCallback, useEffect, useState } from "react";
import { Box, TextField, Typography, Button, Paper, LinearProgress, IconButton } from "@mui/material";
import { CloudUpload, Close, Check } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import PropTypes from "prop-types";
import styles from "./offlineSignatoryKyc.module.css";
import config from "../../../../../service/config";
import companyStore from "../../../CompanyStore";
import { useAllProduct } from "../../../../api-context/commonApiContext";

const OfflineSignatoryKyc = ({ setLoader, loader }) => {
  const { authTokens } = useAllProduct();
  const navigate = useNavigate();
  const [directors, setDirectors] = useState([
    {
      name: "",
      email: "",
      dob: "",
      gender: "Male",
      aadhar: "",
      pan: "",
      address: "",
      remark: "",
      files: [],
      errors: {},
    },
  ]);
  const [progressState, setProgressState] = useState([]);
  const [company_id, setCompany_id] = useState("");

  useEffect(() => {
    setCompany_id(companyStore.companyID);
  }, []);

  useEffect(() => {
    return () => directors.files?.forEach((file) => URL.revokeObjectURL(file.preview));
  }, [directors.files]);

  const validateFields = (director) => {
    const errors = {};

    if (!director.name.trim()) {
      errors.name = "Name is required.";
    } else if (director.name.length < 3) {
      errors.name = "Name must be at least 3 characters.";
    } else if (director.name.length > 100) {
      errors.name = "Name must be less than 100 characters.";
    }

    if (!director.email) {
      errors.email = "Email is required.";
    } else if (!/\S+@\S+\.\S+/.test(director.email)) {
      errors.email = "Email is invalid.";
    }

    if (!director.dob) {
      errors.dob = "Date of birth is required.";
    } else if (!/\d{4}-\d{2}-\d{2}/.test(director.dob)) {
      errors.dob = "Date of birth must be in the format YYYY-MM-DD.";
    }

    if (!director.gender) {
      errors.gender = "Gender is required.";
    }

    if (!director.aadhar.trim()) {
      errors.aadhar = "Aadhar number is required.";
    } else if (!/^\d{12}$/.test(director.aadhar)) {
      errors.aadhar = "Aadhar number must be 12 digits.";
    }

    if (!director.pan.trim()) {
      errors.pan = "PAN number is required.";
    } else if (!/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(director.pan)) {
      errors.pan = "PAN number is invalid.";
    }

    if (!director.address.trim()) {
      errors.address = "Address is required.";
    } else if (director.address.length < 10) {
      errors.address = "Address must be at least 10 characters.";
    } else if (director.address.length > 150) {
      errors.address = "Address must be less than 150 characters.";
    }

    if (director.remark.trim().length > 250) {
      errors.remark = "Remark must be less than 250 characters.";
    }

    return errors;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let hasErrors = false;
    const updatedDirectors = directors.map((director) => {
      const errors = validateFields(director);
      if (Object.keys(errors).length > 0) hasErrors = true;
      return { ...director, errors };
    });
    setDirectors(updatedDirectors);
    if (hasErrors) {
      // alert("Please correct the errors before submitting.");
      return;
    }
    const payload = {
      mode: "offline",
      company_id: company_id,
      created_by: companyStore?.AdminEmail,
      directors: directors.map((director) => ({
        name: director.name,
        gender: director.gender,
        email: director.email,
        dob: director.dob,
        aadhar: director.aadhar,
        pan: director.pan,
        address: director.address,
        remark: director.remark,
        fileUrl: director.files,
      })),
    };

    try {
      // Send directors as JSON
      setLoader(true);
      const response = await axios.post(`${config.node_api_endpoint}/v2/director/offlinekycmanual`, payload, {
        headers: {
          aws_cognito_id_token: authTokens.idToken,
          "Content-Type": "application/json",
        },
      });

      // Update directors state based on response if needed
      setDirectors((prevDirectors) =>
        prevDirectors.map((director, index) => ({
          ...director,
          files: director.files.map((file, fileIndex) => ({
            ...file,
            ...response.data[index]?.files[fileIndex], // Update each file with response data if provided
          })),
        })),
      );
      setLoader(false);
      navigate(`/content/company/kycsuccess`);
    } catch (error) {
      setLoader(false);
      console.error("Error submitting data:", error);
      alert("An error occurred while submitting the data.");
    }
  };

  const handleInputChange = (index, field, value) => {
    const newDirectors = [...directors];
    newDirectors[index][field] = value;
    newDirectors[index].errors[field] = "";
    setDirectors(newDirectors);
  };

  const addDirector = () => {
    setDirectors([
      ...directors,
      {
        name: "",
        email: "",
        dob: "",
        gender: "Male",
        aadhar: "",
        pan: "",
        address: "",
        remark: "",
        files: [],
        errors: {},
      },
    ]);
  };

  const clearDirectorFields = (index) => {
    const newDirectors = [...directors];
    newDirectors[index] = {
      name: "",
      email: "",
      dob: "",
      gender: "",
      aadhar: "",
      pan: "",
      address: "",
      remark: "",
      files: [],
      errors: {},
    };
    setDirectors(newDirectors);
  };

  const removeDirector = (index) => {
    setDirectors(directors.filter((_, i) => i !== index));
  };

  const handleFileChange = (index, event) => {
    const newFiles = Array.from(event.target.files)
      .map((file) => {
        console.log(
          "filesss-->",
          directors[index]?.files.some((f) => f.name === file.name),
        );
        if (directors[index]?.files.some((f) => f.name === file.name)) {
          alert(`File ${file.name} already exists`);
          return null;
        }
        if (file.size > 5 * 1024 * 1024) {
          alert(`File "${file.name}" exceeds the maximum size of 5MB.`);
          return null;
        }

        if (!["application/pdf", "image/jpeg"].includes(file.type)) {
          alert(`File ${file.name} has an unsupported format.`);
          return null;
        }
        const uniqueId = Date.now(); // or generate your own unique ID
        return {
          file,
          name: file.name,
          size: (file.size / (1024 * 1024)).toFixed(2) + " MB",
          progress: 0,
          uploaded: false,
          uniqueId,
          preview: URL.createObjectURL(file),
        };
      })
      .filter((file) => file !== null);

    setDirectors((prevDirectors) => {
      const updatedDirectors = [...prevDirectors];
      const currentFiles = updatedDirectors[index]?.files || [];
      const totalFiles = currentFiles.length + newFiles.length;
      if (totalFiles > 10) {
        alert("You can upload a maximum of 10 files.");
        return prevDirectors;
      }
      const currentTotalSize = currentFiles.reduce((sum, f) => sum + f.file.size, 0);
      const newTotalSize = newFiles.reduce((sum, f) => sum + f.file.size, 0);
      if (currentTotalSize + newTotalSize > 5 * 1024 * 1024) {
        alert("The total size of uploaded files exceeds 5MB.");
        return prevDirectors;
      }
      updatedDirectors[index].files = [...(updatedDirectors[index].files || []), ...newFiles];

      // Proceed with the file upload as before

      updatedDirectors[index].files.forEach((file) => {
        const reader = new FileReader();

        reader.onload = (e) => {
          const arrayBuffer = e.target.result;
          const blob = new Blob([arrayBuffer], { type: file.type });

          const formData = new FormData();
          const uniqueFileName = `${Date.now()}-${file.name}`;
          formData.append("file", blob, uniqueFileName);

          // Upload files
          axios
            .post(`${config.node_api_endpoint}/v2/director/offlinekycfile`, formData, {
              onUploadProgress: (progressEvent) => {
                const uploadProgress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                setDirectors((prevDirectors) => {
                  const updatedDirectors = [...prevDirectors];
                  updatedDirectors[index].files = updatedDirectors[index].files.map((f) => {
                    if (f.uniqueId === file.uniqueId) {
                      return { ...f, progress: uploadProgress };
                    }
                    return f;
                  });
                  return updatedDirectors;
                });
              },
            })
            .then((response) => {
              console.log("File uploaded successfully:", response.data);

              // Update the file object with the fileUrl from the response
              setDirectors((prevDirectors) => {
                const updatedDirectors = [...prevDirectors];
                updatedDirectors[index].files = updatedDirectors[index].files.map((f) => {
                  // Match the file based on uniqueId
                  if (f.uniqueId === file.uniqueId) {
                    return {
                      ...f,
                      fileUrl: response.data.fileUrl,
                      fileName: response.data.fileName,
                      uploaded: true,
                    };
                  }
                  return f;
                });
                return updatedDirectors;
              });
            })
            .catch((error) => {
              console.error("Upload error:", error);
            });
        };

        if (!file.uploaded) {
          reader.readAsArrayBuffer(file.file);
          file.uploaded = true;
        }
      });

      return updatedDirectors;
    });
  };

  const simulateUpload = (files, directorIndex) => {
    const updatedProgressState = [...progressState];

    if (!updatedProgressState[directorIndex]) {
      updatedProgressState[directorIndex] = Array(files.length).fill(0); // Initialize progress for new director
    }

    files.forEach((file, fileIndex) => {
      const interval = setInterval(() => {
        setProgressState((prevState) => {
          const newProgressState = [...prevState];

          if (!newProgressState[directorIndex]) {
            newProgressState[directorIndex] = Array(files.length).fill(0);
          }

          if (newProgressState[directorIndex][fileIndex] < 100) {
            newProgressState[directorIndex][fileIndex] = Math.min(newProgressState[directorIndex][fileIndex] + 10, 100);
          }

          if (newProgressState[directorIndex][fileIndex] === 100) {
            clearInterval(interval);
          }

          return newProgressState;
        });
      }, 1000); // Update progress every 1 second
    });
  };

  // Update directors state with progress
  useEffect(() => {
    setDirectors((prevDirectors) => {
      return prevDirectors.map((director, directorIndex) => {
        const updatedFiles = director.files.map((file, fileIndex) => {
          return { ...file, progress: progressState[directorIndex]?.[fileIndex] || file.progress };
        });

        return { ...director, files: updatedFiles };
      });
    });
  }, [progressState]);

  const handleRemoveFile = (directorIndex, fileIndex) => {
    const fileToDelete = directors[directorIndex].files[fileIndex];

    const fileId = fileToDelete.fileName; // Assuming fileId is stored in the file object

    axios
      .delete(`${config.node_api_endpoint}/api/delete-file/${fileId}`)
      .then(() => {
        // Remove the file from the state
        setDirectors((prevDirectors) => {
          const updatedDirectors = [...prevDirectors];
          updatedDirectors[directorIndex].files = updatedDirectors[directorIndex].files.filter(
            (_, i) => i !== fileIndex,
          );
          return updatedDirectors;
        });
      })
      .catch((error) => {
        console.error("Error deleting file:", error);
        // Handle error, e.g., show an error message to the user
      });
  };

  return (
    <div className={styles.frameGroup}>
      <div className={styles.frameContainer}>
        <div className={styles.frameWrapper}>
          <div className={styles.directorKycParent}>
            <div className={styles.directorKyc}>
              <ol className={styles.directorKycList}>
                {directors.map((director, index) => (
                  <div key={index} className={styles.frameDiv}>
                    <div className={styles.framehead}>
                      <li>Authorized Signatory KYC</li>
                      <div className={styles.buttons}>
                        <button className={styles.input} onClick={() => clearDirectorFields(index)}>
                          Clear
                        </button>
                        {index > 0 && (
                          <button className={styles.remove} onClick={() => removeDirector(index)}>
                            REMOVE
                          </button>
                        )}
                      </div>
                    </div>
                    <div className={`${styles.mobileMenuParent}`}>
                      <TextField
                        className={`w-50`}
                        color="primary"
                        label={`Signatory Name`}
                        variant="outlined"
                        value={director.name}
                        onChange={(e) => handleInputChange(index, "name", e.target.value)}
                        sx={{ "& .MuiInputBase-root": { height: "56px" } }}
                        error={!!director.errors.name}
                        helperText={director.errors.name}
                      />
                      {/* Gender Selection */}
                      <div>
                        <span style={{ fontSize: "18px" }}>Select Gender</span>
                        <div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name={`gender${index}`}
                              id={`male${index}`}
                              value="Male"
                              // checked={director.gender === "Male"}
                              defaultChecked={true}
                              onChange={() => handleInputChange(index, "gender", "Male")}
                              style={{ transform: "scale(0.8)" }}
                            />
                            <label className="form-check-label" style={{ fontSize: "14px" }} htmlFor={`male${index}`}>
                              Male
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name={`gender${index}`}
                              id={`female${index}`}
                              value="Female"
                              // checked={director.gender === "Female"}
                              onChange={() => handleInputChange(index, "gender", "Female")}
                              style={{ transform: "scale(0.8)" }}
                            />
                            <label className="form-check-label" style={{ fontSize: "14px" }} htmlFor={`female${index}`}>
                              Female
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={styles.mobileMenuParent}>
                      <TextField
                        className={"w-50"}
                        color="primary"
                        label={`Signatory Email`}
                        // placeholder={`Signatory Email`}
                        variant="outlined"
                        value={director.email}
                        onChange={(e) => handleInputChange(index, "email", e.target.value)}
                        sx={{ "& .MuiInputBase-root": { height: "56px" } }}
                        error={!!director.errors.email}
                        helperText={director.errors.email}
                      />
                      <TextField
                        className={"w-50"}
                        color="primary"
                        label="Date of Birth"
                        variant="outlined"
                        type="date"
                        value={director.dob}
                        onChange={(e) => handleInputChange(index, "dob", e.target.value)}
                        sx={{ "& .MuiInputBase-root": { height: "56px" } }}
                        error={!!director.errors.dob}
                        helperText={director.errors.dob}
                        InputLabelProps={{
                          shrink: true, // Ensures the label stays above the input
                        }}
                        inputProps={{
                          max: new Date().toISOString().split("T")[0], // Disables future dates
                        }}
                      />
                    </div>
                    <div className={styles.mobileMenuParent}>
                      <TextField
                        className={"w-50"}
                        color="primary"
                        label="Aadhar no"
                        variant="outlined"
                        value={director.aadhar}
                        onChange={(e) => handleInputChange(index, "aadhar", e.target.value)}
                        sx={{ "& .MuiInputBase-root": { height: "56px" } }}
                        error={!!director.errors.aadhar}
                        helperText={director.errors.aadhar}
                      />
                      <TextField
                        className={"w-50"}
                        color="primary"
                        label="Pan no"
                        variant="outlined"
                        type="text"
                        value={director.pan}
                        onChange={(e) => handleInputChange(index, "pan", e.target.value)}
                        sx={{ "& .MuiInputBase-root": { height: "56px" }, width: "344px", fontSize: "16px" }}
                        error={!!director.errors.pan}
                        helperText={director.errors.pan}
                      />
                    </div>
                    <div className={styles.mobileMenuParent}>
                      <TextField
                        className={`${styles.mobileMenu} w-100`}
                        color="primary"
                        label="Address"
                        variant="outlined"
                        value={director.address}
                        onChange={(e) => handleInputChange(index, "address", e.target.value)}
                        sx={{ "& .MuiInputBase-root": { height: "56px" } }}
                        error={!!director.errors.address}
                        helperText={director.errors.address}
                      />
                    </div>
                    {/* <textarea
                      className={styles.mobileMenu2}
                      placeholder="Remark"
                      value={director.remark}
                      onChange={(e) => handleInputChange(index, "remark", e.target.value)}
                    /> */}
                    <div className={styles.mobileMenu3}>
                      <TextField
                        className={styles.field1}
                        placeholder="Remark"
                        type="text"
                        label={`Remark ${index + 1}`}
                        multiline
                        minRows={3}
                        value={director.remark}
                        onChange={(e) => handleInputChange(index, "remark", e.target.value)}
                        error={!!director.errors.remark}
                        helperText={director.errors.remark}
                        sx={{ "& .MuiInputBase-root": { fontSize: "14px" } }}
                      />
                    </div>
                    <Typography variant="h6" gutterBottom>
                      Upload Documents
                    </Typography>
                    <Box
                      sx={{
                        width: "50%",
                        // maxWidth: 400,
                        // margin: "auto",
                        padding: 2,
                      }}
                    >
                      {director.files.map((file, fileIndex) => (
                        <Paper
                          key={fileIndex}
                          variant="outlined"
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            padding: 1,
                            marginBottom: 1,
                            borderRadius: 2,
                            border: "1px solid #e0e0e0",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              marginRight: 2,
                            }}
                          >
                            {file &&
                              file.preview &&
                              (file.file.type === "application/pdf" ? (
                                <embed
                                  src="/pdf-logo.svg"
                                  alt={file.name}
                                  style={{
                                    width: 40,
                                    height: 40,
                                    borderRadius: 4,
                                  }}
                                />
                              ) : (
                                <img
                                  src={file.preview}
                                  alt={file.name}
                                  style={{
                                    width: 40,
                                    height: 40,
                                    objectFit: "cover",
                                    borderRadius: 4,
                                  }}
                                />
                              ))}
                          </Box>
                          <Box
                            sx={{
                              flex: 1,
                              display: "flex",
                              flexDirection: "column",
                              marginRight: 1,
                            }}
                          >
                            <Typography variant="body2" sx={{ fontWeight: 500 }}>
                              {file.name}
                            </Typography>
                            <Typography variant="caption" color="textSecondary" className="w-100">
                              {file.size}
                            </Typography>
                          </Box>
                          {/* <Box sx={{ flex: 2, marginRight: 1 }}>
                            {file.progress < 100 && loader && (
                              <LinearProgress
                                variant="determinate"
                                value={file.progress}
                                sx={{ height: 8, borderRadius: 5 }}
                              />
                            )}
                          </Box> */}
                          <IconButton onClick={() => handleRemoveFile(index, fileIndex)} size="small">
                            <Close fontSize="small" />
                          </IconButton>
                        </Paper>
                      ))}

                      <Button
                        variant="contained"
                        component="label"
                        // startIcon={<CloudUpload />}
                        startIcon={
                          <img
                            src="/fileUpload.svg"
                            alt="Upload Icon"
                            style={{
                              width: 24, // Adjust the width to your preference
                              height: 24, // Adjust the height to your preference
                            }}
                          />
                        }
                        sx={{
                          marginTop: 2,
                          padding: 1.5,
                          border: "2px dotted #AAAAAA",
                          borderRadius: 2,
                          fontSize: "14px",
                          width: "100%",
                          backgroundColor: "#ffffff",
                          color: "#777777",
                          boxShadow: "none",
                          textTransform: "none",
                          "&:hover": {
                            backgroundColor: "#f5f5f5",
                          },
                        }}
                      >
                        Drag & drop the file here or &nbsp;{" "}
                        <span
                          style={{
                            textDecoration: "underline",
                            color: "#2348AB",
                            fontWeight: 700,
                          }}
                        >
                          {" "}
                          Click to upload
                        </span>
                        <input
                          type="file"
                          hidden
                          multiple
                          accept="application/pdf,image/jpeg"
                          onChange={(event) => handleFileChange(index, event)}
                        />
                      </Button>
                      <Typography
                        variant="caption"
                        display="block"
                        sx={{ marginTop: 1, textAlign: "center", color: "#757575" }}
                      >
                        (File type PDF & JPEG / Max 10 / Max Total File Size 5MB)
                      </Typography>
                    </Box>
                  </div>
                ))}
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className={`${styles.loadsButtonsParent} w-50 mb-5`}>
        <button className={styles.loadsButtons} onClick={addDirector}>
          <img className={styles.arrowNarrowLeftIcon} alt="" src="/pluscircle3.svg" />
          <b className={styles.addDirector}>Add Signatory</b>
        </button>
        <button className={styles.loadsButtonsWrapper} onClick={handleSubmit}>
          <div className={styles.loadsButtons1}>
            <b className={styles.generateRequest}>Submit</b>
          </div>
        </button>
      </div>
    </div>
  );
};

export default OfflineSignatoryKyc;

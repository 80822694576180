import React, { useState } from "react";
import styles from "./NewCompanyModel.module.css";
import axios from "axios";
import config from "../../../../service/config";
import companyStore from "../../CompanyStore";
import { useAllProduct } from "../../../api-context/commonApiContext";

export default function NewCompanyModel({
  className = "",
  isVisible,
  onclose,
  setLoader,
  clientID,
  modalRef,
  compName,
  setCompName,
  errorMsg,
  setErrorMsg,
}) {
  const { authTokens } = useAllProduct();

  const HandleData = () => {
    const trimmedCompName = compName.trim();

    if (trimmedCompName === "") {
      setErrorMsg("Escrow Name is Required");
      return;
    }
    if (trimmedCompName.length < 5) {
      setErrorMsg("Escrow Name must be at least 5 characters long");
      return;
    }
    setErrorMsg("");
    const payload = { name: trimmedCompName, created_by: companyStore?.AdminEmail };
    setLoader(true);
    axios
      .post(`${config.node_api_endpoint}/v1/escrow`, payload, {
        headers: {
          aws_cognito_id_token: authTokens.idToken,
          "X-Client-Id": clientID,
          "Content-Type": "application/json",
        },
      })
      .then(() => {
        setLoader(false);
        console.log("Company Added");
        setCompName("");
        onclose();
      })
      .catch((err) => {
        setLoader(false);
        if (err.response?.data?.message || err.message) {
          setErrorMsg(`${err.response?.data?.message || err.message}`);
        } else {
          setCompName("");
          onclose();
        }
      });
  };

  if (!isVisible) {
    return null;
  }

  return (
    <div className={styles.newModelcontainer}>
      <div ref={modalRef} className={[styles.addCompany, className].join(" ")}>
        <div className="w-100 d-flex justify-content-between">
          <div className={styles.newCompany}>New Escrow account</div>
          <img
            alt=""
            src="/xcircle.svg"
            style={{ cursor: "pointer" }}
            onClick={() => {
              onclose();
              setCompName("");
              setErrorMsg("");
            }}
          />
        </div>
        <div className={styles.mobileMenuParent}>
          <div className={`${styles.mobileMenu} mb-3`}>
            <input
              placeholder="Escrow Name"
              value={compName}
              className={styles.field}
              onChange={(e) => {
                const value = e.target.value;
                if (value.length > 100) {
                  setErrorMsg("Escrow Name cannot exceed 100 characters");
                } else {
                  setErrorMsg("");
                }
                setCompName(value);
              }}
            />
            <img className={styles.trailingIcon2} alt="" src="/trailingicon-21.svg" />
          </div>
          {errorMsg && <div className={`${styles.errorMsg}`}>{errorMsg}</div>}
          <div
            /* className={`${styles.loadsButtonsParent} mt-2`} */ className="w-100 d-flex justify-content-between m-1"
          >
            <div
              className={`${styles.loadsButtons} w-50 p-2`}
              onClick={() => {
                setCompName("");
                setErrorMsg("");
                onclose();
              }}
            >
              <div style={{ fontWeight: "700", fontSize: "16px" }}>Cancel</div>
            </div>
            <div
              className={`${styles.loadsButtons1} w-50 mx-2`}
              onClick={() => {
                HandleData();
              }}
            >
              <img className={styles.boxArrowInDownIcon} alt="" src="/boxarrowindown3.svg" />
              <b className={styles.loads}>Create</b>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

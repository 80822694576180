import React, { useEffect, useState } from "react";
import styles from "./userModal.module.css";
import { TextField, MenuItem, Button } from "@mui/material";
import axios from "axios";
import config from "../../../service/config";
import { useAllProduct } from "../../api-context/commonApiContext";
import { CognitoIdentityProviderClient, SignUpCommand } from "@aws-sdk/client-cognito-identity-provider";

const UserModal = ({ setShowModal, selectedUser, setFilteredData, setSelectedUser, clientId, modalRef }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    role: "",
  });
  const { role, authTokens, setLoader } = useAllProduct();
  const [errors, setErrors] = useState({ name: "", email: "", role: "", cognito: "" });
  useEffect(() => {
    if (selectedUser) {
      setFormData({
        name: selectedUser.name || "",
        email: selectedUser.email || "",
        role: selectedUser?.auth?.map((d) => d.role?.map((data) => data).toString()) || "",
      });
    }
  }, [selectedUser]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const updateUser = async (userData) => {
    if (Object.keys(authTokens).length > 0) {
      try {
        setLoader(true);
        await axios.put(`${config.node_api_endpoint}/v1/user/updateUserData`, userData, {
          headers: {
            aws_cognito_id_token: authTokens.idToken,
            "Content-Type": "application/json",
          },
        });

        let bodyHtml = `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
      <html lang="und" dir="auto" xmlns="http://www.w3.org/1999/xhtml">
        <head>
          <style type="text/css">
            @import url("https://fonts.googleapis.com/css2?family=Sarabun:wght@400;500;600");
          </style>
      
          <title></title>
          <!--[if (!mso)&(!ie)]>These<!-- -->
          <!--<![endif]-->
          <!--[if (!mso)&(!ie)]>are<!-- -->
          <!--<![endif]-->
          <!--[if (!mso)&(!ie)]>for<!-- -->
          <!--<![endif]-->
          <!--[if (!mso)&(!ie)]>outlook<!-- -->
          <!--<![endif]-->
          <!--[if (!mso)&(!ie)]>live<!-- -->
          <!--<![endif]-->
          <!--[if (!mso)&(!ie)]>that<!-- -->
          <!--<![endif]-->
          <!--[if (!mso)&(!ie)]>removes<!-- -->
          <!--<![endif]-->
          <!--[if (!mso)&(!ie)]>the first<!-- -->
          <!--<![endif]-->
          <!--[if (!mso)&(!ie)]>10 well-formed<!-- -->
          <!--<![endif]-->
          <!--[if (!mso)&(!ie)]>conditional comments<!-- -->
          <!--<![endif]-->
          <!--[if gte mso 9]>
            <xml>
              <o:OfficeDocumentSettings xmlns:o="urn:schemas-microsoft-com:office:office">
                <o:AllowPNG />
                <o:PixelsPerInch>96</o:PixelsPerInch>
              </o:OfficeDocumentSettings>
            </xml>
          <![endif]-->
      
          <style type="text/css">
            @media (prefers-color-scheme: dark) {
            }
          </style>
      
          <meta name="color-scheme" content="light dark" />
      
          <meta name="supported-color-schemes" content="light dark" />
      
          <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
      
          <!--[if !mso]><!-->
          <meta http-equiv="X-UA-Compatible" content="IE=edge" />
          <!--<![endif]-->
      
          <meta name="x-apple-disable-message-reformatting" />
      
          <style></style>
      
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      
          <style type="text/css">
            u + div .kombai-email-compat__list-with-padding-left {
              padding-left: 0.5em !important;
            }
          </style>
      
          <!--[if mso]>
            <style type="text/css">
              v\:* {
                behavior: url(#default#VML);
                display: inline-block;
              }
              o\:* {
                behavior: url(#default#VML);
                display: inline-block;
              }
              w\:* {
                behavior: url(#default#VML);
                display: inline-block;
              }
              .ExternalClass {
                width: 100%;
              }
              table {
                mso-table-lspace: 0pt;
                mso-table-rspace: 0pt;
              }
              img {
                -ms-interpolation-mode: bicubic;
              }
              .ReadMsgBody {
                width: 100%;
              }
              a {
                background: transparent !important;
                background-color: transparent !important;
              }
      
              li {
                text-align: -webkit-match-parent;
                display: list-item;
                text-indent: -1em;
              }
      
              ul,
              ol {
                margin-left: 1em !important;
              }
      
              p {
                text-indent: 0;
              }
            </style>
          <![endif]-->
        </head>
        <body style="margin: 0; padding: 0">
          <div style="font-size: 0px; line-height: 1px; mso-line-height-rule: exactly; display: none; max-width: 0px; max-height: 0px; opacity: 0; overflow: hidden; mso-hide: all"></div>
          <center lang="und" dir="auto" style="width: 100%; table-layout: fixed; -webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%">
            <table cellpadding="0" cellspacing="0" border="0" role="presentation" bgcolor="white" width="657.00" style="border-radius: 12px; background-color: white; padding-top: 32px; padding-bottom: 28.02px; width: 657px; border-spacing: 0; font-family: Sarabun, sans-serif; min-width: 657px; border-collapse: separate">
              <tr>
                <td valign="middle" width="100.00%" style="width: 100%; vertical-align: middle">
                  <table cellpadding="0" cellspacing="0" border="0" role="presentation" width="100.00%" style="width: 100%; border-spacing: 0">
                   <tr>
          <td align="center" style="padding-bottom: 13.76px">
            <img src="cid:logo" width="101" height="50" alt="Logo" />
        </td>
        </tr>
                    <tr>
                      <td align="left" style="padding-top: 13.75px; padding-bottom: 12.15px; padding-left: 32px">
                        <p width="100.00%" style="font-size: 20px; font-weight: 400; letter-spacing: 0.4px; color: #4c4e64; margin: 0; padding: 0; width: 100%; line-height: 21.44px; mso-line-height-rule: exactly"><span>Dear </span><span style="font-size: 20px; font-weight: 600; letter-spacing: 0.4px; text-align: left">${userData.name},</span></p>
                      </td>
                    </tr>
                    <tr>
                      <td align="left" style="padding-top: 12.15px; padding-left: 32px; padding-right: 32px">
                        <p width="100.00%" style="text-align: left; color: #777777; margin: 0; padding: 0; width: 100%">
                          <span style="font-size: 20px; font-weight: 500; letter-spacing: 0.4px; text-align: left">Your role on Digital onboarding has been updated to</span><span style="font-size: 20px; font-weight: 600; color: #333333; letter-spacing: 0.4px; text-align: left"> ${formData.role}.</span><br /><span style="font-size: 20px; font-weight: 500; letter-spacing: 0.4px; text-align: left">Feel free to explore your updated permissions by logging in to Digital Onboarding.</span>
                        </p>
                      </td>
                    </tr>
                     <tr>
              <td align="left" style="padding-top: 13.75px; padding-bottom: 12.15px; padding-left: 32px">
               <span style="font-size: 20px; font-weight: 500; letter-spacing: 0.4px; text-align: left; color: #777777",>Best regards,</span>
                <br />
                <span style="font-size: 20px; font-weight: 500; letter-spacing: 0.4px; text-align: left; color: #777777;">Digital Onboarding Team</span>
            </td>
            </tr>
                  </table>
                </td>
              </tr>
            </table>
          </center>
        </body>
      </html>`;
        const emailPayload = {
          to: userData.email,
          body: bodyHtml,
          subject: "Your Role on Digital onboarding has Been Updated.",
        };

        axios
          .post(`${config.email_api_endpoint}/send-email`, emailPayload, {
            headers: { "Content-Type": "multipart/form-data", "x-api-key": config.email_key },
          })
          .then((res) => {
            // setHideButton(true);
            setLoader(false);
            setSelectedUser([]);
          })
          .catch((err) => {
            console.log("", err);
          });
        fetchUsers();
        setShowModal(false);
      } catch (error) {
        console.error("Error updating user:", error.response?.data || error.message);
        alert(error.response?.data?.error || "An unexpected error occurred. Please try again later.");
      }
    }
  };

  const fetchUsers = async () => {
    if (Object.keys(authTokens).length > 0) {
      try {
        setLoader(true);
        const response = await axios.get(`${config.node_api_endpoint}/v1/user/getAllUserData`, {
          headers: {
            aws_cognito_id_token: authTokens.idToken,
            "Content-Type": "application/json",
          },
        });
        setLoader(false);
        setFilteredData(response.data.users);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    }
  };

  const validateFields = () => {
    const newErrors = {};
    if (!formData.name.trim()) {
      newErrors.name = "Name is required";
    } else if (formData.name.trim().length < 3) {
      newErrors.name = "Name should be at least 3 characters long";
    } else if (formData.name.trim().length > 50) {
      newErrors.name = "Name should be at most 50 characters long";
    }

    if (!formData.email || !/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(formData.email)) {
      newErrors.email = "Please enter a valid email address.";
    }
    if (!formData.role) {
      newErrors.role = "Role is required.";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // No errors
  };

  const awscognito = async () => {
    const newErrors = {};
    const client = new CognitoIdentityProviderClient({ region: "ap-south-1" });
    const params = {
      ClientId: config.cognito_client_id,
      Username: formData.email,
      Password: "Password@123",
      UserAttributes: [
        {
          Name: "email",
          Value: formData.email,
        },
      ],
    };

    try {
      const command = new SignUpCommand(params);
      const response = await client.send(command);
      // alert("Success: Sign up successful. Please check your email for confirmation.");
      return response;
    } catch (error) {
      console.error("Sign up error:", error);
      // alert(`Error: ${error.message || "Sign up failed."}`);
      newErrors.cognito = `${error.message || "Sign up failed."}`;
    }
    setErrors(newErrors);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(errors);
    if (!validateFields()) return;
    if (!selectedUser.user_id) {
      console.log("new user creation");
      setLoader(true);
      awscognito().then(async (res) => {
        console.log("resData-->", res);

        if (res?.$metadata?.httpStatusCode === 200) {
          setLoader(false);
          console.log("res-->", res);
          const payload = {
            userId: selectedUser?.user_id || null, // Include _id only for updates
            name: formData.name,
            email: formData.email,
            role: formData.role.toString(),
            status: "active",
            clientId: clientId,
          };

          if (selectedUser?.user_id) {
            // If the user exists, update
            await updateUser(payload);
          } else {
            // Otherwise, create a new user
            try {
              const response = await axios.post(`${config.node_api_endpoint}/v1/user/createuser`, payload, {
                headers: {
                  aws_cognito_id_token: authTokens.idToken,
                  "Content-Type": "application/json",
                },
              });
              console.log("User created successfully:", response.data);
              let bodyHtml = `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
                <html lang="und" dir="auto" xmlns="http://www.w3.org/1999/xhtml">
                  <head>
                    <style type="text/css">
                      @import url("https://fonts.googleapis.com/css2?family=Sarabun:wght@400;500;600");
                    </style>
                
                    <title></title>
                    <!--[if (!mso)&(!ie)]>These<!-- -->
                    <!--<![endif]-->
                    <!--[if (!mso)&(!ie)]>are<!-- -->
                    <!--<![endif]-->
                    <!--[if (!mso)&(!ie)]>for<!-- -->
                    <!--<![endif]-->
                    <!--[if (!mso)&(!ie)]>outlook<!-- -->
                    <!--<![endif]-->
                    <!--[if (!mso)&(!ie)]>live<!-- -->
                    <!--<![endif]-->
                    <!--[if (!mso)&(!ie)]>that<!-- -->
                    <!--<![endif]-->
                    <!--[if (!mso)&(!ie)]>removes<!-- -->
                    <!--<![endif]-->
                    <!--[if (!mso)&(!ie)]>the first<!-- -->
                    <!--<![endif]-->
                    <!--[if (!mso)&(!ie)]>10 well-formed<!-- -->
                    <!--<![endif]-->
                    <!--[if (!mso)&(!ie)]>conditional comments<!-- -->
                    <!--<![endif]-->
                    <!--[if gte mso 9]>
                      <xml>
                        <o:OfficeDocumentSettings xmlns:o="urn:schemas-microsoft-com:office:office">
                          <o:AllowPNG />
                          <o:PixelsPerInch>96</o:PixelsPerInch>
                        </o:OfficeDocumentSettings>
                      </xml>
                    <![endif]-->
                
                    <style type="text/css">
                      @media (prefers-color-scheme: dark) {
                      }
                    </style>
                
                    <meta name="color-scheme" content="light dark" />
                
                    <meta name="supported-color-schemes" content="light dark" />
                
                    <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
                
                    <!--[if !mso]><!-->
                    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                    <!--<![endif]-->
                
                    <meta name="x-apple-disable-message-reformatting" />
                
                    <style></style>
                
                    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                
                    <style type="text/css">
                      u + div .kombai-email-compat__list-with-padding-left {
                        padding-left: 0.5em !important;
                      }
                    </style>
                
                    <!--[if mso]>
                      <style type="text/css">
                        v\:* {
                          behavior: url(#default#VML);
                          display: inline-block;
                        }
                        o\:* {
                          behavior: url(#default#VML);
                          display: inline-block;
                        }
                        w\:* {
                          behavior: url(#default#VML);
                          display: inline-block;
                        }
                        .ExternalClass {
                          width: 100%;
                        }
                        table {
                          mso-table-lspace: 0pt;
                          mso-table-rspace: 0pt;
                        }
                        img {
                          -ms-interpolation-mode: bicubic;
                        }
                        .ReadMsgBody {
                          width: 100%;
                        }
                        a {
                          background: transparent !important;
                          background-color: transparent !important;
                        }
                
                        li {
                          text-align: -webkit-match-parent;
                          display: list-item;
                          text-indent: -1em;
                        }
                
                        ul,
                        ol {
                          margin-left: 1em !important;
                        }
                
                        p {
                          text-indent: 0;
                        }
                      </style>
                    <![endif]-->
                  </head>
                  <body style="margin: 0; padding: 0">
                    <div style="font-size: 0px; line-height: 1px; mso-line-height-rule: exactly; display: none; max-width: 0px; max-height: 0px; opacity: 0; overflow: hidden; mso-hide: all"></div>
                    <center lang="und" dir="auto" style="width: 100%; table-layout: fixed; -webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%">
                      <table cellpadding="0" cellspacing="0" border="0" role="presentation" bgcolor="white" width="657.00" style="border-radius: 12px; background-color: white; padding-top: 32px; padding-bottom: 28.02px; width: 657px; border-spacing: 0; font-family: Sarabun, sans-serif; min-width: 657px; border-collapse: separate">
                        <tr>
                          <td valign="middle" width="100.00%" style="width: 100%; vertical-align: middle">
                            <table cellpadding="0" cellspacing="0" border="0" role="presentation" width="100.00%" style="width: 100%; border-spacing: 0">
                             <tr>
          <td align="center" style="padding-bottom: 13.76px">
            <img src="cid:logo" width="101" height="50" alt="Logo" />
        </td>
        </tr>
                              <tr>
                                <td align="left" style="padding-top: 13.75px; padding-bottom: 12.15px; padding-left: 32px">
                                  <p width="100.00%" style="font-size: 20px; font-weight: 400; letter-spacing: 0.4px; color: #4c4e64; margin: 0; padding: 0; width: 100%; line-height: 21.44px; mso-line-height-rule: exactly"><span>Dear </span><span style="font-size: 20px; font-weight: 600; letter-spacing: 0.4px; text-align: left">${formData?.name},</span></p>
                                </td>
                              </tr>
                              <tr>
                                <td align="left" style="padding-top: 12.15px; padding-left: 32px; padding-right: 32px">
                                  <p width="100.00%" style="text-align: left; color: #777777; margin: 0; padding: 0; width: 100%">
                                    <span style="font-size: 20px; font-weight: 500; letter-spacing: 0.4px; text-align: left">You have been successfully added as a user on </span><span style="font-size: 20px; font-weight: 600; color: #333333; letter-spacing: 0.4px; text-align: left">Digital Onboarding Portal.</span><span style="font-size: 20px; font-weight: 500; letter-spacing: 0.4px; text-align: left"> Your role is set as </span><span style="font-size: 20px; font-weight: 600; color: #333333; letter-spacing: 0.4px; text-align: left">${formData?.role}.</span><br /><span style="font-size: 20px; font-weight: 500; letter-spacing: 0.4px; text-align: left">Here are your account details:</span><br /><span style="font-size: 20px; font-weight: 500; letter-spacing: 0.4px; text-align: left">Email: </span><span style="font-size: 20px; font-weight: 500; letter-spacing: 0.4px; text-align: left">${formData?.email}</span><br /><span style="font-size: 20px; font-weight: 500; letter-spacing: 0.4px; text-align: left">Role: </span><span style="font-size: 20px; font-weight: 500; letter-spacing: 0.4px; text-align: left">${formData?.role}</span>
                                  </p>
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                            <tr>
                      <td align="left" style="padding-top: 13.75px; padding-bottom: 12.15px; padding-left: 32px">
                       <span style="font-size: 20px; font-weight: 500; letter-spacing: 0.4px; text-align: left; color: #777777",>Best regards,</span>
                        <br />
                        <span style="font-size: 20px; font-weight: 500; letter-spacing: 0.4px; text-align: left; color: #777777;">Digital Onboarding Team</span>
                    </td>
                    </tr>
                      </table>
                    </center>
                  </body>
                </html>`;
              const emailPayload = {
                to: formData.email,
                body: bodyHtml,
                subject: "You Have Been Added as a User on Digital Onboarding",
              };

              axios
                .post(`${config.email_api_endpoint}/send-email`, emailPayload, {
                  headers: {
                    "Content-Type": "multipart/form-data",
                    "x-api-key": config.email_key,
                  },
                })
                .then((res) => {
                  // setHideButton(true);
                  setLoader(false);
                  console.log(res.data);
                })
                .catch((err) => {
                  console.log("", err);
                });
              fetchUsers();
              setShowModal(false);
            } catch (error) {
              console.error("Error creating user:", error.response?.data || error.message);
              alert(error.response?.data?.error || "An unexpected error occurred. Please try again later.");
            }
          }
        }
      });
    } else {
      const payload = {
        userId: selectedUser?.user_id || null, // Include _id only for updates
        name: formData.name,
        email: formData.email,
        role: formData.role.toString(),
        status: "active",
        clientId: clientId,
      };

      if (selectedUser.user_id) {
        // If the user exists, update
        await updateUser(payload);
      } else {
        // Otherwise, create a new user
        try {
          const response = await axios.post(`${config.node_api_endpoint}/v1/user/createuser`, payload, {
            headers: {
              aws_cognito_id_token: authTokens.idToken,
              "Content-Type": "application/json",
            },
          });
          console.log("User created successfully:", response.data);
          let bodyHtml = `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
            <html lang="und" dir="auto" xmlns="http://www.w3.org/1999/xhtml">
              <head>
                <style type="text/css">
                  @import url("https://fonts.googleapis.com/css2?family=Sarabun:wght@400;500;600");
                </style>
            
                <title></title>
                <!--[if (!mso)&(!ie)]>These<!-- -->
                <!--<![endif]-->
                <!--[if (!mso)&(!ie)]>are<!-- -->
                <!--<![endif]-->
                <!--[if (!mso)&(!ie)]>for<!-- -->
                <!--<![endif]-->
                <!--[if (!mso)&(!ie)]>outlook<!-- -->
                <!--<![endif]-->
                <!--[if (!mso)&(!ie)]>live<!-- -->
                <!--<![endif]-->
                <!--[if (!mso)&(!ie)]>that<!-- -->
                <!--<![endif]-->
                <!--[if (!mso)&(!ie)]>removes<!-- -->
                <!--<![endif]-->
                <!--[if (!mso)&(!ie)]>the first<!-- -->
                <!--<![endif]-->
                <!--[if (!mso)&(!ie)]>10 well-formed<!-- -->
                <!--<![endif]-->
                <!--[if (!mso)&(!ie)]>conditional comments<!-- -->
                <!--<![endif]-->
                <!--[if gte mso 9]>
                  <xml>
                    <o:OfficeDocumentSettings xmlns:o="urn:schemas-microsoft-com:office:office">
                      <o:AllowPNG />
                      <o:PixelsPerInch>96</o:PixelsPerInch>
                    </o:OfficeDocumentSettings>
                  </xml>
                <![endif]-->
            
                <style type="text/css">
                  @media (prefers-color-scheme: dark) {
                  }
                </style>
            
                <meta name="color-scheme" content="light dark" />
            
                <meta name="supported-color-schemes" content="light dark" />
            
                <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
            
                <!--[if !mso]><!-->
                <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                <!--<![endif]-->
            
                <meta name="x-apple-disable-message-reformatting" />
            
                <style></style>
            
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            
                <style type="text/css">
                  u + div .kombai-email-compat__list-with-padding-left {
                    padding-left: 0.5em !important;
                  }
                </style>
            
                <!--[if mso]>
                  <style type="text/css">
                    v\:* {
                      behavior: url(#default#VML);
                      display: inline-block;
                    }
                    o\:* {
                      behavior: url(#default#VML);
                      display: inline-block;
                    }
                    w\:* {
                      behavior: url(#default#VML);
                      display: inline-block;
                    }
                    .ExternalClass {
                      width: 100%;
                    }
                    table {
                      mso-table-lspace: 0pt;
                      mso-table-rspace: 0pt;
                    }
                    img {
                      -ms-interpolation-mode: bicubic;
                    }
                    .ReadMsgBody {
                      width: 100%;
                    }
                    a {
                      background: transparent !important;
                      background-color: transparent !important;
                    }
            
                    li {
                      text-align: -webkit-match-parent;
                      display: list-item;
                      text-indent: -1em;
                    }
            
                    ul,
                    ol {
                      margin-left: 1em !important;
                    }
            
                    p {
                      text-indent: 0;
                    }
                  </style>
                <![endif]-->
              </head>
              <body style="margin: 0; padding: 0">
                <div style="font-size: 0px; line-height: 1px; mso-line-height-rule: exactly; display: none; max-width: 0px; max-height: 0px; opacity: 0; overflow: hidden; mso-hide: all"></div>
                <center lang="und" dir="auto" style="width: 100%; table-layout: fixed; -webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%">
                  <table cellpadding="0" cellspacing="0" border="0" role="presentation" bgcolor="white" width="657.00" style="border-radius: 12px; background-color: white; padding-top: 32px; padding-bottom: 28.02px; width: 657px; border-spacing: 0; font-family: Sarabun, sans-serif; min-width: 657px; border-collapse: separate">
                    <tr>
                      <td valign="middle" width="100.00%" style="width: 100%; vertical-align: middle">
                        <table cellpadding="0" cellspacing="0" border="0" role="presentation" width="100.00%" style="width: 100%; border-spacing: 0">
                         <tr>
          <td align="center" style="padding-bottom: 13.76px">
           <img src="cid:logo" width="101" height="50" alt="Logo" />
        </td>
        </tr>
                          <tr>
                            <td align="left" style="padding-top: 13.75px; padding-bottom: 12.15px; padding-left: 32px">
                              <p width="100.00%" style="font-size: 20px; font-weight: 400; letter-spacing: 0.4px; color: #4c4e64; margin: 0; padding: 0; width: 100%; line-height: 21.44px; mso-line-height-rule: exactly"><span>Dear </span><span style="font-size: 20px; font-weight: 600; letter-spacing: 0.4px; text-align: left">${formData?.name},</span></p>
                            </td>
                          </tr>
                          <tr>
                            <td align="left" style="padding-top: 12.15px; padding-left: 32px; padding-right: 32px">
                              <p width="100.00%" style="text-align: left; color: #777777; margin: 0; padding: 0; width: 100%">
                                <span style="font-size: 20px; font-weight: 500; letter-spacing: 0.4px; text-align: left">You have been successfully added as a user on </span><span style="font-size: 20px; font-weight: 600; color: #333333; letter-spacing: 0.4px; text-align: left">Digital Onboarding Portal.</span><span style="font-size: 20px; font-weight: 500; letter-spacing: 0.4px; text-align: left"> Your role is set as </span><span style="font-size: 20px; font-weight: 600; color: #333333; letter-spacing: 0.4px; text-align: left">${formData?.role}.</span><br /><span style="font-size: 20px; font-weight: 500; letter-spacing: 0.4px; text-align: left">Here are your account details:</span><br /><span style="font-size: 20px; font-weight: 500; letter-spacing: 0.4px; text-align: left">Email: </span><span style="font-size: 20px; font-weight: 500; letter-spacing: 0.4px; text-align: left">${formData?.email}</span><br /><span style="font-size: 20px; font-weight: 500; letter-spacing: 0.4px; text-align: left">Role: </span><span style="font-size: 20px; font-weight: 500; letter-spacing: 0.4px; text-align: left">${formData?.role}</span>
                              </p>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                        <tr>
                  <td align="left" style="padding-top: 13.75px; padding-bottom: 12.15px; padding-left: 32px">
                   <span style="font-size: 20px; font-weight: 500; letter-spacing: 0.4px; text-align: left; color: #777777",>Best regards,</span>
                    <br />
                    <span style="font-size: 20px; font-weight: 500; letter-spacing: 0.4px; text-align: left; color: #777777;">Digital Onboarding Team</span>
                </td>
                </tr>
                  </table>
                </center>
              </body>
            </html>`;
          const emailPayload = {
            to: formData.email,
            body: bodyHtml,
            subject: "You Have Been Added as a User on Digital Onboarding",
          };

          axios
            .post(`${config.email_api_endpoint}/send-email`, emailPayload, {
              headers: {
                "Content-Type": "multipart/form-data",
                "x-api-key": config.email_key,
              },
            })
            .then((res) => {
              // setHideButton(true);
              setLoader(false);
              console.log(res.data);
            })
            .catch((err) => {
              console.log("", err);
            });
          fetchUsers();
          setShowModal(false);
        } catch (error) {
          console.error("Error creating user:", error.response?.data || error.message);
          alert(error.response?.data?.error || "An unexpected error occurred. Please try again later.");
        }
      }
    }
  };
  // console.log("user", selectedUser);

  return (
    <div className={styles.modalBackdrop}>
      <div className={styles.modalContainer} ref={modalRef}>
        <button
          className={`${styles.backButton} d-flex`}
          onClick={() => {
            setSelectedUser("");
            setShowModal(false);
          }}
        >
          &#x2190; Back
        </button>
        <h2 className={styles.modalTitle}>
          {selectedUser?.name?.length > 0 ? "Update User Details" : "Add User Details"}
        </h2>
        <form onSubmit={handleSubmit}>
          <div className={styles.formGroup}>
            <TextField
              label="Name"
              name="name"
              variant="outlined"
              value={formData.name}
              onChange={handleChange}
              fullWidth
              sx={{
                "& .MuiInputBase-root": { borderRadius: "12px" },
                marginBottom: "5px",
              }}
              disabled={selectedUser?.name?.length > 0 ? true : false}
            />
            {errors.name && <span className={styles.errorColor}>{errors.name}</span>}
          </div>
          <div className={styles.formGroup}>
            <TextField
              label="User Email ID"
              name="email"
              variant="outlined"
              // type="email"
              value={formData.email}
              onChange={handleChange}
              fullWidth
              sx={{
                "& .MuiInputBase-root": { borderRadius: "12px" },
                marginBottom: "5px",
                marginTop: "5px",
              }}
              disabled={selectedUser?.email?.length > 0 ? true : false}
            />
            {errors.email && <span className={styles.errorColor}>{errors.email}</span>}
          </div>
          <div className={styles.formGroup}>
            <TextField
              select
              label="Role"
              name="role"
              value={formData.role}
              onChange={handleChange}
              fullWidth
              sx={{
                "& .MuiInputBase-root": { borderRadius: "12px" },
                marginBottom: "5px",
                marginTop: "5px",
              }}
            >
              <MenuItem value="">Select Role</MenuItem>
              {role.toString() === "Owner" && <MenuItem value="Super Admin">Super Admin</MenuItem>}
              {(role.toString() === "Owner" || role.toString() === "Super Admin") && (
                <MenuItem value="Admin">Admin</MenuItem>
              )}
              {(role.toString() === "Owner" || role.toString() === "Super Admin" || role.toString() === "Admin") && (
                <MenuItem value="Maker">Maker</MenuItem>
              )}
              <MenuItem value="Approver">Approver</MenuItem>
              <MenuItem value="Viewer">Viewer</MenuItem>
            </TextField>
            {errors.role && <span className={styles.errorColor}>{errors.role}</span>}
            {errors.cognito && <span className={styles.errorColor}>{errors.cognito}</span>}
          </div>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            sx={{ borderRadius: "12px", height: "50px", backgroundColor: "#2348AB", marginTop: "5px" }}
          >
            {selectedUser?.name?.length > 0 ? "Update" : "Submit"}
          </Button>
        </form>
      </div>
    </div>
  );
};

export default UserModal;

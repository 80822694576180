import React, { useState, useEffect, useRef } from "react";
import Draggable from "react-draggable";
import axios from "axios";
import { useParams } from "react-router-dom";
import styles from "./InspectPdf.module.css";
import config from "../../../../service/config";
import companyStore from "../../CompanyStore";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { useAllProduct } from "../../../api-context/commonApiContext";

const InspectPdf = ({ buttonClicked, pdfUrl, setPdfUrl }) => {
  const [items, setItems] = useState([]);
  const [imageData, setImageData] = useState({});
  const [draggables, setDraggables] = useState([]);
  const [id, setId] = useState("");
  const [doc_id, setDoc_id] = useState("");
  const containerRefs = useRef({});
  const [imageSizes, setImageSizes] = useState({}); // Store image dimensions
  const [ogDoc, setOgDoc] = useState("");
  const [data, setData] = useState();
  const [workFlowId, setWorkFlowId] = useState("");
  // const [authToken, setAuthToken] = useState("");
  const { loader, setLoader, authTokens } = useAllProduct();
  useEffect(() => {
    setId(companyStore.companyID);
    setDoc_id(companyStore.doc_id);

    setLoader(true);
    if (Object.keys(authTokens)?.length > 0) {
      axios
        .post(
          `${config.node_api_endpoint}/v1/esign/getdocument`,
          { doc_id: companyStore.doc_id },
          {
            headers: {
              aws_cognito_id_token: authTokens.idToken,
              // "Content-Type": "application/json",
            },
          },
        )
        .then((res) => {
          setLoader(false);
          setData(res.data);
          setImageData(res.data.png_url);
          setOgDoc(res.data.png_url);
          setWorkFlowId(res.data.workflowId);
          // setAuthToken(res.data.authToken);
        })
        .catch((er) => {
          setLoader(false);
          "%o", er;
        });
    }
  }, [authTokens, companyStore]);

  useEffect(() => {
    if (workFlowId) {
      setLoader(true);

      fetchDataWithRetry(workFlowId)
        .then((base64Data) => {
          setLoader(false);
          const pdfBlob = base64ToBlob(base64Data, "application/pdf");
          const pdfFileUrl = URL.createObjectURL(pdfBlob);
          setPdfUrl(pdfFileUrl);
        })
        .catch((err) => {
          setLoader(false);
          console.error("Failed after retries:", err);
        });
    }
  }, [workFlowId]);

  const MAX_RETRIES = 3;

  const fetchDataWithRetry = async (workFlowId, retries = 0) => {
    try {
      const res = await axios.post(
        `${config.node_api_endpoint}/v1/esign/DownloadWorkflowDocuments`,
        {
          workFlowId: workFlowId,
        },
        {
          headers: {
            aws_cognito_id_token: authTokens.idToken,
            // "Content-Type": "application/json",
          },
        },
      );

      if (res?.data?.Response?.length > 0) {
        return res.data.Response[0].Base64FileData;
      } else {
        throw new Error("No data in response");
      }
    } catch (error) {
      if (retries < MAX_RETRIES) {
        console.warn(`Retrying... (${retries + 1}/${MAX_RETRIES})`);
        return fetchDataWithRetry(workFlowId, retries + 1);
      } else {
        throw error; // After max retries, throw the error
      }
    }
  };

  const base64ToBlob = (base64, mimeType) => {
    const byteCharacters = atob(base64); // Decode base64 string to binary
    const byteArrays = [];

    // Create byte arrays from the binary string
    for (let offset = 0; offset < byteCharacters?.length; offset += 1024) {
      const slice = byteCharacters.slice(offset, offset + 1024);
      const byteNumbers = new Array(slice?.length);
      for (let i = 0; i < slice?.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      byteArrays.push(new Uint8Array(byteNumbers));
    }

    // Create the Blob with the PDF MIME type
    return new Blob(byteArrays, { type: mimeType });
  };

  function esStackCordinatesToDigio(x, y) {
    var elx = x;
    var ely = 1;
    var eux = y; //because tranformation matrix was derived with y in eux position by mistake
    var euy = 1;
    var c1 = 0.583297106247098;
    var c2 = 0.166639121;
    var c3 = 274.3495435;
    var c4 = -39.985987035;

    var dlx = 449.338661360347 - (300 - x) * 1.41666974191992;
    var dly = 10.0057451431493 - (540 - y) * -1.1854809;
    var dux = 589.329955680173 - (449.338661360347 - dlx);
    var duy = 49.9991308793456 - (10.0057451431493 - dly);
    return {
      lx: dlx,
      ly: dly,
      ux: dux,
      uy: duy,
    };
  }

  const handleSendClick = () => {
    console.log("in it");
    console.log(draggables);

    const updatedData = draggables
      .map((item) => {
        console.log("items ", item.x, item.y);

        let coordinate = esStackCordinatesToDigio(item.x, item.y);
        // console.log("hey");
        console.log("coordinates", coordinate);

        const llx = coordinate.lx;
        const lly = coordinate.ly;
        const urx = coordinate.ux;
        const ury = coordinate.uy;

        return {
          name: item.name,
          email: item.email,
          position: {
            llx: llx,
            lly: lly,
            urx: urx,
            ury: ury,
          },
        };
      })
      .filter((data) => data !== null);

    console.log("updated data", updatedData);
  };

  if (buttonClicked) {
    handleSendClick();
  }

  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
      {pdfUrl?.length > 0 ? (
        <div className="w-100" style={{ height: "85vh", overflow: "hidden" }}>
          <Viewer fileUrl={pdfUrl} />
        </div>
      ) : data?.status === "FAILED" ? (
        <>
          {" "}
          {Object.entries(imageData).map(([key, image]) => (
            <div key={key}>
              {/* Image tag to retrieve the natural width and height */}
              <img
                src={image}
                alt="Background"
                style={{
                  width: "100%",
                  height: "100%",
                  display: "block",
                  border: "1px solid black",
                  // marginBottom: "20px",
                  objectFit: "contain", // Ensures the image is fully visible without being cut
                }}
              />
            </div>
          ))}
        </>
      ) : (
        ""
      )}
    </Worker>
  );
};

export default InspectPdf;

import PropTypes from "prop-types";
import styles from "./EsignMain.module.css";
import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import config from "../../../service/config";
import DeleteEsignRequest from "./DeleteEsignRequest/DeleteEsignRequest";
import moment from "moment";
import {
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Paper,
  Button,
  TablePagination,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import companyStore from "../CompanyStore";
import { Spin } from "antd";
import { DatePicker } from "@mui/x-date-pickers";
import { useAllProduct } from "../../api-context/commonApiContext";
import dayjs from "dayjs";

const EsignMain = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchedByDate, setSearchedByDate] = useState([]);
  const [statusFilter, setStatusFilter] = useState("");
  const [textFilter, setTextFilter] = useState("");
  const [showModel, setShowModel] = useState(false);
  const [createdDateFilter, setCreatedDateFilter] = useState(null);
  const [docId, setdocId] = useState("");
  const [aggName, setAggName] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const { role, authTokens, refreshData, loader, setLoader, userData } = useAllProduct();
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const fetchData = async () => {
    if (authTokens && Object?.keys(authTokens)?.length > 0 && userData?.length > 0) {
      companyStore.setContractID("");
      let payload = {
        escrow_id: companyStore.escrowId,
      };
      try {
        // Fetch KYC list data
        setLoader(true);
        const signRes = await axios.post(`${config.node_api_endpoint}/v2/esign/listdocuments`, payload, {
          headers: {
            aws_cognito_id_token: authTokens.idToken,
            "Content-Type": "application/json",
          },
        });
        const signData = signRes.data.filter((data) => data.status !== "draft");

        // Fetch user data

        const users = userData;

        // console.log("res--->", users);
        // Map roles to KYC data
        const dataWithRoles = signData.map((signItem) => {
          // console.log("signItem", signItem);

          const user = users.find((u) => u.email === signItem.created_by);
          return {
            ...signItem,
            role: user ? user.auth[0].role : "Unknown",
          };
        });

        const sortedData = dataWithRoles.sort((a, b) => {
          return new Date(b.created_at) - new Date(a.created_at); // Sorting in descending order
        });
        setLoader(false);
        setData(sortedData); // Original data without roles
        setFilteredData(sortedData); // Data enriched with roles and sorted
      } catch (error) {
        setLoader(false);
        console.error("Error fetching data:", error);
      }
    }
  };
  useEffect(() => {
    if (role?.toString()?.length > 0) {
      fetchData();
    }
  }, [authTokens, showModel, userData, role]);

  useEffect(() => {
    setFilteredData([]); // Reset the filtered data to an empty array

    console.log("helllo");

    setCurrentPage(0);
    fetchData(); // Re-fetch data instead of reloading the page
  }, [refreshData]);
  const navigate = useNavigate();

  const resetFilters = () => {
    setFilteredData(data);
  };

  const applyFilters = () => {
    setTextFilter("");
    let filtered = [...data];

    if (textFilter) {
      filtered = filtered.filter((item) => item.agreement_name.toLowerCase().includes(textFilter.toLowerCase()));
    }

    if (statusFilter) {
      filtered = filtered.filter((item) => item.status === statusFilter);
    }

    if (createdDateFilter) {
      filtered = filtered.filter((item) => {
        const [datePart] = item.created_at.split(" / "); // Extract the date part before '/'
        const itemDate = new Date(datePart).toDateString(); // Convert to a comparable date format
        const filterDate = new Date(createdDateFilter).toDateString();

        return itemDate === filterDate;
      });
    }

    setSearchedByDate(filtered);
    setFilteredData(filtered);
    setCurrentPage(0);
  };

  const handleTextFilterChange = (e) => {
    const value = e.target.value;
    setTextFilter(value);

    let filtered = searchedByDate?.length > 0 ? searchedByDate : data;

    if (value) {
      filtered = filtered.filter((item) => {
        // const formattedDate = moment(item.created_at).format("DD MMM YYYY / hh:mm A");

        return (
          item.created_at.toLowerCase().includes(value.toLowerCase()) ||
          item?.agreement_name.trim()?.toLowerCase().includes(value.trim().toLowerCase()) ||
          item?.created_by.trim()?.toLowerCase().includes(value.trim().toLowerCase()) ||
          item?.role[0].trim()?.toLowerCase().includes(value.trim().toLowerCase()) ||
          item?.status.trim()?.toLowerCase().includes(value.trim().toLowerCase())
        );
      });
    }

    setFilteredData(filtered);
    if (!value) setFilteredData(filtered);
    setCurrentPage(0);
  };

  const handlePaginationChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };
  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const formatDate = (isoDate) => {
    const date = new Date(isoDate);

    // Extract date, month, and year
    const day = date.getDate(); // Returns the day (27)
    const month = date.toLocaleString("en-US", { month: "short" }); // Returns "Jan"
    const year = date.getFullYear(); // Returns 2025

    // Extract time in 12-hour format with am/pm
    const hours = date.getHours() % 12 || 12; // Converts 24-hour to 12-hour format
    const minutes = date.getMinutes().toString().padStart(2, "0"); // Ensures 2-digit minutes
    const period = date.getHours() >= 12 ? "pm" : "am"; // Adds am/pm

    // Combine everything into the desired format
    return `${day} ${month} ${year} / ${hours}:${minutes} ${period}`;
  };
  const startIndex = (currentPage - 1) * pageSize;
  // const currentPageData = filteredData.slice(startIndex, startIndex + pageSize);
  const currentPageData = filteredData.slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage);

  console.log("test-->", data);

  return (
    <div className={`${styles.pageContainer} ${styles.family}  h-100 w-100`}>
      <Spin
        size="large"
        tip="Loading..."
        spinning={loader}
        className="d-flex justify-content-center align-items-center w-100 h-100"
        wrapperClassName="spin-nested-loading"
        style={{ maxHeight: "none" }}
      >
        {/* <div className={styles.frameParent}> */}
        <div className="d-flex justify-content-between">
          <b
            className={`${styles.escrowName} mt-2 mb-2`}
            title={companyStore.escrowName.length > 50 ? companyStore.escrowName : ""}
          >
            {companyStore.escrowName.length > 50
              ? companyStore.escrowName.slice(0, 50) + "..."
              : companyStore.escrowName}
          </b>

          <button
            className={`${styles.loadsButtons} ${role.toString() === "Owner" || role.toString() === "Super Admin" || role.toString() === "Admin" || role.toString() === "Maker" ? "" : styles.disabledDiv}`}
            onClick={() => {
              navigate(`/content/esign/createesign`);
            }}
          >
            <img className={styles.plusCircleIcon} alt="" src="/pluscircle2.svg" />
            <b className={styles.newKycRequest}>New E-sign Request</b>
          </button>
        </div>
        <div className={`${styles.frameWrapper} p-2 mt-2 mb-2`}>
          {/* <div className={`${styles.frameContainer} px-2`}> */}
          <div className={"w-100 d-flex justify-content-center align-items-center"}>
            <div className={"w-25"}>
              <div className="d-flex">
                <img className={styles.leadingIcon} alt="" src="/leadingicon3.svg" />
                <TextField label="Search" variant="standard" value={textFilter} onChange={handleTextFilterChange} />
              </div>
            </div>

            <div className={`${styles.mobileMenuParent} w-75 d-flex justify-content-end `}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Created Date"
                  value={createdDateFilter}
                  maxDate={dayjs()}
                  onChange={(newValue) => setCreatedDateFilter(newValue)}
                  renderInput={(params) => <TextField {...params} />}
                  sx={{
                    "& .MuiInputBase-root": {
                      // width: "250px",
                      borderRadius: "12px",
                    },
                  }}
                />
              </LocalizationProvider>

              <FormControl sx={{ width: "250px" }}>
                <InputLabel id="escrow-select-label">Status</InputLabel>
                <Select
                  labelId="escrow-select-label"
                  id="escrow-select"
                  value={statusFilter}
                  label="Status"
                  onChange={(e) => setStatusFilter(e.target.value)}
                  sx={{ borderRadius: "12px" }}
                >
                  <MenuItem value={"draft"}>Draft</MenuItem>
                  <MenuItem value={"REQUESTED"}>Requested</MenuItem>
                  <MenuItem value={"Completed"}>Completed</MenuItem>
                  <MenuItem value={"Pending"}>Pending</MenuItem>
                  <MenuItem value={"expired"}>Expired</MenuItem>
                </Select>
              </FormControl>

              {/* Button to apply filter */}
              <div className={styles.loadsButtons1} onClick={applyFilters}>
                <b className={styles.escrowstackPrivateLimited}>Apply</b>
              </div>
            </div>
          </div>
        </div>
        <Paper>
          <TableContainer>
            <Table>
              <TableHead
                className={styles.tableBg}
                sx={{
                  "& .MuiTableCell-root": {
                    padding: "16px",
                  },
                }}
              >
                <TableRow>
                  <TableCell align="center">Name Of Agreement</TableCell>
                  <TableCell align="center">Last Updated</TableCell>
                  <TableCell align="center">Created By</TableCell>
                  <TableCell align="center">Role</TableCell>
                  <TableCell align="center">Status</TableCell>
                  <TableCell align="center">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {currentPageData.length > 0 ? (
                  currentPageData.map((detail, index) => (
                    <TableRow key={index} sx={{ "& .MuiTableCell-root": { padding: "8px" } }}>
                      <TableCell align="center">{detail.agreement_name}</TableCell>
                      <TableCell align="center">{moment(detail.created_at).format("DD MMM YYYY / hh:mm A")}</TableCell>
                      <TableCell align="center">{detail.created_by?.length > 0 ? detail.created_by : "N/A"}</TableCell>
                      <TableCell align="center">{detail.role?.length > 0 ? detail.role.toString() : "N/A"}</TableCell>
                      <TableCell align="center">{detail?.status?.toUpperCase() || ""}</TableCell>
                      <TableCell align="center">
                        <Button
                          className={styles.btn} /*view Request Button*/
                          onClick={() => {
                            console.log("det--->", detail);

                            setdocId(detail._id);
                            companyStore.setDoc_id(detail._id);
                            navigate(`/content/esign/preview/doc`);
                          }}
                          //[0] is docid, [1] is company id
                        >
                          <img className={styles.eyeIcon} alt="" src="/eye.svg" />
                        </Button>
                        <b className={styles.b}>/</b>
                        <Button
                          className={styles.btn} /*Delete Request Button*/
                          onClick={() => {
                            setShowModel(true);
                            setdocId(detail._id);
                            setAggName(detail.agreement_name);
                            companyStore.setDoc_id(detail._id);
                          }}
                        >
                          <img className={styles.eyeIcon} alt="" src="/trash-01.svg" />
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={6} align="center">
                      No Records Found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            className={`${styles.tableBg}`}
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredData.length}
            rowsPerPage={rowsPerPage}
            page={currentPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{
              "& .MuiTablePagination-selectLabel": {
                marginTop: "14px",
              },
              "& .MuiTablePagination-displayedRows": {
                marginTop: "14px",
              },
            }}
          />
        </Paper>
        {/* </div> */}
      </Spin>
      <DeleteEsignRequest
        isVisible={showModel}
        onclose={() => {
          setShowModel(false);
          // window.location.reload();
        }}
        Docid={docId}
        ag_name={aggName}
      />
    </div>
  );
};

EsignMain.propTypes = {
  className: PropTypes.string,
};

export default EsignMain;

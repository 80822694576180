import { useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import styles from "./CompanyKycDetail.module.css";
import { useNavigate, useParams } from "react-router-dom";
import { Fragment, useState } from "react";
import CompanyApproved from "./CompanyPopupConfirmation/CompanyApproved";
import CompanyRejected from "./CompanyPopupConfirmation/CompanyRejected";
import axios from "axios";
import config from "../../../../service/config";
import companyStore from "../../CompanyStore";
import { useAllProduct } from "../../../api-context/commonApiContext";

const CompanyKycDetail = ({ className = "" }) => {
  const [showApprove, setShowApprove] = useState(false);
  const [showReject, setShowReject] = useState(false);
  const [data, setData] = useState([]);
  const [isRejectVisible, setRejectVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const { role, authTokens } = useAllProduct();

  const navigate = useNavigate();
  // const compId=id.split('_')[1];
  const onclose = () => {
    setShowApprove(false);
    setShowReject(false);
  };
  const handlenavi = () => {
    navigate(`/content/company/kyclist/main`);
  };
  const onFrameButtonClick = useCallback(() => {
    // Please sync "Company List & Director List" to the project
  }, []);

  const onLoadsButtonsClick = useCallback(() => {
    // Please sync "Reject Pop-up" to the project
  }, []);

  const onLoadsButtonsClick1 = useCallback(() => {
    // Please sync "Approve Pop-up" to the project
  }, []);
  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: smooth scrolling
    });
  };
  useEffect(() => {
    console.log("kycID", companyStore.KYBId);

    if (authTokens && Object?.keys(authTokens)?.length > 0) {
      axios
        .get(`${config.node_api_endpoint}/v1/company/kyb?id=${companyStore.KYBId}`, {
          headers: {
            aws_cognito_id_token: authTokens.idToken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          console.log("data-->", res.data);
          setData(res.data);
          if (res.data.status == "PENDING_APPROVAL") {
            setRejectVisible(true);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [authTokens]);

  const handleDocumentDownload = (url) => {
    const downloadUrl = `${url}?download=true`; // Append the query param
    const a = document.createElement("a");
    a.href = downloadUrl;
    a.download = url.split("/").pop(); // Extract filename from URL
    a.style.display = "none";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  // console.log("data",data.pan_info.full_name);

  // function getAddressFromCINorGST(companyInfoJson) {
  //   if (!companyInfoJson?.cin_info) {
  //     return "Not Provided";
  //   } else if (companyInfoJson?.cin_info?.company_data["Registered Address"]) {
  //     return formatAddress(
  //       companyInfoJson?.cin_info?.company_data["Registered Address"][
  //         companyInfoJson?.cin_info["Registered Address"].length - 1
  //       ],
  //     );
  //   } else if (!companyInfoJson?.gst_info) {
  //     return "Not Provided";
  //   } else if (companyInfoJson?.gst_info?.details?.pradr?.addr) {
  //     var addr = companyInfoJson?.gst_info?.details?.pradr.addr;
  //     // return `${addr.bno},${addr.st},${addr.loc},${addr.dst},${addr.stcd},${addr.pncd}`
  //   }
  //   return "Not Provided";
  // }
  function formatAddress(addressJson) {
    var keys = Object.keys(addressJson);
    var addressStr = "";
    for (var i = 0; i < keys.length - 2; i++) {
      if (addressJson[keys[i]] == "" || keys[i] == "activeStatus" || keys[i] == "Registered Address") {
        continue;
      } else {
        addressStr += addressJson[keys[i]] + ",";
      }
    }
    return addressStr;
  }

  function cinName(data) {
    if (!data?.cin_info) {
      return "Not Provided";
    }

    try {
      // Parse cin_info into an object
      const parsedCinInfo = JSON.parse(data.cin_info);

      // Check if company_data exists and return company_name
      if (parsedCinInfo?.company_data?.company_name) {
        return parsedCinInfo.company_data.company_name;
      } else {
        return "Not Provided";
      }
    } catch (error) {
      // Handle JSON parse errors gracefully
      console.error("Error parsing cin_info:", error);
      return "Not Provided";
    }
  }

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const itemsPerPage = 5;
  const totalPages = Math.ceil(data?.cin_info?.company_directors?.length / itemsPerPage);
  console.log("verify", data);

  return (
    <Fragment>
      {data.mode === "online" ? (
        <div className={`${styles.pageContainer} ${styles.family}  h-100 w-100`}>
          <div className={`${styles.frameParent} w-100`}>
            <button
              className={styles.arrowNarrowLeftParent}
              onClick={() => {
                handlenavi();
              }}
            >
              <img className={styles.arrowNarrowLeftIcon} alt="" src="/arrownarrowleft1.svg" />
              <div className={styles.back}>Back</div>
            </button>
            <div className={"mt-2"}>
              <div className={`${styles.escrowName}`}>{companyStore?.escrowName}</div>
              <div className={`${styles.compName} mt-1`}>{companyStore?.companyName}</div>
            </div>
            <div className={styles.frameGroup}>
              <div className={styles.frameContainer}>
                <div className={styles.companyKycDetailsWrapper}>
                  <div className={styles.companyKycDetails}>Company KYC Details</div>
                </div>
                <div className={styles.frameDiv}>
                  <div className={styles.frameDiv}>
                    <div className={styles.companyNameWrapper}>
                      <div className={styles.companyKycDetails}>Submitted By</div>
                    </div>
                    <div className={styles.div}>:</div>
                  </div>
                  <div className={styles.escrowstackPrivateLimited}>
                    {/* {data?.original_data?.authorized_signatory_email || "NA"} */}
                    {data?.created_by || "NA"}
                  </div>
                </div>
                <div className={styles.frameDiv}>
                  <div className={styles.frameDiv}>
                    <div className={styles.companyNameWrapper}>
                      <div className={styles.companyKycDetails}>Company Name</div>
                    </div>
                    <div className={styles.div}>:</div>
                  </div>
                  <div className={styles.escrowstackPrivateLimited}>{data?.original_data?.company_name || "NA"}</div>
                </div>
                <div className={styles.frameDiv}>
                  <div className={styles.frameDiv}>
                    <div className={styles.companyNameWrapper}>
                      <div className={styles.companyKycDetails}>Authorized signatory</div>
                    </div>
                    <div className={styles.div}>:</div>
                  </div>
                  <div className={styles.escrowstackPrivateLimited}>
                    {data?.original_data?.authorized_signatory || "NA"}
                  </div>
                </div>
                <div className={styles.frameDiv}>
                  <div className={styles.frameDiv}>
                    <div className={styles.companyNameWrapper}>
                      <div className={styles.companyKycDetails}>Email ID</div>
                    </div>
                    <div className={styles.div}>:</div>
                  </div>
                  <div className={styles.escrowstackPrivateLimited}>
                    {data?.original_data?.authorized_signatory_email || "NA"}
                  </div>
                </div>
                <div className={styles.frameDiv}>
                  <div className={styles.frameDiv}>
                    <div className={styles.companyNameWrapper}>
                      <div className={styles.companyKycDetails}>Remark</div>
                    </div>
                    <div className={styles.div}>:</div>
                  </div>
                  <div className={styles.escrowstackPrivateLimited}>{data?.original_data?.remark || "NA"}</div>
                </div>
              </div>
              <div className={`${styles.frameParent8} mt-3`}>
                <div className={styles.companyKycResponseWrapper}>
                  <b className={styles.companyKycResponse}>{`Company Kyc Response `}</b>
                </div>
                {/* ////////////////////////////////////////////////////////////////////////// */}
                <div className={`${styles.frameParent9} w-100 mt-2`}>
                  {!isRejectVisible && data.status === "rejected" && (
                    <div className={`${styles.status} `}>
                      <div className={`${styles.statusHeading}`}> {data?.status?.toUpperCase()} </div>
                      <div>
                        <span style={{ color: "#4C4E64", fontWeight: "700" }}>Reason for reject:</span>
                        <span style={{ color: "#4C4E64", fontWeight: "400" }}> {data.rejected_reason}</span>
                      </div>
                    </div>
                  )}

                  {!isRejectVisible && data.status === "approved" && (
                    <div className={`${styles.statusapproved} mt-2`}>
                      <div className={styles.statusHeading}>{data?.status?.toUpperCase()}</div>
                      {/* <div>{data.reason}</div> */}
                    </div>
                  )}

                  <div className={styles.frameDiv}>
                    <div className={styles.frameDiv}>
                      <div className={styles.companyNameWrapper}>
                        <div className={styles.companyKycDetails}>PAN</div>
                      </div>
                      <div className={styles.div}>:</div>
                    </div>
                    <div className={styles.escrowstackPrivateLimited}>{data?.PAN || "Not Provided"}</div>
                  </div>
                  <div className={styles.frameDiv}>
                    <div className={styles.frameDiv}>
                      <div className={styles.companyNameWrapper}>
                        <div className={styles.companyKycDetails}>Name as per PAN</div>
                      </div>
                      <div className={styles.div}>:</div>
                    </div>

                    <div className={styles.escrowstackPrivateLimited}>
                      {data?.pan_info ? JSON.parse(data.pan_info).full_name : "Not Provided"}
                    </div>
                  </div>
                  <div className={styles.frameDiv}>
                    <div className={styles.frameDiv}>
                      <div className={styles.companyNameWrapper}>
                        <div className={styles.companyKycDetails}>CIN</div>
                      </div>
                      <div className={styles.div}>:</div>
                    </div>
                    <div className={styles.escrowstackPrivateLimited}>{data?.CIN || "Not Provided"}</div>
                  </div>
                  <div className={styles.frameDiv}>
                    <div className={styles.frameDiv}>
                      <div className={styles.companyNameWrapper}>
                        <div className={styles.companyKycDetails}>Name as per CIN</div>
                      </div>
                      <div className={styles.div}>:</div>
                    </div>
                    <div className={styles.escrowstackPrivateLimited}>{cinName(data)}</div>
                  </div>
                  <div className={styles.frameDiv}>
                    <div className={styles.frameDiv}>
                      <div className={styles.companyNameWrapper}>
                        <div className={styles.companyKycDetails}>GST.NO</div>
                      </div>
                      <div className={styles.div}>:</div>
                    </div>
                    <div className={styles.escrowstackPrivateLimited}>{data?.GST || "Not Provided"}</div>
                  </div>
                  <div className={styles.frameDiv}>
                    <div className={styles.frameDiv}>
                      <div className={styles.companyNameWrapper}>
                        <div className={styles.companyKycDetails}>Name as per GST</div>
                      </div>
                      <div className={styles.div}>:</div>
                    </div>
                    <div className={styles.escrowstackPrivateLimited}>
                      {data?.gst_info ? JSON.parse(data.gst_info).corporate_name : "Not Provided"}
                    </div>
                  </div>

                  <div className={styles.frameDiv}>
                    <div className={styles.frameDiv}>
                      <div className={styles.companyNameWrapper}>
                        <div className={styles.companyKycDetails}>Registered Address</div>
                      </div>
                      <div className={styles.div}>:</div>
                    </div>
                    {/* <div className={styles.escrowstackPrivateLimited}>{getAddressFromCINorGST(data)}</div> */}
                    <div className={styles.escrowstackPrivateLimited}>
                      {data?.cin_info ? JSON.parse(data?.cin_info)?.company_data?.registered_address : "Not Provided"}
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-around">
                  <div style={{ paddingRight: "28px" }} className={styles.companyKycDetails}>
                    Documents
                  </div>
                  <div className={`${styles.div} pe-3`}>:</div>
                  <div>
                    {" "}
                    {data?.documents?.jpgUrls?.map((data) => {
                      return (
                        <div className="pb-2">
                          {" "}
                          <a className={`${styles.docName} pe-2`} target="blank" href={data}>
                            {data?.split("/").pop() || "NA"}
                          </a>{" "}
                          <img
                            src="/downloadIcon.svg"
                            style={{ cursor: "pointer" }}
                            onClick={() => handleDocumentDownload(data)}
                          />
                        </div>
                      );
                    })}
                    {data?.documents?.pdfUrl?.map((data) => {
                      return (
                        <div className="pb-2">
                          {" "}
                          <a className={`${styles.docName} pe-2`} target="blank" href={data}>
                            {data?.split("/").pop() || "NA"}
                          </a>{" "}
                          <img
                            src="/downloadIcon.svg"
                            style={{ cursor: "pointer" }}
                            onClick={() => handleDocumentDownload(data)}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className={`${styles.detailContainer} mt-3 mb-3`}>
                  <div className={styles.detailHeader}>Director details</div>
                  <table className={styles.table}>
                    <thead className={styles.thead}>
                      <tr className={styles.tr}>
                        <th className={styles.th}>No</th>
                        <th className={styles.th}>Name</th>
                        <th className={styles.th}>Din.No</th>
                        <th className={styles.th}>Designation</th>
                      </tr>
                    </thead>
                    <tbody className={styles.tbody}>
                      {data.cin_info ? (
                        JSON.parse(data.cin_info).company_directors?.map((detail, index) => (
                          <tr className={styles.tr} key={index}>
                            <td className={styles.td}>{index + 1}</td>
                            <td className={styles.td}>{detail?.name}</td>
                            <td className={styles.td}>{detail?.din}</td>
                            <td className={styles.td}>{detail?.designation}</td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="4" className={styles.noRecords}>
                            No Records Found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  <div className={styles.pagination}>
                    {Array.from({ length: totalPages }, (_, index) => (
                      <button
                        key={index + 1}
                        className={`${styles.pageButton} ${currentPage === index + 1 ? styles.active : ""}`}
                        onClick={() => handlePageChange(index + 1)}
                      >
                        {index + 1}
                      </button>
                    ))}
                  </div>
                </div>
                {isRejectVisible && (
                  <div className={`${styles.frameDiv} mt-2`}>
                    <div
                      className={`${role.toString() === "Owner" || role.toString() === "Super Admin" || role.toString() === "Admin" || role.toString() === "Approver" ? "" : styles.disabledDiv}`}
                    >
                      <button
                        className={styles.loadsButtons}
                        onClick={() => {
                          handleScrollToTop();
                          setShowReject(true);
                          setShowApprove(false);
                        }}
                      >
                        <b className={styles.reject}>Reject</b>
                      </button>
                    </div>
                    <div
                      className={`${role.toString() === "Owner" || role.toString() === "Super Admin" || role.toString() === "Admin" || role.toString() === "Approver" ? "" : styles.disabledDiv}`}
                    >
                      <button
                        className={styles.loadsButtons1}
                        onClick={() => {
                          handleScrollToTop();
                          setShowApprove(true);
                          setShowReject(false);
                        }}
                      >
                        <b className={styles.reject}>Approve</b>
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className={styles.spacing}></div>
        </div>
      ) : (
        <div className={`${styles.pageContainer} ${styles.family}  h-100 w-100`}>
          <div className={`${styles.frameParent} w-100`}>
            <button
              className={styles.arrowNarrowLeftParent}
              onClick={() => {
                handlenavi();
              }}
            >
              <img className={styles.arrowNarrowLeftIcon} alt="" src="/arrownarrowleft1.svg" />
              <div className={styles.back}>Back</div>
            </button>
            <div className={"mt-2"}>
              <div className={`${styles.escrowName}`}>{companyStore?.escrowName}</div>
              <div className={`${styles.compName} mt-1`}>{companyStore?.companyName}</div>
            </div>
            <div className={styles.frameGroup}>
              <div className={styles.frameContainer}>
                <div className={styles.companyKycDetailsWrapper}>
                  <div className={styles.companyKycDetails}>Company KYC Details</div>
                </div>
                <div className={styles.frameDiv}>
                  <div className={styles.frameDiv}>
                    <div className={styles.companyNameWrapper}>
                      <div className={styles.companyKycDetails}>Submitted By</div>
                    </div>
                    <div className={styles.div}>:</div>
                  </div>
                  <div className={styles.escrowstackPrivateLimited}>{data?.created_by || "NA"}</div>
                </div>
                <div className={styles.frameDiv}>
                  <div className={styles.frameDiv}>
                    <div className={styles.companyNameWrapper}>
                      <div className={styles.companyKycDetails}>CIN</div>
                    </div>
                    <div className={styles.div}>:</div>
                  </div>
                  <div className={styles.escrowstackPrivateLimited}>{data?.CIN || "NA"}</div>
                </div>
                <div className={styles.frameDiv}>
                  <div className={styles.frameDiv}>
                    <div className={styles.companyNameWrapper}>
                      <div className={styles.companyKycDetails}>PAN</div>
                    </div>
                    <div className={styles.div}>:</div>
                  </div>
                  <div className={styles.escrowstackPrivateLimited}>{data?.PAN || "NA"}</div>
                </div>
                <div className={styles.frameDiv}>
                  <div className={styles.frameDiv}>
                    <div className={styles.companyNameWrapper}>
                      <div className={styles.companyKycDetails}>GSTIN</div>
                    </div>
                    <div className={styles.div}>:</div>
                  </div>
                  <div className={styles.escrowstackPrivateLimited}>{data?.GST || "NA"}</div>
                </div>
                <div className={styles.frameDiv}>
                  <div className={styles.frameDiv}>
                    <div className={styles.companyNameWrapper}>
                      <div className={styles.companyKycDetails}>Remarks</div>
                    </div>
                    <div className={styles.div}>:</div>
                  </div>
                  <div className={styles.escrowstackPrivateLimited}>{data?.remark || "NA"}</div>
                </div>
                {data.cin_info ? (
                  <div className={`${styles.frameParent8} mt-3`}>
                    <div className={styles.companyKycResponseWrapper}>
                      <b className={styles.companyKycResponse}>{`Company Kyc Response `}</b>
                    </div>
                    {/* ////////////////////////////////////////////////////////////////////////// */}
                    <div className={`${styles.frameParent9} w-100 mt-2`}>
                      {!isRejectVisible && data.status === "rejected" && (
                        <div className={`${styles.status}`}>
                          <div className={styles.statusHeading}> {data?.status?.toUpperCase()} </div>
                          <div>
                            <span style={{ color: "#4C4E64", fontWeight: "700" }}>Reason for reject:</span>
                            <span style={{ color: "#4C4E64", fontWeight: "400" }}> {data.rejected_reason}</span>
                          </div>
                        </div>
                      )}

                      {!isRejectVisible && data.status === "approved" && (
                        <div className={styles.statusapproved}>
                          <div className={styles.statusHeading}>{data?.status?.toUpperCase()}</div>
                          {/* <div>{data.reason}</div> */}
                        </div>
                      )}

                      <div className={styles.frameDiv}>
                        <div className={styles.frameDiv}>
                          <div className={styles.companyNameWrapper}>
                            <div className={styles.companyKycDetails}>PAN</div>
                          </div>
                          <div className={styles.div}>:</div>
                        </div>
                        <div className={styles.escrowstackPrivateLimited}>{data?.PAN || "Not Provided"}</div>
                      </div>
                      <div className={styles.frameDiv}>
                        <div className={styles.frameDiv}>
                          <div className={styles.companyNameWrapper}>
                            <div className={styles.companyKycDetails}>Name as per PAN</div>
                          </div>
                          <div className={styles.div}>:</div>
                        </div>

                        <div className={styles.escrowstackPrivateLimited}>
                          {JSON.parse(data?.pan_info)?.full_name || "Not Provided"}
                        </div>
                      </div>
                      <div className={styles.frameDiv}>
                        <div className={styles.frameDiv}>
                          <div className={styles.companyNameWrapper}>
                            <div className={styles.companyKycDetails}>CIN</div>
                          </div>
                          <div className={styles.div}>:</div>
                        </div>
                        <div className={styles.escrowstackPrivateLimited}>{data?.CIN || "Not Provided"}</div>
                      </div>
                      <div className={styles.frameDiv}>
                        <div className={styles.frameDiv}>
                          <div className={styles.companyNameWrapper}>
                            <div className={styles.companyKycDetails}>Name as per CIN</div>
                          </div>
                          <div className={styles.div}>:</div>
                        </div>
                        <div className={styles.escrowstackPrivateLimited}>{cinName(data)}</div>
                      </div>
                      <div className={styles.frameDiv}>
                        <div className={styles.frameDiv}>
                          <div className={styles.companyNameWrapper}>
                            <div className={styles.companyKycDetails}>GST.NO</div>
                          </div>
                          <div className={styles.div}>:</div>
                        </div>
                        <div className={styles.escrowstackPrivateLimited}>{data?.GST || "Not Provided"}</div>
                      </div>
                      <div className={styles.frameDiv}>
                        <div className={styles.frameDiv}>
                          <div className={styles.companyNameWrapper}>
                            <div className={styles.companyKycDetails}>Name as per GST</div>
                          </div>
                          <div className={styles.div}>:</div>
                        </div>
                        <div className={styles.escrowstackPrivateLimited}>
                          {JSON.parse(data?.gst_info)?.corporate_name || "Not Provided"}
                        </div>
                      </div>
                      {/* <div className={styles.frameDiv}>
                <div className={styles.frameDiv}>
                  <div className={styles.companyNameWrapper}>
                    <div className={styles.companyKycDetails}>Company Name</div>
                  </div>
                  <div className={styles.div}>:</div>
                </div>
                <div
                  className={styles.escrowstackPrivateLimited}
                >{`Escrow stack Private Limited `}</div>
              </div> */}
                      {/* <div className={styles.frameDiv}>
                <div className={styles.frameDiv}>
                  <div className={styles.companyNameWrapper}>
                    <div className={styles.companyKycDetails}>
                      Date of incorpation
                    </div>
                  </div>
                  <div className={styles.div}>:</div>
                </div>
                <div className={styles.escrowstackPrivateLimited}>
                  NA
                </div>
              </div> */}
                      <div className={styles.frameDiv}>
                        <div className={styles.frameDiv}>
                          <div className={styles.companyNameWrapper}>
                            <div className={styles.companyKycDetails}>Registered Address</div>
                          </div>
                          <div className={styles.div}>:</div>
                        </div>
                        {/* <div className={styles.escrowstackPrivateLimited}>{getAddressFromCINorGST(data)}</div> */}
                        <div className={styles.escrowstackPrivateLimited}>
                          {JSON.parse(data?.cin_info)?.company_data?.registered_address
                            ? JSON.parse(data?.cin_info).company_data?.registered_address
                            : "Not Provided"}
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-around">
                      <div style={{ paddingRight: "28px" }} className={styles.companyKycDetails}>
                        Documents
                      </div>
                      <div className={`${styles.div} pe-3`}>:</div>
                      <div>
                        {" "}
                        {data?.documents?.jpgUrls?.map((data) => {
                          return (
                            <div className="pb-2">
                              {" "}
                              <a className={`${styles.docName} pe-2`} target="blank" href={data}>
                                {data?.split("/").pop() || "NA"}
                              </a>{" "}
                              <img
                                src="/downloadIcon.svg"
                                style={{ cursor: "pointer" }}
                                onClick={() => handleDocumentDownload(data)}
                              />
                            </div>
                          );
                        })}
                        {data?.documents?.pdfUrl?.map((data) => {
                          return (
                            <div className="pb-2">
                              {" "}
                              <a className={`${styles.docName} pe-2`} target="blank" href={data}>
                                {data?.split("/").pop() || "NA"}
                              </a>{" "}
                              <img
                                src="/downloadIcon.svg"
                                style={{ cursor: "pointer" }}
                                onClick={() => handleDocumentDownload(data)}
                              />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <div className={styles.detailContainer}>
                      <div className={styles.detailHeader}>Director details</div>
                      <table className={styles.table}>
                        <thead className={styles.thead}>
                          <tr className={styles.tr}>
                            <th className={styles.th}>No</th>
                            <th className={styles.th}>Name</th>
                            <th className={styles.th}>Din.No</th>
                            <th className={styles.th}>Designation</th>
                          </tr>
                        </thead>
                        <tbody className={styles.tbody}>
                          {data.cin_info ? (
                            JSON.parse(data.cin_info).company_directors?.map((detail, index) => (
                              <tr className={styles.tr} key={index}>
                                <td className={styles.td}>{index + 1}</td>
                                <td className={styles.td}>
                                  {detail?.name?.length > 0 ? detail?.name : "NOT PROVIDED"}
                                </td>
                                <td className={styles.td}>{detail?.din?.length > 0 ? detail?.din : "NOT PROVIDED"}</td>
                                <td className={styles.td}>
                                  {detail?.designation?.length > 0 ? detail?.designation : "NOT PROVIDED"}
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="4" className={`${styles.noRecords} d-flex justify-content-center`}>
                                No Records Found
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                      <div className={styles.pagination}>
                        {Array.from({ length: totalPages }, (_, index) => (
                          <button
                            key={index + 1}
                            className={`${styles.pageButton} ${currentPage === index + 1 ? styles.active : ""}`}
                            onClick={() => handlePageChange(index + 1)}
                          >
                            {index + 1}
                          </button>
                        ))}
                      </div>
                    </div>
                    {isRejectVisible && (
                      <div className={`${styles.frameDiv} mt-3`}>
                        <div
                          className={`${role.toString() === "Owner" || role.toString() === "Super Admin" || role.toString() === "Admin" || role.toString() === "Approver" ? "" : styles.disabledDiv}`}
                        >
                          <button
                            className={styles.loadsButtons}
                            disabled={
                              role.toString() === "Owner" ||
                              role.toString() === "Super Admin" ||
                              role.toString() === "Admin" ||
                              role.toString() === "Approver"
                                ? false
                                : true
                            }
                            onClick={() => {
                              handleScrollToTop();
                              setShowReject(true);
                              setShowApprove(false);
                            }}
                          >
                            <b className={styles.reject}>Reject</b>
                          </button>
                        </div>
                        <div
                          className={`${role.toString() === "Owner" || role.toString() === "Super Admin" || role.toString() === "Admin" || role.toString() === "Approver" ? "" : styles.disabledDiv}`}
                        >
                          <button
                            className={styles.loadsButtons1}
                            disabled={
                              role.toString() === "Owner" ||
                              role.toString() === "Super Admin" ||
                              role.toString() === "Admin" ||
                              role.toString() === "Approver"
                                ? false
                                : true
                            }
                            onClick={() => {
                              handleScrollToTop();
                              setShowApprove(true);
                              setShowReject(false);
                            }}
                          >
                            <b className={styles.reject}>Approve</b>
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="d-flex">
                    <div className={styles.companyKycDetails}>Documents</div>
                    <div className={``} style={{ paddingLeft: "90px", paddingRight: "12px" }}>
                      :
                    </div>
                    <div className="">
                      {" "}
                      {data?.documents?.jpgUrls?.map((data) => {
                        return (
                          <div className="pb-2">
                            <a
                              className={`${styles.docName} pe-2`}
                              target="_blank"
                              rel="noopener noreferrer"
                              href={data}
                            >
                              {data?.split("/").pop() || "NA"}
                            </a>{" "}
                            <img
                              src="/downloadIcon.svg"
                              style={{ cursor: "pointer" }}
                              onClick={() => handleDocumentDownload(data)}
                            />
                          </div>
                        );
                      })}
                      {data?.documents?.pdfUrl?.map((data) => {
                        return (
                          <div className="pb-2">
                            {" "}
                            <a className={`${styles.docName} pe-2`} target="blank" href={data}>
                              {data?.split("/").pop() || "NA"}
                            </a>{" "}
                            <img
                              src="/downloadIcon.svg"
                              style={{ cursor: "pointer" }}
                              onClick={() => handleDocumentDownload(data)}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className={styles.spacing}></div>
        </div>
      )}
      <CompanyApproved isVisible={showApprove} onclose={onclose} />
      <CompanyRejected isVisible={showReject} onclose={onclose} />
    </Fragment>
  );
};

CompanyKycDetail.propTypes = {
  className: PropTypes.string,
};

export default CompanyKycDetail;

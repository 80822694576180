import React, { Fragment, useCallback, useEffect, useState } from "react";
import Sign from "../Sign/Sign";
import axios from "axios";
import styles from "./SignDoc.module.css";
import { useNavigate } from "react-router-dom";
import config from "../../../../service/config";
import companyStore from "../../CompanyStore";
import { Spin } from "antd";
import { useAllProduct } from "../../../api-context/commonApiContext";

export default function SignDoc() {
  const { loader, authTokens, setLoader } = useAllProduct();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [id, setId] = useState("");
  const [error, setError] = useState("");
  const [clicked, setClicked] = useState(null);
  const [isChecked, setIsChecked] = useState(false);
  const [removedPlaceholders, setRemovedPlaceholders] = useState([]);
  const [replaceTrigger, setReplaceTrigger] = useState(false); // Trigger to re-add placeholders

  useEffect(() => {
    if (Object.keys(authTokens)?.length > 0) {
      setLoader(true);
      setId(companyStore.companyID);
      const doc_id = companyStore.doc_id;
      console.log("previewing documents");
      axios
        .post(
          `${config.node_api_endpoint}/v1/esign/getdocument`,
          { doc_id },
          {
            headers: {
              aws_cognito_id_token: authTokens.idToken,
            },
          },
        )
        .then((res) => {
          setLoader(false);
          setData(res.data.signing_parties);
        });
    }
  }, [authTokens]);

  const handlenavi = () => {
    navigate(`/content/esign/createesign`);
  };

  const handleSwitchChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const handlePlaceholderRemoved = (removedItem) => {
    setRemovedPlaceholders((prev) => [...prev, removedItem]);
  };

  const replacePlaceholder = () => {
    console.log("Removed Placeholders to be re-added:", removedPlaceholders);
    if (removedPlaceholders.length > 0) {
      setReplaceTrigger(true); // Trigger re-addition in Sign
    }
    // Optionally clear removedPlaceholders after re-adding
    // setRemovedPlaceholders([]);
  };

  // Reset replaceTrigger after it’s been processed
  useEffect(() => {
    if (replaceTrigger) {
      setReplaceTrigger(false);
    }
  }, [replaceTrigger]);

  return (
    <Fragment>
      <div className={styles.maindiv}>
        <Spin
          size="large"
          tip="Loading..."
          spinning={loader}
          className="d-flex justify-content-center align-items-center w-100 h-100"
          style={{ maxHeight: "none" }}
        >
          <div className={`d-flex mb-2`}>
            <img
              className={`${styles.arrowNarrowLeftIcon} ${styles.cursorIcon} pe-2`}
              onClick={handlenavi}
              alt=""
              src="/arrownarrowleft.svg"
            />
            <div className={`${styles.back} ${styles.cursorIcon}`} onClick={handlenavi}>
              Back
            </div>
          </div>
          <div className={"d-flex justify-content-between mb-4 mt-3 w-75"}>
            <div
              className={`${styles.escrowName}`}
              title={companyStore.escrowName.length > 50 ? companyStore.escrowName : ""}
            >
              {companyStore.escrowName.length > 50
                ? companyStore.escrowName.slice(0, 50) + "..."
                : companyStore.escrowName}
            </div>
            <button className={`${styles.placeholder} p-2`} onClick={replacePlaceholder}>
              {"Replace Placeholder"}
            </button>
          </div>
          <div className={`${styles.containAll}`}>
            <div className={`${styles.scrollableContainer} w-100`}>
              <Sign
                button={clicked}
                reason={isChecked ? "E-SignWithE-Stamp" : "E-Sign"}
                onPlaceholderRemoved={handlePlaceholderRemoved}
                removedPlaceholders={removedPlaceholders} // Pass removed placeholders
                replaceTrigger={replaceTrigger} // Pass trigger to re-add
                signatories={data}
                setError={setError}
              />
            </div>
            <div>
              <div className={styles.detailsContentWrapper}>
                <div className={styles.detailsContent}>
                  <div className={styles.companyAddress}>
                    <h1 className={styles.escrowStackPrivate}>Signature</h1>
                  </div>
                  <div className={styles.rightinfo}>
                    <div className={styles.signatureList}>
                      {data.map((detail) => (
                        <div className={`${styles.detailsHeaders} mb-2 w-100`}>
                          <button className={styles.contain}>
                            <div className={styles.icon}>{detail.name.slice("")[0]}</div>
                            <div className={styles.detailParent}>
                              <b className={styles.detail}>
                                <div className={styles.detailsinfo}>{detail.name}</div>
                                <div className={styles.detailsinfo}>{detail.email}</div>
                                <div className={styles.detailsinfo}>Sign Type: Aadhar</div>
                              </b>
                            </div>
                          </button>
                        </div>
                      ))}
                    </div>
                    <div className="d-flex justify-content-between w-100 mt-2 mb-2">
                      <div>Add eStamping</div>

                      <div className="form-check form-switch">
                        <input
                          className="form-check-input p-2"
                          type="checkbox"
                          role="switch"
                          id="flexSwitchCheckDefault"
                          checked={isChecked}
                          onChange={handleSwitchChange}
                        />
                        <label className="form-check-label" htmlFor="flexSwitchCheckDefault"></label>
                      </div>
                    </div>
                    <div className="mt-2 mb-2 text-danger">{error && error}</div>
                    <button
                      className={styles.continuebtn}
                      onClick={() => {
                        setClicked(true);
                        console.log("retrieving data");
                      }}
                    >
                      <b>Send</b>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Spin>
      </div>
    </Fragment>
  );
}

import React, { useEffect, useRef, useState } from "react";
import companyStore from "../CompanyStore";
import styles from "./header.module.css";
import { useAllProduct } from "../../api-context/commonApiContext";
import { useNavigate } from "react-router-dom";
import config from "../../../service/config";
import axios from "axios";

const Header = () => {
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const modalRef = useRef(null);
  const iconRef = useRef(null);
  const { role, userName, setRole, setUserName, authTokens } = useAllProduct();

  useEffect(() => {
    const fetchUserData = async () => {
      if (authTokens && Object?.keys(authTokens)?.length > 0) {
        try {
          const userRes = await axios.get(`${config.node_api_endpoint}/v1/user/getAllUserData`, {
            headers: {
              aws_cognito_id_token: authTokens.idToken,
              "Content-Type": "application/json",
            },
          });
          const users = userRes.data.userData;
          const getUserRole = users?.filter(
            (data) => data.email === companyStore.AdminEmail && data.status === "active",
          );
          setRole(getUserRole[0]?.auth[0].role.map((data) => data));
          setUserName(getUserRole[0]?.name);
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      }
    };

    fetchUserData();
  }, [authTokens]);

  const handleLogout = () => {
    setUserName("");
    setRole("");
    localStorage.removeItem("authTokens");
    localStorage.removeItem("AdminEmail");
    console.log("Logged out");
    navigate("/");
  };

  const handleClickOutside = (event) => {
    // Close modal if click is outside both modal and the icon
    if (
      modalRef.current &&
      !modalRef.current.contains(event.target) &&
      iconRef.current &&
      !iconRef.current.contains(event.target)
    ) {
      setShowModal(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return (
    <div className="mt-2 me-3 d-flex justify-content-end">
      <div
        ref={iconRef} // Attach ref to the icon
        onClick={() => setShowModal((prev) => !prev)}
        style={{ position: "relative", cursor: "pointer" }}
      >
        <img src="/userpic.svg" alt="User" />
      </div>
      {showModal && (
        <div
          ref={modalRef} // Attach ref to the modal
          className="me-3"
          style={{
            position: "absolute",
            top: "55px",
            right: "0",
            background: "white",
            border: "1px solid #ddd",
            padding: "15px",
            borderRadius: "10px",
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
            zIndex: 51,
          }}
        >
          <div className="d-flex align-items-center gap-1 mb-2">
            <div className={styles.userName}>
              <img src="/profile.svg" alt="User" /> &nbsp; {userName}
            </div>
            <div className={`d-flex align-items-center justify-content-center  ${styles.role} px-2`}>{role}</div>
          </div>
          <div className={`d-flex align-items-center justify-content-center mb-2 ${styles.mail}`}>
            {companyStore.AdminEmail}
          </div>
          <div className={styles.logout}>
            <button className={styles.contractsList} onClick={handleLogout}>
              <img className={styles.building07Icon} alt="" src="/logout.svg" />
              <div className={`${styles.kycList1} px-2`}>Logout</div>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;

import axios from "axios";
import React, { createContext, useContext, useEffect, useState } from "react";
import companyStore from "../Content/CompanyStore";
import config from "../../service/config";
import { useLocation, useNavigate } from "react-router-dom";

const AllDivisionContext = createContext([]);

export const ApiProvider = ({ children }) => {
  const navigate = useNavigate();
  const [eSignCoordinates, setESignCoordinates] = useState(() => {
    const savedData = localStorage.getItem("signCoordinates");
    return savedData ? JSON.parse(savedData) : []; // Default to empty array
  });
  const [role, setRole] = useState([]);
  const [clientID, setClientID] = useState("");
  const [userName, setUserName] = useState("");
  const [loader, setLoader] = useState(false);
  const [authTokens, setAuthTokens] = useState({});
  const [authFromUrl, setAuthFromUrl] = useState("");
  const [userData, setUserData] = useState([]);
  const [refreshData, setRefreshData] = useState(false);

  const location = useLocation();
  let auth = JSON.parse(localStorage.getItem("authTokens"));

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);

    if (queryParams) {
      setAuthFromUrl(queryParams.get("idToken"));
      console.log("Access Tokessn:", queryParams.get("idToken"));
    } else {
      console.log("Access Token not found");
    }
  }, [window.location.search]);

  useEffect(() => {
    if (eSignCoordinates) {
      localStorage.setItem("signCoordinates", JSON.stringify(eSignCoordinates));
    }
  }, [eSignCoordinates]);

  useEffect(() => {
    if ((!auth?.idToken || !auth?.refreshToken) && !location.pathname.includes("editor-contract")) {
      navigate("/");
    } else {
      console.log("counter party page");
      return;
    }
  }, [auth, location.pathname, navigate]);

  useEffect(() => {
    let currentAuth = JSON.parse(localStorage.getItem("authTokens"));
    // if(Object?.keys(currentAuth)?.length===null) navigate("/")
    setAuthTokens(JSON.parse(localStorage.getItem("authTokens")));
    const queryParams = new URLSearchParams(window.location.search);
    const auth = queryParams.get("idToken");

    if (auth?.length > 0 || /* Object?.keys(currentAuth)?.length > 0 */ currentAuth) {
      axios
        .get(`${config.node_api_endpoint}/v1/user/getAllUserData`, {
          headers: {
            aws_cognito_id_token: currentAuth && Object?.keys(currentAuth)?.length > 0 ? currentAuth?.idToken : auth,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          const users = response.data.userData;
          console.log("userData-->", users, companyStore.AdminEmail);

          setUserData(users);
          const getUserRole = users?.filter(
            (data) => data.email === companyStore.AdminEmail && data.status === "active",
          );
          console.log("userNAme", getUserRole);
          setClientID(getUserRole[0].auth[0].clientId);
          console.log("client--->", getUserRole[0].auth[0].clientId);

          let role = getUserRole[0]?.auth[0]?.role.map((data) => data);
          if (!role) {
            localStorage.removeItem("authTokens");
            localStorage.removeItem("AdminEmail");
            return navigate("/");
          }
          setRole(role);
          // const pathname = location.pathname;

          // if (pathname.includes("/editor-contract")) {
          //   return;
          // }

          // if (!role || !authTokens) {
          //   console.log("Logged out");
          //   return navigate("/");
          // } else {
          //   setRole(role?.join(""));
          //   setUserName(getUserRole[0]?.name);
          // }
        })
        .catch((error) => {
          console.error("Error fetching user data:", error);
        });
    } else {
      return;
    }
  }, [refreshData, location.pathname]);

  return (
    <AllDivisionContext.Provider
      value={{
        userData,
        eSignCoordinates,
        setESignCoordinates,
        role,
        userName,
        setRole,
        setUserName,
        loader,
        setLoader,
        authTokens,
        setAuthTokens,
        authFromUrl,
        setUserData,
        setRefreshData,
        refreshData,
        clientID,
      }}
    >
      {children}
    </AllDivisionContext.Provider>
  );
};

export const useAllProduct = () => {
  const context = useContext(AllDivisionContext);

  if (context === null) {
    throw new Error("useAllProduct must be used within a AllProductProvider");
  }
  if (!context) {
    throw new Error("useAllProduct must be used within a ApiProvider");
  }

  return context;
};

export default AllDivisionContext;
